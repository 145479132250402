<template>
  <Layout header-class="header-transparent" :footer-style="3">
    <!-- 首页大屏区域 -->
    <div
      class="slider-area slider-style-1 variation-default height-850 bg_image"
      data-black-overlay="7"
      :style="{
        'background-image': `url(https://bimhuicorebasic.oss-cn-shanghai.aliyuncs.com/banner%E5%AD%98%E5%82%A8/CADbanner.jpg)`,
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center">
              <span class="subtitle" data-aos="fade-up" data-aos-delay="100">
                快捷、高效
              </span>
              <h1
                class="title display-one"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                ShowCad服务
              </h1>
              <p class="description" data-aos="fade-up" data-aos-delay="200">
                CAD多端在线预览
                <br />CAD汇报宝（多模板）<br />嵌入式体验<br />丰富的场景应用
              </p>
              <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                <a class="btn-default btn-medium round btn-icon">
                  立刻登录 体验！
                  <Icon name="arrow-right" size="20" icon-class="icon" />
                </a>
                <router-link
                  class="btn-default btn-medium btn-border round btn-icon"
                  to="/contact"
                >
                  联系客服，指导体验！
                  <Icon name="arrow-right" size="20" icon-class="icon" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rwt-portfolio-area rn-section-gapTop" id="Home">
      <div class="container-fluid plr--60 plr_md--15 plr_sm--15">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="在线应用展示"
              title="上传模型/图纸、一键预览、开始创造！"
              data-aos="fade-up"
            />
          </div>
        </div>
        <!-- <Portfolio
          :portfolio-data="portfolioData"
          column="col-lg-4 col-md-6 mt--30 box-grid-layout no-overlay"
        /> -->
        <Portfolio2
          :portfolio-data="portfolioData2"
          column="col-lg-4 col-md-6 mt--30 box-grid-layout no-overlay"
        />
      </div>
    </div>
    <!-- End Slider Area -->
    <!-- <Separator /> -->
    <!-- 3个ICON区域 -->
    <div class="rn-service-area rn-section-gapBottom pt--100" id="business1">
      <div class="container">
        <ServiceOne
          service-style="service__style--1 icon-circle-style"
          text-align="center"
          icon-size="39"
        />
      </div>
    </div>
    <!-- End Service Area -->

    <Separator />

    <!-- 核心内务介绍区域+小视频区域  -->
    <div>
      <AboutFour
        :image="require(`@/assets/images/about/showCAD核心功能.png`)"
      />
    </div>
    <!-- End Timeline Area -->
    <Separator />

    <!-- 业务说明，CadShow 到底可以做什么 -->
    <div class="rwt-about-area rn-section-gap" id="business2">
      <div class="container">
        <div class="row row--30">
          <div class="col-lg-5">
            <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
              <img
                class="w-100"
                src="../../assets/images/about/showCAD应用场景.png"
                alt="About Images"
              />
            </div>
          </div>
          <div class="col-lg-7 mt_md--40 mt_sm--40">
            <div class="content">
              <SectionTitle
                subtitle="ShowCAD自主服务"
                title="应用场景简介"
                data-aos="fade-up"
              />
              <accordion
                id="accordionExampleOne"
                data-aos="fade-up"
                data-aos-delay="60"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End About Area -->

    <!-- <Separator /> -->

    <!-- 应用场景 -->
    <!-- <div class="rwt-testimonial-area rn-section-gap" id="business3">
      <div class="container">
        <div class="row mb--20">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="Application Scenarios"
              title="应用场景"
              description="ShowCAD应用广泛、改变了原有的习惯 <br /> 应用场景，看看有什么帮助."
              data-aos="fade-up"
            />
          </div>
        </div>
        <Testimonial
          :testimonial="testimonialData5"
          :testimonial-style="5"
          data-aos="fade-up"
        />
      </div>
    </div> -->
    <!-- End Elements Area -->
    <Separator />

    <!-- 业务介绍区域 -->
    <!-- <div class="rwt-pricing-area rn-section-gap" id="business4">
      <div class="wrapper plr--100 plr_md--15 plr_sm--15">
        <div class="row mb--40 mb_sm--0">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="我们提供什么服务？"
              title="业务介绍"
              data-aos="fade-up"
              data-aos-delay="60"
            />
          </div>
        </div>
        <Pricing :pricing-data="pricingFourData" :pricing-style="4" />
      </div>
    </div> -->
    <!-- End Elements Area -->

    <!-- Start Elements Area -->
    <!-- <div class="rwt-callto-action-area rn-section-gapBottom">
      <div class="wrapper">
        <CallToAction
          :style-type="5"
          title="准备开始 利用工具 高效创作了吗 ?"
          subtitle="点击 注册==即可开始创作"
          btn-text="立刻 登录"
        />
      </div>
    </div> -->
    <!-- End Elements Area -->
  </Layout>
</template>

<script>
import AboutFour from "../../components/elements/about/AboutFour";
import Layout from "../../components/common/Layout";
import Icon from "../../components/icon/Icon";
import Accordion from "../../components/elements/accordion/Accordion";
import Separator from "../../components/elements/separator/Separator";
import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
import ServiceOne from "../../components/elements/service/ServiceOne";
import Video from "../../components/elements/video/Video";
import Testimonial from "../../components/elements/testimonial/Testimonial";
import CallToAction from "../../components/elements/callToAction/CallToAction";
import Pricing from "../../components/elements/pricing/Pricing";
// import Portfolio from "../../components/elements/portfolio/Portfolio";
import Portfolio2 from "../../components/elements/portfolio/Portfolio2";
export default {
  name: "BusinessConsulting",
  components: {
    Pricing,
    Accordion,
    ServiceOne,
    CallToAction,
    Testimonial,
    Video,
    SectionTitle,
    Separator,
    Icon,
    Layout,
    AboutFour,
    // Portfolio,
    Portfolio2,
  },
  data() {
    return {
      testimonialData5: [
        {
          form: "轻松协作",
          description:
            "多人协作时，图纸不需要通过微信/QQ收发，免费上传BimWork，只需添加好友，选择版本共享，对方即可在线预览和下载；软件可保存最近三个历史版本，便于找回和多方案比选.",
          name: "",
          subtitle: "",
          image: "配图-8",
        },
        {
          form: "老总看图",
          description:
            "图纸提交审批，审批人无需安装CAD软件，登录BimWork即可查看，并可在CAD图上直接批注审查意见；即便在出差途中，也能通过手机端轻松审查图纸，妥妥的老总看图神器。",
          name: "",
          subtitle: "",
          image: "配图-9",
        },
        {
          form: "汇报无忧",
          description:
            "会议室用不了自己的电脑？会议电脑不兼容CAD格式？再也不用麻烦了！只需打开浏览器登录BimWork，即可在线演示汇报。",
          name: "",
          subtitle: "",
          image: "配图-10",
        },
      ],
      pricingFourData: [
        {
          title: "基础功能",
          currency: "¥",
          price: "1",
          subtitle: "",
          features: [
            { title: "每月赠送50次免费转化次数" },
            { title: "在线预览、生成二维码、图纸共享" },
            { title: "多版本切换使用" },
            { title: "分享加成机制" },
            { title: "2D汇报/大屏基础模板" },
          ],
          btn: {
            text: "立刻体验",
            link: "/",
          },
        },
        {
          title: "高级功能",
          currency: "¥",
          price: "50",
          subtitle: "一个月",
          features: [
            { title: "每月赠送50次免费转化次数" },
            { title: "包含基础版所有功能" },
            { title: "无限制分享" },
            { title: "1次 大神聊图机会" },
            { title: "高级版汇报/展示 模板" },
          ],
          btn: {
            text: "立刻体验",
            link: "/",
          },
          isActive: true,
        },
        {
          title: "个性化应用",
          currency: "¥",
          price: "300",
          subtitle: "次",
          features: [
            { title: "1.个性应用（长图展示）" },
            { title: "2.嵌入式网页" },
            { title: "3.刊物/海报 应用" },
            { title: "4.个性化 展示模板" },
            { title: "5.私有部署" },
          ],
          btn: {
            text: "立刻体验",
            link: "/",
          },
        },
      ],
      portfolioData2: [
        {
          //序号
          id: 11,
          //白色主题图片
          portfolioImage: require(`@/assets/NewImages/11.jpg`),
          //黑色主题图片
          image: require(`@/assets/NewImages/11.jpg`),
          //名称
          title: "CAD图纸钢筋",
          //子页-描述
          subtitle: "CAD图纸",
          //子页-日期
          date: "2021年5月",
          client: "CAD图纸",
          category: "图纸",
          awards: ["2020 Design beautiful apps Finale Awards"],
          excerpt: "Design beautiful apps.",
          body: `<p>CAD图纸在线预览，广泛应用于设计协作，设计协同行业，极大的提高了效率。</p>`,
          btn: {
            buttonText: "返回首页",
            link: "/",
          },
          FileId: "10000764274142",
          subContent: {
            title: "Digital Marketing.",
            subtext:
              "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent2: {
            title: "App Development.",
            subtext:
              "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent3: {
            title: "Solution.",
            subtext:
              "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          //子页-图片
          gallery: {
            // imageOne: require(`@/assets/NewImages/Picture10000746356818.jpg`),
            // imageTwo: require(`@/assets/NewImages/Picture10000746356818.jpg`),
            // imageThree: require(`@/assets/NewImages/Picture10000746356818.jpg`)
          },
        },
        {
          //序号
          id: 12,
          //白色主题图片
          portfolioImage: require(`@/assets/NewImages/12.jpg`),
          //黑色主题图片
          image: require(`@/assets/NewImages/12.jpg`),
          //名称
          title: "CAD图纸",
          //子页-描述
          subtitle: "CAD图纸",
          //子页-日期
          date: "2021年5月",
          client: "CAD图纸",
          category: "图纸",
          awards: ["2020 Design beautiful apps Finale Awards"],
          excerpt: "Design beautiful apps.",
          body: `<p>CAD图纸在线预览，广泛应用于设计协作，设计协同行业，极大的提高了效率。</p>`,
          btn: {
            buttonText: "返回首页",
            link: "/",
          },
          FileId: "10000764600719",
          subContent: {
            title: "Digital Marketing.",
            subtext:
              "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent2: {
            title: "App Development.",
            subtext:
              "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent3: {
            title: "Solution.",
            subtext:
              "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          //子页-图片
          gallery: {
            // imageOne: require(`@/assets/NewImages/Picture10000746356818.jpg`),
            // imageTwo: require(`@/assets/NewImages/Picture10000746356818.jpg`),
            // imageThree: require(`@/assets/NewImages/Picture10000746356818.jpg`)
          },
        },
        {
          //序号
          id: 13,
          //白色主题图片
          portfolioImage: require(`@/assets/NewImages/13.jpg`),
          //黑色主题图片
          image: require(`@/assets/NewImages/13.jpg`),
          //名称
          title: "CAD图纸",
          //子页-描述
          subtitle: "CAD图纸",
          //子页-日期
          date: "2021年5月",
          client: "CAD图纸",
          category: "图纸",
          awards: ["2020 Design beautiful apps Finale Awards"],
          excerpt: "Design beautiful apps.",
          body: `<p>CAD图纸在线预览，广泛应用于设计协作，设计协同行业，极大的提高了效率。</p>`,
          btn: {
            buttonText: "返回首页",
            link: "/",
          },
          FileId: "10000764275046",
          subContent: {
            title: "Digital Marketing.",
            subtext:
              "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent2: {
            title: "App Development.",
            subtext:
              "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent3: {
            title: "Solution.",
            subtext:
              "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          //子页-图片
          gallery: {
            // imageOne: require(`@/assets/NewImages/Picture10000746356818.jpg`),
            // imageTwo: require(`@/assets/NewImages/Picture10000746356818.jpg`),
            // imageThree: require(`@/assets/NewImages/Picture10000746356818.jpg`)
          },
        },
      ],
    };
  },
};
</script>