<template>
  <Layout header-class="header-left-align" :footer-style="3">
    <!-- Start Slider Area -->
    <!-- <div class="slider-area slider-style-1 bg-transparent height-850">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center">
              <span class="subtitle" data-aos="fade-up" data-aos-delay="100"
                >SHOW Model 三维数字服务</span
              >
              <h1
                class="title theme-gradient display-two"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                三维模型应用服务 <br />
                <vue-typed-js
                  class="justify-content-center"
                  :strings="['全模型', '3D编辑', '3D应用']"
                  :loop="true"
                  :type-speed="80"
                  :start-delay="1000"
                  :back-speed="50"
                >
                  <b class="is-visible theme-gradient typing">全模型</b>
                </vue-typed-js>
              </h1>
              <p class="description" data-aos="fade-up" data-aos-delay="200">
                我们帮助用户成功应用3D数据，3D编辑、3D展示、3D功能汇报，嵌入式应用，全场景应用
              </p>
              <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                <router-link
                  class="btn-default btn-medium round btn-icon"
                  to="#"
                >
                  开始 创作！
                  <Icon name="arrow-right" size="20" icon-class="icon" />
                </router-link>
                <router-link
                  class="btn-default btn-medium btn-border round btn-icon"
                  to="/contact"
                >
                  联系我们！
                  <Icon name="arrow-right" size="20" icon-class="icon" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- End Slider Area -->
    <div class="rn-service-area rn-section-gap" id="miao97">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="我们可以提供什么服务"
              title="图模坊增值服务"
              description="个性化定制开发，打通线上各个平台, <br /> 打造更真实的3d交互式场景体验."
              data-aos="fade-up"
            />
          </div>
        </div>
        <ServiceSix
          service-style="service__style--1 bg-color-blackest radius mt--25"
          icon-size="62"
          text-align="center"
        />
      </div>
    </div>
    <Separator />
    <!-- <div>
      <AboutFour :image="require(`@/assets/images/about/配图-8.jpg`)" />
    </div> -->
    <!-- End Timeline Area -->
    <div class="rn-service-area rn-section-gap" id="miao96">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              radiusRounded=""
              subtitle="1V1专属顾问"
              title="可视化设计业务流程"
              data-aos="fade-up"
            />
          </div>
        </div>
        <ServiceFour
          service-style="service__style--1 icon-circle-style with-working-process"
          text-align="center"
        />
      </div>
    </div>
    <Separator />
    <div class="rwt-advance-tab-area rn-section-gap" id="miao95">
      <div class="container">
        <div class="row mb--40">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="欢迎洽淡"
              title="其它拓展服务"
              description=""
              data-aos="fade-up"
            />
          </div>
        </div>
        <AdvancedTab :tab-data="tabData" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Icon from "../../components/icon/Icon";
import Layout from "../../components/common/Layout";
import AboutFour from "../../components/elements/about/AboutFour";
import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
import ServiceFour from "../../components/elements/service/ServiceFour";
import ServiceSix from "../../components/elements/service/ServiceSix";
import AdvancedTab from "../../components/elements/tab/AdvancedTab";
import Separator from "../../components/elements/separator/Separator";
export default {
  components: {
    Icon,
    Layout,
    AboutFour,
    SectionTitle,
    ServiceFour,
    ServiceSix,
    AdvancedTab,
    Separator,
  },
  data() {
    return {
      tabData: {
        tabContent: [
          {
            id: 1,
            menu: "market-research",
            title: "新型教材出版物方案.",
            description: "扫码轻松享受互动阅读的乐趣.",
            image: require(`@/assets/images/tab/配图-21.jpg`),
          },
          {
            id: 2,
            menu: "corporate-report",
            title: "企业及电商产品展示方案.",
            description: "在线体验全方位的产品3D展示.",
            image: require(`@/assets/images/tab/配图-22.jpg`),
          },
          {
            id: 3,
            menu: "虚拟展厅建设方案",
            title: "虚拟展厅建设方案.",
            description: "打造产品的立体宣传手册.",
            image: require(`@/assets/images/tab/配图-23.jpg`),
          },
        ],
      },
    };
  },
};
</script>

<style>
</style>