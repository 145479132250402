<template>
    <div class="footer-style-2">
        <CallToAction v-if="data.showFooter" :style-type="8" title="准备好开始创作了吗？" subtitle="上传模型/CAD  极速转化，快速出效果"
            btn-text="立刻 登录" />
        <CopyrightTwo v-if="data.showCopyright" />
        <ScrollTop />
    </div>
</template>

<script>
import CopyrightTwo from './CopyrightTwo'
import CallToAction from '../../elements/callToAction/CallToAction'
import ScrollTop from './ScrollTop'

export default {
    name: 'FooterTwo',
    components: { CallToAction, CopyrightTwo, ScrollTop },
    props: {
        data: {
            default: null
        }
    }
}
</script>