import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
// import About from '../views/About'
import ServiceDetailsPage from '../views/ServiceDetailsPage'
import BlogDetailsPage from '../views/BlogDetailsPage'
import BlogCategoryPosts from '../components/blog/BlogCategoryPosts'
import ContactPage from '../views/ContactPage'
import ErrorPage from '../views/404'
import BusinessConsulting from '../views/home-pages/BusinessConsulting'
import BusinessConsulting2 from '../views/home-pages/BusinessConsulting2'
import Corporate from '../views/home-pages/Corporate'
import Business from '../views/home-pages/Business'
import DigitalAgency from '../views/home-pages/DigitalAgency'
import Finance from '../views/home-pages/Finance'
import Company from '../views/home-pages/Company'
import MarketAgency from '../views/home-pages/MarketAgency'
import TravelAgency from '../views/home-pages/TravelAgency'
import Consulting from '../views/home-pages/Consulting'
import SeoAgency from '../views/home-pages/SeoAgency'
import PersonalPortfolio from '../views/home-pages/PersonalPortfolio'
import EventConference from '../views/home-pages/EventConference'
import CreativePortfolio from '../views/home-pages/CreativePortfolio'
import Freelancer from '../views/home-pages/Freelancer'
import InternationalConsulting from '../views/home-pages/InternationalConsulting'
import Startup from '../views/home-pages/Startup'
import WebAgency from '../views/home-pages/WebAgency'
import Shuziluansheng from '../views/Shuziluansheng.vue'
import Duoyuananli from '../views/Duoyuananli.vue'
import MallServices from '../views/MallServices'
import MallServicesdetail from '../views/MallServicesdetail'
import Socio from '../views/Socio.vue'
import ShowCAD from '../views/NavPages/ShowCAD.vue'
import ShowModel from '../views/NavPages/ShowModel.vue'
import Editor from '../views/NavPages/Editor.vue'
import Price from '../views/NavPages/Price.vue'
import Service from '../views/NavPages/Service.vue'
import Login from '../views/login/index.vue'
import Iphone from '../components/elements/split/Iphone.vue'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: '首页',
        },
    },
    {
        path: '/Iphone',
        name: 'Iphone',
        component: Iphone,
        meta: {
            title: 'ceshi',
        },
    },
    {
        path: '/Login',
        name: 'Login',
        component: Login,
        meta: {
            title: '登录',
        },
    },
    {
        path: '/mall',
        name: 'Mall',
        component: MallServices,
        meta: {
            title: '三维工坊',
        },
    },
    {
        path: '/malldetail/:id/:parentId/:fileId/:type',
        name: 'MallServicesdetail',
        component: MallServicesdetail,
        meta: {
            title: '三维工坊详情页',
        },
    },
    // {
    //     path: '/about',
    //     name: 'About',
    //     component: About,
    //     meta: {
    //         title: '关于我们',
    //     },
    // },

    {
        path: '/service-details',
        name: 'Service Details',
        component: ServiceDetailsPage,
        meta: {
            title: 'Service Details || Doob Business and Consulting Bootstrap5 Template',
        },
    },

    {
        path: '/blog-details/:id',
        name: 'Blog details',
        component: BlogDetailsPage,
        meta: {
            title: '项目案例',
        },
    },
    {
        path: '/category/:slug',
        name: 'Blog Category List',
        component: BlogCategoryPosts,
        meta: {
            title: 'Blog Category List || Doob Business and Consulting Bootstrap5 Template',
        },
    },

    {
        path: '/contact',
        name: 'Contact',
        component: ContactPage,
        meta: {
            title: '联系我们',
        },
    },

    {
        path: '/error',
        name: 'Error',
        component: ErrorPage,
        meta: {
            title: '404 ',
        },
    },
    {
        path: '/blog-details',
        name: 'Blog details',
        component: BlogDetailsPage,
        meta: {
            title: '项目案例',
        },
    },
    {
        path: '/business-consulting',
        name: 'Business Consulting',
        component: BusinessConsulting,
        meta: {
            title: '产品服务',
        },
    },
    {
        path: '/business-consulting-2',
        name: 'Business Consulting 2',
        component: BusinessConsulting2,
        meta: {
            title: '产品服务',
        },
    },
    {
        path: '/corporate',
        name: 'Corporate',
        component: Corporate,
        meta: {
            title: '协同平台',
        },
    },
    {
        path: '/business',
        name: 'Business',
        component: Business,
        meta: {
            title: 'Business || Doob Business and Consulting Bootstrap5 Template',
        },
    },
    {
        path: '/digital-agency',
        name: 'Digital Agency',
        component: DigitalAgency,
        meta: {
            title: 'Digital Agency || Doob Business and Consulting Bootstrap5 Template',
        },
    },
    {
        path: '/finance',
        name: 'Finance',
        component: Finance,
        meta: {
            title: 'Finance || Doob Business and Consulting Bootstrap5 Template',
        },
    },
    {
        path: '/company',
        name: 'Company',
        component: Company,
        meta: {
            title: 'Company || Doob Business and Consulting Bootstrap5 Template',
        },
    },
    {
        path: '/marketing-agency',
        name: 'Marketing Agency',
        component: MarketAgency,
        meta: {
            title: 'Marketing Agency || Doob Business and Consulting Bootstrap5 Template',
        },
    },
    {
        path: '/travel-agency',
        name: 'Travel Agency',
        component: TravelAgency,
        meta: {
            title: 'Travel Agency || Doob Business and Consulting Bootstrap5 Template',
        },
    },
    {
        path: '/consulting',
        name: 'Consulting',
        component: Consulting,
        meta: {
            title: 'Consulting || Doob Business and Consulting Bootstrap5 Template',
        },
    },
    {
        path: '/seo-agency',
        name: 'SEO Agency',
        component: SeoAgency,
        meta: {
            title: 'SEO Agency || Doob Business and Consulting Bootstrap5 Template',
        },
    },
    {
        path: '/personal-portfolio',
        name: 'Personal Portfolio',
        component: PersonalPortfolio,
        meta: {
            title: 'Personal Portfolio || Doob Business and Consulting Bootstrap5 Template',
        },
    },
    {
        path: '/event-conference',
        name: 'Event Conference',
        component: EventConference,
        meta: {
            title: 'Event Conference || Doob Business and Consulting Bootstrap5 Template',
        },
    },
    {
        path: '/creative-portfolio',
        name: 'Creative Portfolio',
        component: CreativePortfolio,
        meta: {
            title: 'Creative Portfolio || Doob Business and Consulting Bootstrap5 Template',
        },
    },
    {
        path: '/freelancer',
        name: 'Freelancer',
        component: Freelancer,
        meta: {
            title: 'Freelancer || Doob Business and Consulting Bootstrap5 Template',
        },
    },
    {
        path: '/international-consulting',
        name: 'International Consulting',
        component: InternationalConsulting,
        meta: {
            title: 'International Consulting || Doob Business and Consulting Bootstrap5 Template',
        },
    },
    {
        path: '/startup',
        name: 'Startup',
        component: Startup,
        meta: {
            title: '轻设',
        },
    },
    {
        path: '/web-agency',
        name: 'Web Agency',
        component: WebAgency,
        meta: {
            title: '产品与服务',
        },
    },
    {
        path: '/Shuziluansheng',
        name: 'Shuziluansheng',
        component: Shuziluansheng,
        meta: {
            title: '数字孪生',
        },
    },
    {
        path: '/Duoyuananli',
        name: 'Duoyuananli',
        component: Duoyuananli,
        meta: {
            title: '多元案例',
        },
    },
    {
        path: '/Socio',
        name: 'Socio',
        component: Socio,
        meta: {
            title: '社区',
        },
    },
    {
        path: '/ShowCAD',
        name: 'ShowCAD',
        component: ShowCAD,
        meta: {
            title: 'ShowCAD',
        },
    },
    {
        path: '/ShowModel',
        name: 'ShowModel',
        component: ShowModel,
        meta: {
            title: 'ShowModel',
        },
    },
    {
        path: '/Editor',
        name: 'Editor',
        component: Editor,
        meta: {
            title: 'Editor',
        },
    },
    {
        path: '/Price',
        name: 'Price',
        component: Price,
        meta: {
            title: 'Price',
        },
    },
    {
        path: '/Service',
        name: 'Service',
        component: Service,
        meta: {
            title: 'Service',
        },
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
