<template>
  <div class="main">
    <!-- 定义DOM元素，用于在该DOM元素中显示模型或图纸 -->
    <div class="model" id="domId">
      <!-- <el-button @click="test()">3</el-button> -->
    </div>
  </div>
</template>

<script>
// 构造天空盒管理器
let isModelExploded = false;
let skyBoxManager = null;
let isFrameColorGrey = false;
let isRainEffectActivated = false;
let rain;
let snow;
let isSnowEffectActivated = false;
import axios from "axios";
export default {
  props: ["data"],
  data() {
    return {
      fileId: "",
      color1: "",
      color2: "",
      colorGrey: "",
      app: "",
      viewer3D: "",
      viewAdded: true,
      measure: "",
      measureConfig: "",
      model3D: "",
      color3: "",
    };
  },
  mounted() {
    this.getModelsucess();
  },
  methods: {
    //模型爆炸
    modelSplit(value) {
      this.model3D.setExplosionExtent(value);
      this.viewer3D.render();
    },
    //获取Token
    getModelsucess() {
      var fileId = this.data.fileId;
      let data = {
        fileId: fileId,
      };
      var type = this.data.type;
      axios
        .post(
          "https://api.tomore.fun/api/BimWorkWebService/GetToken",
          data
        )
        .then((res) => {
          this.viewToken = res.data.data;
          let options = new BimfaceSDKLoaderConfig();
          options.viewToken = this.viewToken;
          console.log(this.viewToken);
          if (type == 1) {
            BimfaceSDKLoader.load(
              options,
              this.successCallback,
              this.failureCallback
            );
            setTimeout(() => {
              this.gradientBackgroundColor();
            }, 2000);
          } else {
            BimfaceSDKLoader.load(
              options,
              this.successCallbackCAD,
              this.failureCallback
            );
          }
        });
    },
    //1-1 3D显示
    successCallback() {
      // ======== 判断是否为3D模型 ========
      // 获取DOM元素
      let dom4Show = document.getElementById("domId");
      let webAppConfig =
        new Glodon.Bimface.Application.WebApplication3DConfig();
      webAppConfig.domElement = dom4Show;
      // console.log(webAppConfig.Buttons)
      //自己定义一个数组，配置自己想要的button  隐藏测量, "Measure"
      webAppConfig.Buttons = [
        "Home",
        "RectangleSelect",
        "Walk",
        "Measure",
        "Section",
        "Property",
        "Map",
        "Setting",
        "FullScreen",
      ];
      webAppConfig.enableExplosion = true;
      var localeffect = localStorage.getItem("effect");
      if (localeffect == "2") {
        //设置曝光度
        webAppConfig.exposure = 0.4;
        //是否启用环境光照
        webAppConfig.enableIBLBackground = true;
      }
      //是否开启鼠标悬停效果(hover效果)
      // webAppConfig.enableHover = false;
      // 创建WebApplication
      this.app = new Glodon.Bimface.Application.WebApplication3D(webAppConfig);
      // 添加待显示的模型
      this.app.addView(this.viewToken);
      // 从WebApplication获取viewer3D对象
      this.viewer3D = this.app.getViewer();
      this.color1 = new Glodon.Web.Graphics.Color("#BFEFFF", 0.8);
      this.color2 = new Glodon.Web.Graphics.Color("#949494", 0.8);
      this.colorGrey = new Glodon.Web.Graphics.Color(255, 128, 128, 1);
      this.color3 = new Glodon.Web.Graphics.Color("#E3E3E3", 0.1);
      // 监听添加view完成的事件
      this.viewer3D.addEventListener(
        Glodon.Bimface.Viewer.Viewer3DEvent.ViewAdded,
        () => {
          //自适应屏幕大小
          window.onresize = () => {
            this.viewer3D.resize(
              document.documentElement.clientWidth,
              document.documentElement.clientHeight - 40
            );
          };
          this.viewer3D.enableContactShadow(false);
          this.model3D = this.viewer3D.getModel();
          this.viewer3D.render();
        }
      );
    },
    // 1渐变背景色
    gradientBackgroundColor() {
      if (skyBoxManager) {
        skyBoxManager.enableSkyBox(false);
      }
      this.viewer3D.setBackgroundColor(this.color1, this.color2);
      this.viewer3D.render();
    },

    // 2开启蓝天的天空盒效果
    setBlueSky() {
      this.initSkyBox();
      // 将天空盒效果设置为蓝天
      skyBoxManager.setStyle(Glodon.Bimface.Plugins.SkyBox.SkyBoxStyle.BlueSky);
      skyBoxManager.enableSkyBox(true);
    },

    // 3开启星空的天空盒效果
    setGalaxySky() {
      this.initSkyBox();
      // 将天空盒效果设置为星空
      skyBoxManager.setStyle(Glodon.Bimface.Plugins.SkyBox.SkyBoxStyle.Galaxy);
      skyBoxManager.enableSkyBox(true);
    },
    //4-1 天气效果雨天
    selectweather(value) {
      //雨天配置
      let rainConfig = new Glodon.Bimface.Plugins.WeatherEffect.RainConfig();
      rainConfig.viewer = this.viewer3D;
      rainConfig.darkness = 0.2;
      rainConfig.density = 3;
      rain = new Glodon.Bimface.Plugins.WeatherEffect.Rain(rainConfig);
      //雪天配置
      let snowConfig = new Glodon.Bimface.Plugins.WeatherEffect.SnowConfig();
      snowConfig.viewer = this.viewer3D;
      snowConfig.darkness = 0.3;
      snowConfig.density = 2;
      snowConfig.thickness = 0.4;
      snow = new Glodon.Bimface.Plugins.WeatherEffect.Snow(snowConfig);
      if (value == "1") {
        rain.enableEffect(true);
        snow.enableEffect(false);
      }
      if (value == "3") {
        rain.enableEffect(false);
        snow.enableEffect(false);
      }
      if (value == "2") {
        rain.enableEffect(false);
        snow.enableEffect(true);
      }
    },
    //雪天
    selectweatherSnowy() {
      if (!isSnowEffectActivated) {
        let snowConfig = new Glodon.Bimface.Plugins.WeatherEffect.SnowConfig();
        snowConfig.viewer = this.viewer3D;
        snowConfig.darkness = 0.3;
        snowConfig.density = 2;
        snowConfig.thickness = 0.4;
        snow = new Glodon.Bimface.Plugins.WeatherEffect.Snow(snowConfig);
        snow.enableEffect(true);
      } else {
        snow.enableEffect(false);
      }
    },
    //开启阴影效果
    openShadowtrue() {
      var isContactShadowEnabled = this.viewer3D.isContactShadowEnabled();
      if (isContactShadowEnabled) {
        this.viewer3D.enableContactShadow(!isContactShadowEnabled);
      } else {
        this.viewer3D.enableContactShadow(!isContactShadowEnabled);
      }
    },
    //5-显示线框
    showWireframetrue() {
      if (!isFrameColorGrey) {
        this.model3D.setWireframeColor(this.colorGrey);
        this.viewer3D.render();
      } else {
        this.model3D.restoreWireframeColor();
        this.viewer3D.render();
      }
      isFrameColorGrey = !isFrameColorGrey;
    },

    //X-1 开启高亮
    Shadowtrue() {
      this.viewer3D.enableHover(true);
    },
    //X-2 关闭高亮
    Shadowfalse() {
      this.viewer3D.enableHover(false);
    },
    //简单测量
    SimpleMeasure() {
      console.log("1");
    },
    //组件====自定义天空盒
    initSkyBox() {
      if (skyBoxManager) {
        return;
      }
      // 构造天空盒管理器配置项，并指定Viewer、Option参数
      let skyBoxManagerConfig =
        new Glodon.Bimface.Plugins.SkyBox.SkyBoxManagerConfig();
      skyBoxManagerConfig.viewer = this.viewer3D;
      // 设置自定义天空盒的图片资源
      skyBoxManagerConfig.customizedImage = {
        front:
          "https://static.bimface.com/attach/0d178c31584d432f93b3df90832d6ba1_EnvMap_posz.jpg",
        back: "https://static.bimface.com/attach/c02b7114af6d4966b3f1fd7d483fcdd9_EnvMap_negz.jpg",
        left: "https://static.bimface.com/attach/6c2f5045467b4c51a4e506524e74a65c_EnvMap_negx.jpg",
        right:
          "https://static.bimface.com/attach/ec541f626f194a979d49ec5f52ca32bb_EnvMap_posx.jpg",
        top: "https://static.bimface.com/attach/01700a9a6f7542af8df76bc923b065b9_EnvMap_posy.jpg",
        bottom:
          "https://static.bimface.com/attach/031a2a1a51374fc88fe8acf1d490b7c0_EnvMap_negy.jpg",
      };
      skyBoxManagerConfig.style =
        Glodon.Bimface.Plugins.SkyBox.SkyBoxStyle.CloudySky;
      // 构造天空盒管理器，构造完成后，场景背景即发生变化
      skyBoxManager = new Glodon.Bimface.Plugins.SkyBox.SkyBoxManager(
        skyBoxManagerConfig
      );
    },
    successCallbackCAD(viewMetaData) {
      // ======== 判断是否为2D模型 ========
      // 获取DOM元素
      let dom4Show = document.getElementById("domId");
      let webAppConfig =
        new Glodon.Bimface.Application.WebApplicationDrawingConfig();
      webAppConfig.domElement = dom4Show;
      // 创建WebApplication
      this.app = new Glodon.Bimface.Application.WebApplicationDrawing(
        webAppConfig
      );
      // 添加待显示的模型
      this.app.load(this.viewToken);
      // 从WebApplication获取viewer2D对象
      this.viewer2D = this.app.getViewer();
      // 监听添加view完成的事件
      this.viewer2D.addEventListener(
        Glodon.Bimface.Viewer.ViewerDrawingEvent.Loaded,
        () => {
          //自适应屏幕大小
          window.onresize = () => {
            this.viewer2D.resize(
              document.documentElement.clientWidth,
              document.documentElement.clientHeight - 40
            );
          };
          this.viewLoaded = true;
        }
      );
    },
    failureCallback() {
      // console.log(error);
    },
    //自动旋转
    // ************************** 绕中心旋转场景 **************************
    centerRotate() {
      this.viewer3D.startAutoRotate(5);
    },
    //停止自动旋转
    stopcenterRotate() {
      this.viewer3D.stopAutoRotate();
    },
    //禁止平移
    Nofuntion1(value) {
      switch (value) {
        case "1":
          //禁止平移
          this.viewer3D.enableTranslate(false);
          break;
        default:
          //可以
          this.viewer3D.enableTranslate(true);
      }
    },
    //禁止缩放
    Nofuntion2(value) {
      switch (value) {
        case "1":
          //禁止平移
          this.viewer3D.enableScale(false);
          break;
        default:
          //可以
          this.viewer3D.enableScale(true);
      }
    },
    //禁止旋转
    Nofuntion3(value) {
      switch (value) {
        case "1":
          //禁止平移
          this.viewer3D.enableOrbit(false);
          break;
        default:
          //可以
          this.viewer3D.enableOrbit(true);
      }
    },
    //所有构件半透明
    overrideAllComponentsColor(value) {
      switch (value) {
        case "1":
          //给指定模型的所有构件着色  transparentAllComponents 全部透明
          this.model3D.overrideAllComponentsColor(this.color3);
          this.viewer3D.render();
          break;
        default:
          //可以
          this.model3D.clearOverrideColorComponents();
          this.viewer3D.render();
      }
    },
    //正交视图
    Nofuntion7(value) {
      console.log("yu");
      switch (value) {
        case "1":
          //透视
          this.viewer3D.setCameraType("OrthographicCamera");
          this.viewer3D.render();
          break;
        default:
          this.viewer3D.setCameraType("PerspectiveCamera", 50);
          this.viewer3D.render();
          //可以
          this.viewer3D.enableOrbit(true);
      }
    },
  },
};
</script>

<style>
.model {
  flex: 1000;
  height: 680px;
  width: 100%;
}

.main {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
</style>