<template>
    <div class="row row--15 service-wrapper">
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" v-for="(service, index) in serviceList" :key=index>
            <div :class="`card-box ${cardStyle} ${checkTextAlign}`" data-aos="fade-up" data-aos-delay="70">
                <div class="inner">
                    <div class="image">
                        <router-link to="/service-details">
                            <img :src="service.image" alt="card Images" />
                        </router-link>
                    </div>
                    <div class="content">
                        <h4 class="title mb--20">
                            <router-link to="/service-details" v-html="service.title" />
                        </h4>
                        <p class="description b1 color-gray mb--0" v-html="service.description" />
                        <router-link class="btn-default btn-small btn-border" to="/service-details">
                            了解 更多
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServiceTwo',
    props: {
        cardStyle: {
            type: String
        },
        textAlign: {
            type: String,
            default: 'left'
        }
    },
    data() {
        return {
            serviceList: [
                {
                    image: require('@/assets/images/service/配图-17.jpg'),
                    title: '全项目生命周期管理',
                    description: '首页全面直观地展示项目信息，可快捷进入日常所需功能页面,支撑完整的业务流程.'
                },
                {
                    image: require('@/assets/images/service/配图-18.jpg'),
                    title: '项目工程协作管理',
                    description: '审核人员在线添加批注与审核意见，图文结合，让设计人员快速反馈修改，提高项目效率。'
                },
                {
                    image: require('@/assets/images/service/配图-19.jpg'),
                    title: '项目看板拖拽管理',
                    description: '拖拽式看板管理是一种高度可视化的工作流管理方法，提升团队执行力。'
                },
                {
                    image: require('@/assets/images/service/配图-20.jpg'),
                    title: '统一式文档管理',
                    description: '可实现全成员及各个部门之间独立的项目文档协同管理，极大的拓展了项目协作的便利性.'
                }
            ]
        }
    },
    computed: {
        checkTextAlign() {
            if (this.textAlign === 'left') return 'text-start';
            else if (this.textAlign === 'center') return 'text-center';
            else if (this.textAlign === 'end') return 'text-end';
            else return '';
        }
    }
}
</script>