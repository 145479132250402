<template>
    <Layout :footer-style="3">
        <Breadcrumb current-url="Service Details" :title="title" />

        <!-- Start Accordion Area -->
        <div class="rn-section-gap">
            <div class="container">
                <div class="row row--40 align-items-center">
                    <div class="col-lg-6">
                        <div class="thumb">
                            <img src="../assets/images/about/配图-8.jpg" alt="Service Images" class="w-100 rounded">
                        </div>
                    </div>

                    <div class="col-lg-6 mt_md--40 mt_sm--40">
                        <div>
                            <p>
                                （1）全项目生命周期管理
                                首页全面直观地展示项目信息，可快捷进入日常所需功能页面。驾驶舱包含以BIM数字化信息模型融合的项目协作管理，丰富的在线CAD和BIM模型操作、业务表单、工作流、企业文档、日程提醒、即时信息、大屏视觉等功能，支撑项目全生命周期管理和完整的业务流程。

                            </p>
                            <p>
                                （2）项目工程协作管理
                                兼容主流CAD软件各版本图纸对象，融合BIM可视化协同，可实现在线CAD图纸或BIM模型的审图、批注、二三维图纸联动。审核人员在线添加批注与审核意见，图文结合，让设计人员快速反馈修改，提高项目效率。
                            </p>
                            <h4 class="title">高效的应用场景</h4>
                            <ul class="liststyle bullet">
                                <li>设计师</li>
                                <li>管理层</li>
                                <li>设计院</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Accordion Area -->

        <Separator />

        <!-- Start Accordion Area -->
        <div class="rn-section-gap">
            <div class="container">
                <div class="row row--40 align-items-center">
                    <div class="col-lg-6 order-2 order-lg-1">
                        <div>
                            <p>
                                （5）项目看板拖拽管理
                                拖拽式看板管理是一种高度可视化的工作流管理方法，通过任务看板管理整个项目周期，制定项目任务并分配至相关工作人员，帮助团队更有效地开展协作过程。观察项目进展情况，便于工作内容分析与工作节点监督，提升团队执行力。

                            </p>
                            <p>
                                （6）项目聊天
                                在平台中实现成员线上聊天，进行快速沟通，通过IM记录查找项目相关重要节点，追溯项目相关信息。另可在首页公告栏查看会议记录，以防重点消息遗漏。

                            </p>
                            <p>
                                （7）统一式文档管理
                                项目云盘兼容数十种常用文件格式，轻松实现文档在线安全阅读。支持文档共享、文档归纳、文档上传下载等功能，可实现全成员及各个部门之间独立的项目文档协同管理，极大的拓展了项目协作的便利性。

                            </p>
                            <h4 class="title">高效的应用场景</h4>
                            <ul class="liststyle bullet">
                                <li>设计师</li>
                                <li>管理层</li>
                                <li>设计院</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 mb_md--40 mb_sm--40 order-1 order-lg-2">
                        <Video :video-data="videoData" column="col-12" />
                    </div>
                </div>
            </div>
        </div>
        <!-- End Accordion Area -->
    </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import Separator from '../components/elements/separator/Separator'
import Video from '../components/elements/video/Video'
import Icon from '../components/icon/Icon'

export default {
    name: 'ServiceDetailsPage',
    components: { Icon, Video, Separator, Breadcrumb, Layout },
    props: {
        title: {
            type: String,
            default: '二三维项目协同平台*详情介绍'
        }
    },
    data() {
        return {
            videoData: [
                {
                    thumb: require(`@/assets/images/about/配图-27.jpg`),
                    src: 'https://bimhuicorebasic.oss-cn-shanghai.aliyuncs.com/12s%E8%A7%86%E9%A2%91.mp4',
                }
            ]
        }
    }
}
</script>