<template>
  <Layout header-class="header-left-align" :footer-style="3">
    <!-- Start Slider Area -->
    <div class="slider-area slider-style-1 bg-transparent height-850">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center">
              <span class="subtitle" data-aos="fade-up" data-aos-delay="100">SHOW Model 三维数字服务</span>
              <h1 class="title theme-gradient display-two" data-aos="fade-up" data-aos-delay="150">
                三维模型应用服务 <br />
                <vue-typed-js class="justify-content-center" :strings="['全模型', '3D编辑', '3D应用']" :loop="true"
                  :type-speed="80" :start-delay="1000" :back-speed="50">
                  <b class="is-visible theme-gradient typing">全模型</b>
                </vue-typed-js>
              </h1>
              <p class="description" data-aos="fade-up" data-aos-delay="200">
                我们帮助用户成功应用3D数据，3D编辑、3D展示、3D功能汇报，嵌入式应用，全场景应用
              </p>
              <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                <router-link class="btn-default btn-medium round btn-icon" to="#">
                  开始 创作！
                  <Icon name="arrow-right" size="20" icon-class="icon" />
                </router-link>
                <router-link class="btn-default btn-medium btn-border round btn-icon" to="/contact">
                  联系我们！
                  <Icon name="arrow-right" size="20" icon-class="icon" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider Area -->

    <Separator />

    <!-- Start About Area -->
    <div class="rwt-about-area rn-section-gap" id="miao1">
      <div class="container">
        <div class="row row--30 align-items-center">
          <div class="col-lg-5">
            <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
              <img class="w-100" src="../../assets/images/about/配图-27.jpg" alt="About Images" />
            </div>
          </div>
          <div class="col-lg-7 mt_md--40 mt_sm--40">
            <div class="content">
              <div class="section-title">
                <h2 class="title theme-gradient" data-aos="fade-up" data-aos-delay="280">
                  全模型 格式上传 <br />
                  <vue-typed-js :strings="['全模型', '3D编辑', '3D应用']" :loop="true" :type-speed="80" :start-delay="1000"
                    :back-speed="50">
                    <b class="is-visible theme-gradient typing">全模型.</b>
                  </vue-typed-js>
                </h2>
                <p data-aos="fade-up" data-aos-delay="310">
                  BIMWORK渲染引擎，基于多年的三维开发经验，推出的一套面向不同行业的企业提供三维平台，旨在帮助企业和用户快速、低成本、低门槛地使用自己的三维应用，分享自己的三维数据。
                  底层封装了底层的图形接口，基于 HTML5 和 WebGL
                  技术，可方便地在主流浏览器上进行浏览和调试，支持 PC
                  和移动设备。为可视化应用提供了简单、丰富的功能。
                </p>
                <p data-aos="fade-up" data-aos-delay="340">
                  BIM模型往往非常巨大，单体一栋楼的BIM模型1-2G是常见现象。这样的模型在日常管理的3D可视化应用中是不太可用的，因此往往需要轻量化。
                  而BIM模型的轻量化过程，可能本身就要消耗大量时间精力。因此BIMWORK轻量化技术在运维显示端非常重要，BIMWORK支持模型轻量化后导入完成场景应用。
                </p>
                <div class="read-more-btn mt--40" data-aos="fade-up" data-aos-delay="370">
                  <router-link class="btn-default" to="/about">
                    <span>更多说明：详见工作台</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End About Area -->

    <Separator />

    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gap" id="miao2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle text-align="center" subtitle="二次开发场景应用" title="模型应用服务，我拥有模型可以做什么？"
              description="我们提供了高效的工作台，多种格式,全方面的应用于您的模型，在高效的场景编辑器中，您的模型更具有价值." data-aos="fade-up" />
          </div>
        </div>
        <ServiceFive service-style="gallery-style" text-align="left" />
      </div>
    </div>
    <!-- End Service Area -->

    <!-- Start Call To Action Area -->
    <div class="rwt-callto-action-area">
      <div class="wrapper">
        <CallToAction :style-type="5" title="准备好开始使用了吗?" subtitle="或许可以听听明星使用者的意见。" btn-text="立刻登录 " />
      </div>
    </div>
    <!-- End Call To Action Area -->

    <!-- Start Team Area -->
    <div class="rwt-team-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle text-align="center" subtitle="明星使用者" title="好用的 ShowModel"
              description="丰富的三维应用，多姿多彩！ <br /> 高逼真的效果，焕然一新！" data-aos="fade-up" />
          </div>
        </div>
        <div class="row row--15">
          <div class="col-lg-4 col-md-6 col-12" v-for="(teamMember, index) in teamData" :key="index">
            <Team :team-member="teamMember" :team-style="2" />
          </div>
        </div>
      </div>
    </div>
    <!-- End Team Area -->

    <Separator />

    <Separator />

    <!-- Start Testimonial Area -->
    <div class="rwt-testimonial-area rn-section-gap" id="miao3">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle text-align="center" subtitle="炫酷的特效" title="3D效果编辑器" description="炫酷特效、GIS、夜景、丰富、好看的场景"
              data-aos="fade-up" />
          </div>
        </div>
        <Testimonial :testimonial="testimonialData5" :testimonial-style="5" data-aos="fade-up" />
      </div>
    </div>
    <!-- End Testimonial Area -->
    <Separator />
    <!-- End Elements Area -->
    <div>
      <div class="main">
        <!-- 定义DOM元素，用于在该DOM元素中显示模型或图纸 -->
        <div class="model" id="domId"></div>
      </div>
    </div>
    <Separator />
    <!-- 业务介绍区域 -->
    <div class="rwt-pricing-area rn-section-gap" id="miao4">
      <div class="wrapper plr--100 plr_md--15 plr_sm--15">
        <div class="row mb--40 mb_sm--0">
          <div class="col-lg-12">
            <SectionTitle text-align="center" subtitle="我们提供什么服务？" title="业务介绍" data-aos="fade-up"
              data-aos-delay="60" />
          </div>
        </div>
        <Pricing :pricing-data="pricingFourData" :pricing-style="4" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Pricing from "../../components/elements/pricing/Pricing";
import axios from "axios";
import Layout from "../../components/common/Layout";
import Separator from "../../components/elements/separator/Separator";
import Icon from "../../components/icon/Icon";
import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
import ServiceFive from "../../components/elements/service/ServiceFive";
import Team from "../../components/elements/team/Team";
import BlogPost from "../../components/blog/BlogPost";
import BlogPostMixin from "../../mixins/BlogPostMixin";
import CallToAction from "../../components/elements/callToAction/CallToAction";
import Testimonial from "../../components/elements/testimonial/Testimonial";
import Accordion from "../../components/elements/accordion/Accordion";

export default {
  name: "BusinessConsulting2",
  components: {
    Pricing,
    Accordion,
    Testimonial,
    CallToAction,
    BlogPost,
    Team,
    ServiceFive,
    SectionTitle,
    Icon,
    Separator,
    Layout,
  },
  mixins: [BlogPostMixin],
  data() {
    return {
      teamData: [
        {
          image: "配图-13",
          name: "朱 先生",
          designation: "3D 设计师",
          location: "中国*江苏省*苏州市",
          description:
            "3D编辑器非常好用，以前我的作品都在个人电脑里，现在可以分享全世界。",
          socialNetwork: [
            {
              icon: "home",
              url: "#",
            },
            {
              icon: "inbox",
              url: "#",
            },
            {
              icon: "italic",
              url: "#",
            },
          ],
        },
        {
          image: "配图-14",
          name: "梁 先生",
          designation: "BIM设计师",
          location: "中国*上海市",
          description:
            "我特别喜欢工作台中的 模型轻量化，在线展示，汇报，领导特别喜欢看我的作品.",
          socialNetwork: [
            {
              icon: "home",
              url: "#",
            },
            {
              icon: "inbox",
              url: "#",
            },
            {
              icon: "italic",
              url: "#",
            },
          ],
        },
        {
          image: "配图-15",
          name: "徐 女士",
          designation: "设计院工程师",
          location: "浙江省*杭州市",
          description: "方便快捷、高效。轻松应对甲方的各种要求。",
          socialNetwork: [
            {
              icon: "home",
              url: "#",
            },
            {
              icon: "inbox",
              url: "#",
            },
            {
              icon: "italic",
              url: "#",
            },
          ],
        },
      ],
      testimonialData5: [
        {
          form: "高清特效",
          description:
            "BIMWORK 提供了对场景的加载、分层级的浏览，对象的访问、搜索、以及对象的多种控制方式和丰富的效果展示，可以通过绑定事件进行各种交互操作，还提供了摄像机视角控制、点线面效果、温湿度云图、界面数据展示、粒子效果等等各种可视化功能；",
          name: "",
          subtitle: "",
          image: "配图-8",
        },
        {
          form: "GIS展示",
          description:
            "以GIS软件为平台开发的针对三维地下综合管线的管理系统软件。该平台将地下管网资源聚合，全域多维“一张图”，洞悉全局态势， 打造“全数字化移交、全智能化运营、全生命周期管理”的智慧管网。  　　",
          name: "",
          subtitle: "",
          image: "配图-9",
        },
        {
          form: "多种3D互动",
          description:
            "让yoghurt通过 720 °产品旋转及交互真正了解产品质量，并查看产品细节，阅读产品电子说明书。交互式设计还能突出产品功能，独特设计方面的动画和快速产品演示。",
          name: "",
          subtitle: "",
          image: "配图-10",
        },
      ],
      pricingFourData: [
        {
          title: "3d建模及模型处理",
          currency: "¥",
          price: "600",
          subtitle: "一个",
          features: [
            { title: "1.根据客户提供的产品照片3d建模（客户无3d模型）" },
            { title: "2.优化客户提供的3d模型（客户提供3d模型）" },
            { title: "3.根据建模复杂程度而定，详细报价与客服联系" },
          ],
          btn: {
            text: "立刻体验",
            link: "/",
          },
        },
        {
          title: "3d网页开发",
          currency: "¥",
          price: "300",
          subtitle: "一个",
          features: [
            { title: "1.根据客户需求定制网页页面" },
            { title: "2.3d网页嵌入到现有网页" },
            { title: "3.根据您的需求功能而定，详细报价与客服联系" },
          ],
          btn: {
            text: "立刻体验",
            link: "/",
          },
          isActive: true,
        },
        {
          title: "3d小程序定制",
          currency: "¥",
          price: "3000",
          subtitle: "次",
          features: [
            { title: "1.客户将小程序制作需求告知我们，我们提供开发定制服务" },
            { title: "2.根据业务要求设计小程序的外观及功能" },
            { title: "3.测试，上线，并小程序源码交付" },
          ],
          btn: {
            text: "立刻体验",
            link: "/",
          },
        },
        {
          title: "3d互动可视化平台",
          currency: "¥",
          price: "3000",
          subtitle: "次",
          features: [
            {
              title:
                "1.根据我公司已经制作好的模型，制作符合各个电商平台标准的模型文件",
            },
            { title: "2.客户可在电商平台自定义交互" },
            { title: "3.根据您的需求功能而定，详细报价与客服联系" },
          ],
          btn: {
            text: "立刻体验",
            link: "/",
          },
        },
      ],
    };
  },
  mounted() {
    // portfolio.FileId
    this.DwgRouterSuccess();
  },
  methods: {
    //获取Token
    DwgRouterSuccess() {
      var fileId = "10000767029252";
      let data = {
        fileId: fileId,
      };
      axios
        .post(
          "https://api.tomore.fun/api/HelpCad/DwgRouterSuccess",
          data
        )
        .then((res) => {
          this.viewToken = res.data.data;
          let options = new BimfaceSDKLoaderConfig();
          options.viewToken = this.viewToken;
          BimfaceSDKLoader.load(
            options,
            this.successCallback,
            this.failureCallback
          );
        });
    },
    successCallback(viewMetaData) {
      // ======== 判断是否为3D模型 ========
      // 获取DOM元素
      let dom4Show = document.getElementById("domId");
      let webAppConfig =
        new Glodon.Bimface.Application.WebApplication3DConfig();
      webAppConfig.domElement = dom4Show;
      // 创建WebApplication
      this.app = new Glodon.Bimface.Application.WebApplication3D(webAppConfig);
      // 添加待显示的模型
      this.app.addView(this.viewToken);
      // 从WebApplication获取viewer3D对象
      this.viewer3D = this.app.getViewer();
      // 监听添加view完成的事件
      this.viewer3D.addEventListener(
        Glodon.Bimface.Viewer.Viewer3DEvent.ViewAdded,
        () => {
          //自适应屏幕大小
          window.onresize = () => {
            this.viewer3D.resize(
              document.documentElement.clientWidth,
              document.documentElement.clientHeight - 40
            );
          };
          // 从viewer3D对象中获取模型对象model3D
          this.model3D = this.viewer3D.getModel();
          // 渲染3D模型
          this.viewer3D.render();
        }
      );
    },
    failureCallback() {
      // console.log(error);
    },
  },
};
</script>
<style lang="scss" scoped>
.model {
  flex: 1000;
  height: 800px;
  width: 80%;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
}

.main {
  text-align: center;
  margin: auto;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.buttons {
  margin: 5px;
  height: 30px;
}

.el-button--medium {
  font-size: 15px;
}
</style>