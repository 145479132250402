<template>
  <Layout :footer-style="3">
    <div
      class="
        slider-area slider-style-4
        variation-2
        slider-dot
        rn-slick-dot rn-slick-arrow
      "
    >
      <VueSlickCarousel v-bind="sliderSetting">
        <div
          v-for="(slider, index) in sliderData"
          :key="index"
          class="single-slide"
        >
          <div
            class="height-950 bg-overlay bg_image"
            :style="{
              'background-image': `url(https://bimhuicorebasic.oss-cn-shanghai.aliyuncs.com/banner%E5%AD%98%E5%82%A8/%E8%AE%BA%E5%9D%9Bbanner.jpg)`,
            }"
          >
            <div class="container">
              <div class="row row--30 align-items-center">
                <div class="col-lg-12">
                  <div class="inner text-center">
                    <h1 class="title" v-html="slider.title" />
                    <p class="description" v-html="slider.description" />
                    <div class="button-group mt--30">
                      <!-- <a
                        class="btn-default btn-large round"
                        href="http://120.79.35.135:6002"
                      >
                        开始 创作！
                      </a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
.el-radio {
  color: rgb(101, 101, 104);
}

.height-950 {
  height: 650px;
  display: flex;
  align-items: center;
}
</style>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import Layout from "../components/common/Layout";
export default {
  components: {
    VueSlickCarousel,
    Layout,
  },
  data() {
    return {
      sliderSetting: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      sliderData: [
        {
          title: `图模论坛 <br/> 开发中，敬请期待…`,
          //   description: `使用工作台高效处理模型，尽情展现.`,
        },
        // {
        //   image: "关于我们-2",
        //   title: `急速变化 <br/> 高品质效果.`,
        //   description: `模型处理？特效差？编辑器高效解决.`,
        // },
      ],
    };
  },
};
</script>