<template>
    <Layout buy-button-class="btn-default btn-small" :footer-style="3">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-8 height-650">
            <div class="single-slide">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="inner text-center">
                                <span class="subtitle theme-gradient" data-aos="fade-up" data-aos-delay="100">融合+
                                    一模多用</span>
                                <h1 class="title" data-aos="fade-up" data-aos-delay="150">
                                    高清BIM动画 <br />+ <br />模型互动平台.
                                </h1>
                                <p class="description" data-aos="fade-up" data-aos-delay="200">
                                    60S+4K高清BIM动画演示 ,模型二次开发，融合Web端高清展示平台
                                    <br /> 一份价格，多处使用，终身展示。
                                </p>
                                <div class="button-group mt--30" data-aos="fade-up" data-aos-delay="250">
                                    <router-link class="btn-default btn-large round" to="#">
                                        立刻 体验
                                    </router-link>
                                    <router-link class="btn-default btn-border btn-large round" to="/contact">
                                        联系我们
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Counterup Area -->
        <div class="our-counterup-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="thumbnail" data-aos="fade-up">
                            <img class="radius w-100" src="../../assets/images/bg/2000+1125.jpg" alt="Images" />
                        </div>
                        <div class="rwt-counterup-area ptb--60">
                            <div class="col-lg-12">
                                <Counter :counter-style="5" text-align="center" :counter-data="counterData"
                                    column="col-lg-3 col-md-6 col-sm-6 col-12" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Counterup Area -->

        <Separator />

        <!-- Start Service Area -->
        <div class="service-area rn-section-gapTop" id="miao1">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="content" data-aos="fade-up">
                            <h3 class="title">三维展示效果</h3>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb--10" data-aos="fade-up">
                            采用BimWork技术的H5页面，通过全方位无插件的WEB 3D技术，绝佳的三维展示效果让用户耳目一新。
                        </p>
                        <div class="readmore-btn" data-aos="fade-up">
                            <router-link class="btn-read-more" to="#">
                                <span></span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Service Area -->
        <!-- Start Video Area -->
        <div class="rwt-video-area rn-section-gapBottom">
            <div class="container">
                <Video :video-data="videoData" :video-style="2" column="col-lg-12" data-aos="fade-up" />
            </div>
        </div>
        <!-- End Video Area -->
        <!-- Start Elements Area -->
        <div class="rwt-tab-area rn-section-gap">
            <div class="container">
                <div class="row mb--40">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="更多案例" title="丰富的案例展示" data-aos="fade-up" />
                    </div>
                </div>
                <Tab :tab-data="tabData" :tab-style="3" />
            </div>
        </div>
        <!-- End Elements Area -->

        <!-- Start Elements Area -->
        <div class="rwt-split-area no-radius" id="miao2">
            <div class="wrapper">
                <Split :split-data="splitOneData" />
            </div>
        </div>
        <!-- End Elements Area -->

        <!-- Start Elements Area -->
        <div class="rwt-split-area" id="miao3">
            <div class="wrapper">
                <Split :split-data="splitTwoData" :split-style="4" />
            </div>
        </div>

    </Layout>
</template>

<script>
import Tab from '../../components/elements/tab/Tab'
import Layout from "../../components/common/Layout";
import Separator from "../../components/elements/separator/Separator";
import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
import AboutFour from "../../components/elements/about/AboutFour";
import AdvancePricing from "../../components/elements/pricing/AdvancePricing";
import Brand from "../../components/elements/brand/Brand";
import Counter from "../../components/elements/counterUp/Counter";
import ServiceOne from "../../components/elements/service/ServiceOne";
import Split from '../../components/elements/split/Split'
import Video from '../../components/elements/video/Video'
export default {
    name: 'WebAgency',
    components: { Tab, Video, Split, ServiceOne, Counter, Brand, AdvancePricing, AboutFour, SectionTitle, Separator, Layout },
    data() {
        return {
            videoData: [
                {
                    thumb: require(`@/assets/images/bg/视频封面1290x620.jpg`),
                    src: 'https://bimhuicorebasic.oss-cn-shanghai.aliyuncs.com/12s%E8%A7%86%E9%A2%91.mp4',
                    title: `三维动画展示效果`
                }
            ],
            splitOneData: {
                image: '1.jpg',
                title: '智慧楼宇运营平台',
                description: `汇报展示平台主要基于BIMWORK高渲染引擎，作为单独的项目级平台供客户使用。基于BIMWORK高渲染引擎管道，生成高特效三维汇报展示平台。运用BIMWORK高特效引擎的汇报展示平台，具有高效果、本地稳定、互动优良及沉浸式体验效果.`,
                list: [
                    '- 1.城市底座生成',
                    '- 2.BIM模型融合',
                    '- 3.引擎脚本开发',
                    '- 4.UI前后端开发'
                ],
                btn: {
                    text: '联系我们',
                    link: '/contact'
                }
            },
            splitTwoData: {
                image: '2.jpg',
                title: '智慧交通平台',
                description: `Unity是实时3D互动内容创作和运营平台。包括游戏开发、美术、建筑、汽车设计、影视在内的所有创作者，借助Unity将创意变成现实。Unity平台提供一整套完善的软件解决方案，可用于创作、运营和变现任何实时互动的2D和3D内容，支持平台包括手机、平板电脑、PC、游戏主机、增强现实和虚拟现实设备。BIMWORK整合了Unity部分技术，自主创新了各类脚本及Untity高特效渲染管道。主要使用C#，Python，Javascript实现UI设计、BIM开发、GIS数据融合、物联网开发、数据结构等。通过基于WebGL的BIMWORK渲染引擎，实现在线BIMWORK网站在线交互。 .`,
                counterData: [
                    {
                        number: 199,
                        title: '稳定运行时间',
                    },
                    {
                        number: 575,
                        title: '项目模型数量',
                    },
                    {
                        number: 69,
                        title: '制作周期',
                    },
                    {
                        number: 500,
                        title: '已观看人数',
                    }
                ]
            },
            brandList: [
                {
                    image: require(`@/assets/images/brand/brand-01.png`)
                },
                {
                    image: require(`@/assets/images/brand/brand-02.png`)
                },
                {
                    image: require(`@/assets/images/brand/brand-03.png`)
                },
                {
                    image: require(`@/assets/images/brand/brand-04.png`)
                },
                {
                    image: require(`@/assets/images/brand/brand-05.png`)
                },
                {
                    image: require(`@/assets/images/brand/brand-06.png`)
                },
                {
                    image: require(`@/assets/images/brand/brand-07.png`)
                },
                {
                    image: require(`@/assets/images/brand/brand-08.png`)
                }
            ],
            tabData: [
                {
                    id: 'strategy-menu',
                    text: '市政项目',
                    dataList: [
                        {
                            image: '4.jpg',
                            title: '效果图动画',
                            description: '某XXX项目'
                        },
                        {
                            image: '5.jpg',
                            title: '效果图动画',
                            description: '某XXX项目'
                        },
                    ],
                },
                {
                    id: 'development-menu',
                    text: '公路项目',
                    dataList: [
                        {
                            image: '6.jpg',
                            title: '效果图动画',
                            description: '某XXX项目'
                        },
                        {
                            image: '7.jpg',
                            title: '效果图动画',
                            description: '某XXX项目'
                        },
                    ],
                },
                {
                    id: 'reporting-menu',
                    text: '其他类型',
                    dataList: [
                        {
                            image: '8.jpg',
                            title: '效果图动画',
                            description: '某XXX项目'
                        },
                        {
                            image: '9.jpg',
                            title: '效果图动画',
                            description: '某XXX项目'
                        },
                        {
                            image: '10.jpg',
                            title: '效果图动画',
                            description: '某XXX项目'
                        },
                    ]
                }
            ],
            counterData: [
                {
                    number: 99,
                    title: '已经成功案例',
                },
                {
                    number: 575,
                    title: '客户数',
                },
                {
                    number: 15,
                    title: '部署平均天数',
                },
                {
                    number: 100,
                    title: '满意度',
                }
            ]
        }
    }
}
</script>