<template>
  <!-- :header-transparency="true" -->
  <Layout :footer-style="3" :show-top-header="false">
    <!-- 1- 海报页 -->
    <div class="
        slider-area slider-style-4
        variation-2
        slider-dot
        rn-slick-dot rn-slick-arrow
      " v-loading="loading" element-loading-text="获取数据中，请稍侯...">
      <VueSlickCarousel v-bind="sliderSetting">
        <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
          <div class="height-950 bg-overlay bg_image" :style="{
            'background-image': 'url(' + slider.image + ')',
          }">
            <div class="container">
              <div class="row row--30 align-items-center">
                <div class="col-lg-12">
                  <div class="inner text-center">
                    <h1 class="title" v-html="slider.title" />
                    <p class="description" v-html="slider.description" />
                    <div class="button-group mt--30">
                      <a class="btn-default btn-large round" href="https://tumorefun.bim.work/">
                        开始 创作！
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="main">
      <!-- 2- 搜索页 -->
      <el-form ref="form" :model="form" label-width="82px" style="max-width: 1500px; margin: 0 auto">
        <el-form-item label="图模类型" size="small">
          <el-radio v-model="form.type" label="模型" border @change="initData">模型</el-radio>
          <el-radio v-model="form.type" label="图纸" border @change="initData">图纸</el-radio>
        </el-form-item>
        <el-form-item label="行业类型">
          <el-radio-group v-model="form.industry" size="small" @change="initData">
            <el-radio-button v-for="city in cities" :label="city" :key="city">{{
              city
            }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="其他类型" prop="free">
          <el-checkbox v-model="form.free" @change="initData" :disabled="isfree">免费</el-checkbox>
          <el-checkbox v-model="form.vipFree" @change="initData" :disabled="isvipFree">会免</el-checkbox>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <!-- 3- 排序页 -->
      <el-form ref="form" :model="form" label-width="82px" style="max-width: 1500px; margin: 0 auto">
        <el-form-item label="排序筛选" size="small">
          <el-radio-group v-model="form.sort" size="small" @change="initData">
            <el-radio-button v-for="sort in sorts" :label="sort" :key="sort">{{
              sort
            }}</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <!-- //图片布局 -->
      <el-form style="max-width: 1500px; margin: 0 auto">
        <div class="map-card-group">
          <el-row>
            <!-- //数据来源curPageMapCards -->
            <el-col class="card-col" :xs="12" :sm="9" :md="6" :lg="5" :xl="5" v-for="item in curPageMapCards"
              :key="item.key">
              <el-card class="card-item" :id="item.name" shadow="hover"
                :body-style="{ padding: '2px', color: 'aquamarine' }">
                <div @click="routerdetail(item)">
                  <el-image :key="item.key" :src="item.urlImage" lazy class="card-image">
                  </el-image>
                </div>
                <!-- 1-2 文件名 -->
                <div class="text-ellipsis">
                  <div class="textel">
                    <div class="name" style="
                        color: black;
                        float: left;
                        font-size: 15px;
                        margin-left: 5px;
                      ">
                      {{ item.name }}
                    </div>
                    <div class="icons">
                      <svg class="icon" aria-hidden="true" style="
                          width: 25px;
                          height: 22px;
                          margin-left: 10px;
                          color: black;
                          cursor: pointer;
                        ">
                        <use xlink:href="#icon-Buy"></use>
                      </svg>
                      <el-tooltip content="下载" placement="bottom">
                        <svg class="icon" aria-hidden="true" style="
                            width: 25px;
                            height: 22px;
                            margin-right: 2px;
                            color: black;
                            cursor: pointer;
                          ">
                          <use xlink:href="#icon-download1"></use>
                        </svg>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <el-divider></el-divider>
                <!-- 1- 图纸信息板块 -->
                <div class="card-bottom">
                  <!-- 1-3 按钮模块 -->
                  <!-- //头像 -->
                  <div class="avatar">
                    <el-avatar :size="30" icon="el-icon-user-solid" style="margin-right: 6px"></el-avatar>
                    <div class="title">{{ item.account }}</div>
                  </div>
                  <div class="icon">
                    <el-tooltip content="浏览次数" placement="bottom">
                      <i class="el-icon-view" style="margin-right: 10px">{{
                        item.showint
                      }}</i>
                    </el-tooltip>
                    <el-tooltip content="下载次数" placement="bottom">
                      <i class="el-icon-download">{{ item.dowanloadint }}</i>
                    </el-tooltip>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="pagination" style="margin-top: 40px; margin-bottom: 40px; margin-left: 600px">
      <pagination :total="total" :page.sync="params.currentPage" :limit.sync="params.pageSize" @pagination="initData" />
    </div>
    <!-- End Slider Area -->
  </Layout>
</template>

<script>
import axios from "axios";
import Layout from "../components/common/Layout";
import VueSlickCarousel from "vue-slick-carousel";
import Pagination from "@/components/Pagination";
const sortsOptions = ["推荐", "热门", "最新发布", "浏览最多"];
const cityOptions = [
  "室外场景",
  "室内场景",
  "路桥隧道",
  "机电水暖",
  "工业设备",
  "食品饮料",
  "建筑",
  "市政",
  "人物",
  "动物",
  "其他",
  "乐器",
];
export default {
  name: "Startup",
  components: {
    Layout,
    VueSlickCarousel,
    Pagination,
  },
  data() {
    return {
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 20,
      },
      loading: false,
      // 数据
      curPageMapCards: [],
      cities: cityOptions,
      sorts: sortsOptions,
      form: {
        //排序
        sort: "推荐",
        //场景类型
        industry: "室外场景",
        //图模类型
        type: "模型",
        //是否免费
        free: false,
        //会员免费
        vipFree: false,
      },
      sliderSetting: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      sliderData: [
        {
          image:
            "https://bimhuicorebasic.oss-cn-shanghai.aliyuncs.com/banner%E5%AD%98%E5%82%A8/%E5%9B%BE%E6%A8%A1%E5%B8%82%E5%9C%BAbanner1.jpg",
          title: `模型积分兑换 <br/> 高效便捷.`,
          description: `使用工作台高效处理模型，尽情展现.`,
        },
        {
          image:
            "https://bimhuicorebasic.oss-cn-shanghai.aliyuncs.com/banner%E5%AD%98%E5%82%A8/%E5%9B%BE%E6%A8%A1%E5%B8%82%E5%9C%BAbanner2.jpg",
          title: `急速变化 <br/> 高品质效果.`,
          description: `模型处理？特效差？编辑器高效解决.`,
        },
      ],
      isfree: false,
      isvipFree: false,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    routerdetail(item) {
      console.log(item);
      var id = item.Id;
      var parentId = item.parentId;
      if (item.type == "模型") {
        var type = 1;
      } else {
        var type = 2;
      }
      var fileId = item.fileId;
      this.$router.push({
        path: `/malldetail/${id}/${parentId}/${fileId}/${type}`,
        params: {
          id: id,
          parentId: parentId,
          fileId: fileId,
          type: type,
        },
      });
    },
    //获取数据
    initData() {
      this.isvipFree = this.form.free;
      this.isfree = this.form.vipFree;
      //默认情况下是个人查询
      this.loading = true;
      let query = {
        sort: this.form.sort,
        industry: this.form.industry,
        type: this.form.type,
        free: this.form.free,
        vipFree: this.form.vipFree,
        currentPage: this.params.currentPage,
        pageSize: this.params.pageSize,
      };
      //
      axios
        .post("http://api.tomore.fun/api/BimWorkWebService/GetPlant", query)
        .then((res) => {
          this.curPageMapCards = res.data.data.list;
          this.total = res.data.data.pagination.total;
          this.loading = false;
        })
        .catch((e) => {
          this.$message.error("获取失败，请稍后再试");
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-radio {
  color: rgb(101, 101, 104);
}

.height-950 {
  height: 650px;
  display: flex;
  align-items: center;
}

.main {
  margin-top: 20px;
  margin-left: 15%;
}

.el-col-12 {
  width: 50%;
}

.el-radio-button {
  margin-right: 10px;
}

.el-radio {
  margin-right: 5px;
}

.map-card-group {
  width: 100%;

  .el-divider {
    margin: 2px 0;
  }

  .card-image {
    height: 200px;
    width: 100%;
    text-align: center;
  }

  .card-col {
    padding: 5px;
  }

  .card-imge {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--el-text-color-secondary);
    font-size: 15px;
    // color: blue;
  }

  .demo-qrcode-image-item {
    width: 200px;
  }

  .bg-error {
    background: #ecfaf1;
  }

  .card-bottom {
    padding: 2px;
    font-size: 13px;

    .operation {
      display: flex;
      height: 40px;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
  }

  .text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    // width: 260px;
    // margin-left:39px;
  }

  .width150 {
    width: 200px !important;
  }

  .co-map-tag {
    position: absolute;
    right: 3px;
    top: 3px;
  }

  .textel {
    margin-right: 2px;
    font-size: 13px;
  }

  .card-item {
    position: relative;
  }

  .icon {
    // font-size: 12px;
    margin-top: 5px;
    float: right;
    color: rgb(67, 66, 66);
    margin-right: 2px;
  }

  .icons {
    margin-bottom: 2px;
  }
}

.avatar {
  float: left;
}

.span {
  margin-bottom: 5px;
}

.title {
  color: black;
  float: right;
  font-size: 13px;
  margin-top: 5px;
}
</style>

