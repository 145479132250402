<template>
    <router-view />
</template>

<script>
import AppFunctions from './helpers/AppFunctions'
export default {
    data() {
        return {
            AppFunctions
        }
    },
    created() {
        //默认执行一次变亮
        AppFunctions.toggleClass('body', 'active-light-mode')
    },

}
</script>
<style lang="scss">
#app {}

html,
body {
    // width: 100%;
    // height: 100%;
    // margin: 0;
    // padding: 0;
}
</style>