<template>
  <!-- 首页 -->
  <Layout :footer-style="3" :showTopHeader="true">
    <!-- Start Slider Area -->
    <div
      class="
        demo-slider-area
        slider-area
        bg-transparent
        slider-style-1
        pb--100
        pt--70
        variation-default
        bg_image
      "
      :style="{
        'background-image': `url(https://bimhuicorebasic.oss-cn-shanghai.aliyuncs.com/banner%E5%AD%98%E5%82%A8/%E9%A6%96%E9%A1%B5banner.jpg)`,
      }"
      style="height: 700px"
    >
      <!-- //首页==模块1 -->
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center">
              <h1
                class="title display-two"
                data-aos="fade-up"
                data-aos-delay="100"
                style="
                  color: white;
                  font-size: 48px;
                  margin-top: 62px;
                  text-align: left !important;
                "
              >
                领先的 二三维 可视化应用服务平台 <br />
                <span class="theme-gradient" style="margin-top: 25px"
                  >2D图纸</span
                >
                & <span class="theme-gradient">3D模型</span
                ><!-- class="theme-gradient" -->
                <br />
                <span
                  style="
                    font-weight: 400;
                    font-size: 52px;
                    font-weight: 700;
                    margin-top: 30px;
                    text-align: left !important;
                  "
                  >云交互、云协作、云展示
                </span>
              </h1>
              <div
                class="button-group"
                data-aos="fade-up"
                data-aos-delay="200"
                style="text-align: left"
              >
                <a
                  class="btn-default btn-arrow-right btn-icon"
                  href="#"
                  @click="test()"
                  >免费使用
                  <Icon name="arrow-down" />
                </a>
              </div>
            </div>
          </div>
          <div class="hero__model">
            <iframe
              id="landing-model"
              class="hero__iframe"
              width="500px"
              height="500px"
              src="https://sketchfab.com/models/99bfe75ebd734fa3832a63e02e2cacf7/embed?annotations_visible=0&autospin=-0.1&autostart=1&camera=0&double_click=0&internal=1&max_texture_size=1024&orbit_constraint_pan=1&orbit_constraint_zoom_in=40&orbit_constraint_zoom_out=60&preload=1&scrollwheel=0&sound_enable=0&transparent=1&ui_animations=0&ui_annotations=0&ui_ar=1&ui_ar_help=0&ui_color=white&ui_fadeout=0&ui_fullscreen=1&ui_help=0&ui_infos=0&ui_inspector=0&ui_settings=0&ui_stop=0&ui_theatre=0&ui_theme=dark&ui_vr=0&ui_watermark=0"
              allowfullscreen
              mozallowfullscreen="true"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- <Separator /> -->
    <div class="rwt-split-area no-radius" id="miao2">
      <div>
        <Splitphone :split-data="splitOneData" />
      </div>
    </div>
    <div class="rwt-timeline-area rn-section-gap" id="miao">
      <div class="container">
        <div class="row mt_dec--20">
          <div class="col-lg-12">
            <Timeline :timeline-data="timelineData" />
          </div>
        </div>
      </div>
    </div>
    <Separator />
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Icon from "../components/icon/Icon";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import Portfolio from "../components/elements/portfolio/Portfolio";
import Portfolio2 from "../components/elements/portfolio/Portfolio2";
import Splitphone from "../components/elements/split/Splitphone";
import Timeline from "../components/elements/timeline/Timeline";
export default {
  name: "Home",
  components: {
    Icon,
    Layout,
    Separator,
    SectionTitle,
    Portfolio,
    Portfolio2,
    Splitphone,
    Timeline,
  },
  data() {
    return {
      splitOneData: {
        image: "1.jpg",
        title: "模型展示",
        description: `图模坊模型展示，通过轻量化、无插件的方式来加载高质量的3D模型。实现了模型的720度全方位展示，且兼具互动特性，支持产品模型的爆炸、拆分、旋转、 缩放、移动等在线操作体验，还可以通过微信、手机一键分享至任何网页或平台。`,
        btn: {
          text: "浏览图模市场 →",
          link: "/Mall",
        },
        btn2: {
          text: "进入工作台 →",
        },
      },
      timelineData: [
        {
          id: "1",
          // date: "Step-1",
          title: "图模坊",
          title2: "可视化编辑器",
          description:
            "以成熟的3D编辑模式和便捷的业务交互设计，搭建全要素、多精度的孪生数据底座，高效实现可视化场景，赋能用户三维呈现与交互的无限创造力。",
          image: "配图-1",
          workingStep: [
            {
              stepTitle: "模型效果编辑器",
              stepDescription: "基于WEBGL的3D图形引擎",
              stepDescription2: "采用云计算技术可视化在线编辑",
              stepDescription3: "可保存编辑效果一键发送",
            },
            {
              stepTitle: "无代码大屏编辑器",
              stepDescription: "支持三维场景中修改设备点位、属性数据等信息。",
              stepDescription2:
                "支持数据看板层面，根据数据类型选择各形态的图表展示。",
              stepDescription3: "支持模型、看板数据业务绑定。",
            },
          ],
          path: "/Editor",
          buttontext: "了解更多",
        },
        {
          id: "3",
          // date: "Step-3",
          title: "丰富的模型增值服务",
          description:
            "图模坊支持为用户的业务场景深度定制开发，包括模型制作、模型处理，以及围绕3D模型的产品页面和小程序开发、可视化大屏设计等，也可为建设系统、材料设备厂商提供BIM/CIM构件制作，打造数字化产品说明书。",
          image: "配图-3",
          workingStep: [
            {
              stepTitle: "BIM可视化组件",
              stepDescription:
                "通过BIM可视化组件系统，对模型进行轻量化预览、审阅、批注和成果确认。",
            },
            {
              stepTitle: "轻量化引擎技术",
              stepDescription:
                "轻量化的BIM渲染引擎，可以让查看BIM模型及应用BIM模型更加方便快捷。",
            },
          ],
          path: "/Service",
          buttontext: "了解更多",
        },
        {
          id: "4",
          // date: "Step-4",
          title: "图模市场",
          title2: "让你的作品更具价值",
          description:
            "自主上传，支持各类通用格式，模型自动转换为独有的轻量化文件，用户可以在线查看，也可以一键分享给同事和朋友。",
          image: "配图-4",
          workingStep: [
            {
              stepTitle: "数字化技术",
              stepDescription:
                "利用数字化技术，为建筑全生命周期提供完整的建筑工程数据库。",
            },
            {
              stepTitle: "集成化信息",
              stepDescription:
                "数据库中包含几何信息和非几何信息，可提高信息的集成化程度。",
            },
          ],
          path: "/Mall",
          buttontext: "进入图模市场",
        },
      ],
    };
  },
  methods: {
    test() {
      window.open("https://www.tomore.fun/");
    },
  },
};
</script>
<style lang="scss" scoped>
.inner {
  text-align: left !important;
}
.hero__model {
  width: 40%;
  height: 650px;
  display: flex;
  position: absolute;
  right: 0;
  float: right;
  align-items: center;
  z-index: 1000;
}
</style>