import dayjs from 'dayjs'
const jnpf = {
  dateFormat(date, format) {
    format = format || 'YYYY-MM-DD HH:mm'
    if (!date) return ''
    return dayjs(date).format(format)
  },
  formatter: (size) => {
    if (size < 1024) {
      return size + 'B';
    } else if (size < 1024 * 1024) {
      return (size / 1024).toFixed(1) + 'KB';
    } else if (size < 1024 * 1024 * 1024) {
      return (size / 1024 / 1024).toFixed(1) + 'M';
    } else {
      return (size / 1024 / 1024 / 1024).toFixed(1) + 'G';
    }
  }
}
export default jnpf