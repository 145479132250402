<template>
  <div class="row row--15 service-wrapper">
    <div
      class="col-lg-4 col-md-6 col-sm-6 col-12"
      v-for="(service, index) in serviceList"
      :key="index"
    >
      <div
        :class="`service ${serviceStyle} ${checkTextAlign}`"
        data-aos="fade-up"
        data-aos-delay="70"
      >
        <div class="icon">
          <Icon :name="service.icon" :class="`size-${iconSize}`" />
        </div>
        <div class="content">
          <h4 class="title w-600">
            <router-link to="/service-details" v-html="service.title" />
          </h4>
          <p
            class="description b1 color-gray mb--0"
            v-html="service.description"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../../icon/Icon";

export default {
  name: "ServiceOne",
  components: { Icon },
  props: {
    serviceStyle: {
      type: String,
    },
    iconSize: {
      type: String | Number,
      default: 62,
    },
    textAlign: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      serviceList: [
        {
          icon: "activity",
          title: "3D建模/模型处理",
          description:
            "高效搭建不同精度三维模型和场景，并可提供专业可视化呈现效果",
        },
        {
          icon: "cast",
          title: "3d网页/小程序开发",
          description: "基于3D展示技术的网页和小程序开发，赋予模型更多可能",
        },
        {
          icon: "map",
          title: "可视化设计服务",
          description: "专业的可视化设计团队，为您量身打造可视化视觉应用",
        },
      ],
    };
  },
  computed: {
    checkTextAlign() {
      if (this.textAlign === "left") return "text-start";
      else if (this.textAlign === "center") return "text-center";
      else if (this.textAlign === "end") return "text-end";
      else return "";
    },
  },
};
</script>