<template>
  <!-- //路由设置 -->
  <ul class="mainmenu">
    <li class="has-droupdown has-menu-child-item">
      <router-link to="/">首页</router-link>
    </li>
    <li class="with-megamenu has-menu-child-item">
      <a href="#">
        产品与服务
        <Icon name="plus" class="collapse-icon plus" />
        <Icon name="minus" class="collapse-icon minus" />
      </a>
      <div class="rn-megamenu">
        <div class="wrapper">
          <div class="row row--0">
            <div class="col-lg-3 single-mega-item">
              <ul class="mega-menu-item">
                <li>
                  <div>
                    <span class="Top" style="margin-left: 7.1%"
                      >产品与服务</span
                    >
                    <span class="rn-badge-card" style="margin-left: 3%"
                      >介绍</span
                    >
                  </div>
                </li>
                <li
                  style="
                    font-size: 13px;
                    padding: 0px 25% 0px 7.1%;
                    line-height: 2;
                    text-align: left;
                  "
                >
                  <span
                    >图模坊为图纸和模型提供了丰富的展现形式和视觉效果，致力于为客户提供有价值的可视化技术服务
                  </span>
                </li>
                <!-- <li>
                  <router-link to="/business-consulting">
                    <div class="Top">
                      ShowCad自动化业务<span class="rn-badge-card">服务</span>
                    </div>
                  </router-link>
                </li>
                <li>
                  <router-link to="/error?id=12345">
                    支持全版本CAD协同预览
                  </router-link>
                </li>
                <li>
                  <router-link to="/error?id=123456"
                    >ShowCAD 服务应用</router-link
                  >
                </li>
                <li>
                  <router-link to="/error?id=1234567"
                    >ShowCAD 服务说明</router-link
                  >
                </li>
                <li>
                  <router-link to="/error?id=12345678"
                    >ShowCAD 业务体系</router-link
                  >
                </li> -->
              </ul>
            </div>
            <div class="col-lg-3 single-mega-item">
              <ul class="mega-menu-item">
                <li>
                  <router-link to="/Editor">
                    <div class="Top">自主图模服务</div>
                    <span class="rn-badge-card">所见即所得</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/ShowCAD">二维应用ShowCAD</router-link>
                </li>
                <li>
                  <router-link to="/ShowModel">三维应用ShowModel</router-link>
                </li>
                <li>
                  <router-link to="/Editor">模型效果编辑器</router-link>
                </li>
                <!-- <li>
                  <router-link to="/Editor">无代码大屏编辑器</router-link>
                </li> -->
                <!-- <li>
                                    <router-link to="/Portfolio">3D模型应用</router-link>
                                </li> -->
              </ul>
            </div>
            <div class="col-lg-3 single-mega-item">
              <ul class="mega-menu-item">
                <li>
                  <router-link to="/Service">
                    <div class="Top">增值服务</div>
                    <span class="rn-badge-card">个性化服务</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/Service">3D建模/模型处理</router-link>
                </li>
                <!-- <li>
                  <router-link to="/error?id=6"
                    >模型轻量化/格式转换</router-link
                  >
                </li> -->
                <li>
                  <router-link to="/Service">3D网页/小程序开发</router-link>
                </li>
                <li>
                  <router-link to="/Service">可视化设计服务</router-link>
                </li>
                <!-- <li>
                  <router-link to="/error?id=6">项目看板拖拽管理</router-link>
                </li>
                <li>
                  <router-link to="/error?id=7">产品价格体系</router-link>
                </li> -->
              </ul>
            </div>
            <div class="col-lg-3 single-mega-item">
              <ul class="mega-menu-item">
                <li>
                  <router-link to="/Price">
                    <div class="Top" style="font-size: 14px">产品/服务价格</div>
                    <span class="rn-badge-card">详询客服</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/Price">自主服务价格</router-link>
                </li>
                <li>
                  <router-link to="/Price">增值服务价格</router-link>
                </li>
                <!-- <li>
                  <router-link to="/error?id=001">ShowWork看板协同</router-link>
                </li>
                <li>
                  <router-link to="/error?id=001">ShowCAD 协作应用</router-link>
                </li>
                <li>
                  <router-link to="/error?id=001"
                    >ShowModel 协作应用</router-link
                  >
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
    <!-- <li class="has-droupdown has-menu-child-item">
            <a href="#">
                解决方案
                <Icon name="plus" class="collapse-icon plus" />
                <Icon name="minus" class="collapse-icon minus" />
            </a>
            <ul class="submenu">
                <li>
                    <router-link to="/web-agency">三维数字展现平台</router-link>
                </li>
                <li>
                    <router-link to="/error?id=11">智慧动画效果图</router-link>
                </li>
                <li>
                    <router-link to="/error?id=12">智慧园区运营平台</router-link>
                </li>
                <li>
                    <router-link to="/error?id=13">交通仿真模拟平台</router-link>
                </li>
            </ul>
        </li> -->
    <!-- <li>
      <router-link to="/Shuziluansheng">数字孪生</router-link>
    </li> -->
    <!-- <li>
      <router-link to="/Duoyuananli">多元案例</router-link>
    </li> -->
    <li>
      <router-link to="/Mall">图模市场</router-link>
    </li>
    <li>
      <router-link to="/Socio">图模论坛</router-link>
    </li>
    <!-- <li>
            <router-link to="/">论坛服务</router-link>
        </li> -->
    <!-- <li>
            <router-link to="/about">关于我们</router-link>
        </li> -->
    <li>
      <router-link to="/contact">关于我们</router-link>
    </li>
    <!-- <li>
      <a href="http://www.bimhuicloud.com/" target="_blank">工作台</a>
    </li> -->
  </ul>
</template>

<script>
import Icon from "../../icon/Icon";
export default {
  name: "Nav",
  components: { Icon },
  methods: {
    routeview() {
      this.$router.push({
        path: "/business-consulting",
        params: {
          id: "1",
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.Top {
  font-size: 15px;
  // text-align: center;
  font-weight: 600;
  color: black !important;
}
</style>