<template>
  <Layout :footerStyle="3">
    <div
      class="
        slider-area slider-style-4
        variation-2
        slider-dot
        rn-slick-dot rn-slick-arrow
      "
    >
      <VueSlickCarousel v-bind="sliderSetting">
        <div
          v-for="(slider, index) in sliderData"
          :key="index"
          class="single-slide"
        >
          <div
            class="height-950 bg-overlay bg_image"
            :style="{
              'background-image': `url(${require(`@/assets/images/bg/${slider.image}.jpg`)})`,
            }"
          >
            <div class="container">
              <div class="row row--30 align-items-center">
                <div class="col-lg-12">
                  <div class="inner text-center">
                    <h1 class="title" v-html="slider.title" />
                    <p class="description" v-html="slider.description" />
                    <div class="button-group mt--30">
                      <!-- <a
                        class="btn-default btn-large round"
                        href="http://120.79.35.135:6002"
                      >
                        开始 创作！
                      </a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="flex justify-between main fadeInUp">
      <el-row>
        <el-col :xs="0.5" :sm="0.5" :md="0.5" :lg="0.5" :xl="3"
          ><div
            class="grid-content bg-purple"
            style="color: transparent; z-index: -99; margin-top: -10%"
          >
            .
          </div></el-col
        >
        <el-col :span="7"
          ><div class="grid-content bg-purple-light">
            <!-- <滚动介绍> -->
            <div @mousewheel.stop="">
              <swiper
                :options="swiperOption2"
                ref="swiper2"
                class="swiper-main"
                @slideChangeTransitionStart="
                  onSwiperSlideChangeTransitionStart2
                "
                style="margin-left: 10%; margin-top: 25%; margin-right: 10%"
              >
                <swiper-slide>
                  <div class="main-data" ref="data">
                    基于新一代信息技术，融合位置物联网、GIS可视化等技术，实现对人员、物资的精确管理。在重点区域设置电子围栏，无权限人员进入即刻告警；对人员超/缺员、串岗滞留等规范化管理，提升工厂的秩序管理、提高生产效率。
                    <div class="fc">
                      <a
                        class="btn-default round btn-icon"
                        href="#Home"
                        style="font-size: 14px"
                        >了解详情
                      </a>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="main-data">
                    数字园区是运用数字化技术实现智能化管理、分析、决策的数字化园区平台，那么我们应该如何建设数字化智慧园区呢？数字化智慧园区建设分为四个方面：一、园区平台设计理念。二、园区智能化建设实施。三、园区智能化运维。四、园区项目价值。
                    <!-- </div> -->
                    <div class="fc">
                      <a
                        class="btn-default round btn-icon"
                        href="#Home"
                        style="font-size: 14px"
                        >了解详情
                      </a>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="main-data">
                    <!-- <div class="data-title"> -->
                    <!-- <span style="margin-left: 5px;"></span> -->
                    <!-- </div> -->
                    <!-- <div class="data-text line-height-1-5"> -->
                    建立统一的应急管理与日常管理、对内与对外服务的管理体系，满足“安全、高效、绿色”的现代产业园区建设规划要求
                    安全监管统一规范，生产数据体系化分析，实现环保平台、智慧化工综合管理平台等数据的接入，实现园区产业
                    数据互联管理数字化
                    <!-- </div> -->
                    <div class="fc">
                      <a
                        class="btn-default round btn-icon"
                        href="#Home"
                        style="font-size: 14px"
                        >了解详情
                      </a>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <!-- <滚动介绍> -->
          </div>
        </el-col>
        <el-col :xs="0.5" :sm="0.5" :md="0.5" :lg="7" :xl="5"
          ><div class="grid-content bg-purple-light">
            <!-- <轮播图> -->
            <div class="swiper-box" style="margin-bottom: 2%">
              <swiper
                class="wrapper"
                :options="swiperOption"
                ref="swiper1"
                @slideChangeTransitionStart="onSwiperSlideChangeTransitionStart"
                style="margin-left: -1%; margin-top: 15%"
              >
                <swiper-slide v-for="(item, index) in imglist" :key="index">
                  <img class="slider" :src="item" />
                </swiper-slide>
                <div
                  class="swiper-pagination swiper-pagination-wrapper"
                  slot="pagination"
                ></div>
              </swiper>
            </div>
            <!-- <轮播图> -->
          </div></el-col
        >
        <el-col :span="6"
          ><div class="grid-content bg-purple-light">
            <div
              class="swiper-title"
              style="margin-top: 3%; z-index: 99; font-size: 24px"
            >
              {{ titlelist[wpli] }}
            </div>
          </div></el-col
        >
      </el-row>
    </div>
    <div class="xiezuo">
      <el-row :gutter="20">
        <el-col :span="12">
          <div
            class="grid-content bg-purple"
            style="margin-top: 16%; margin-left: 20%; margin-right: 20%"
          >
            <div>
              <span
                class="theme-gradient"
                style="font-size: 34px; font-weight: 700"
                >数字园区</span
              >
            </div>
            <div class="content">
              <p>
                数字园区是运用数字化技术实现智能化管理、分析、决策的数字化园区平台，那么我们应该如何建设数字化智慧园区呢？数字化智慧园区建设分为四个方面：一、园区平台设计理念。二、园区智能化建设实施。三、园区智能化运维。四、园区项目价值。　
              </p>
            </div>
            <div class="al">
              <a
                class="btn-default round btn-icon"
                href="#Home"
                style="font-size: 14px"
                >了解详情
              </a>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div
            class="grid-content bg-purple"
            style="
              margin-top: 13%;
              margin-left: 10%;
              margin-right: 10%;
              background-color: transparent;
            "
          >
            <img
              src="https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B%20%2811%29.jpg"
              alt
              style="height: 350px; width: 600px"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="20">
      <el-col :span="12">
        <div
          class="grid-content bg-purple"
          style="margin-top: 6%; margin-left: 20%; margin-right: 20%"
        >
          <span class="theme-gradient" style="font-size: 35px; font-weight: 700"
            >智慧平台</span
          >
          <div class="content">
            <p>
              建立统一的应急管理与日常管理、对内与对外服务的管理体系，满足“安全、高效、绿色”的现代产业园区建设规划要求
              安全监管统一规范，生产数据体系化分析，实现环保平台、智慧化工综合管理平台等数据的接入，实现园区产业
              数据互联管理数字化
            </p>
          </div>
          <div class="al">
            <a
              class="btn-default round btn-icon"
              href="#Home"
              style="font-size: 14px"
              >了解详情
            </a>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div
          class="grid-content bg-purple"
          style="margin-top: 3%; margin-left: 10%; margin-right: 10%"
        >
          <img
            src="https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B%20%2810%29.jpg"
            alt
            style="height: 350px; width: 600px"
          />
        </div>
      </el-col>
    </el-row>
    <!-- <div class="xiezuo">
      <el-row :gutter="20">
        <el-col :span="3">
          <div class="grid-content bg-purple"></div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple" style="margin-top: 6%">
            <div>
              <span
                class="theme-gradient"
                style="font-size: 35px; font-weight: 700; margin-left: 20%"
                >模型-立交</span
              >
            </div>
            <div class="content">
              <p>
                交通立交桥模型，全称"立体交叉桥"，是在城市重要交通交汇点建立的上下分层、多方向行驶、互不相扰的现代化陆地桥。主要是设置在城内交通要道上。主要形式有十字型立体交叉、Y型立体交叉和T型立体交叉。其通行方法为：干线上的主交通流走上跨道或下穿道，左右转弯的车辆仍在平面交叉改变运动方向。
              </p>
            </div>
            <div class="al">
              <a
                class="btn-default round btn-icon"
                href="#Home"
                style="font-size: 15px"
                >了解详情
              </a>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple" style="margin-top: 3%">
            <img
              src="https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B%20%283%29.jpg"
              alt
              style="height: 350px; margin-left: 10%; width: 600px"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="20">
      <el-col :span="3">
        <div class="grid-content bg-purple"></div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple" style="margin-top: 6%">
          <span
            class="theme-gradient"
            style="font-size: 35px; font-weight: 700; margin-left: 20%"
            >模型-房屋</span
          >
          <div class="content">
            <p>
              房屋建筑模型，上有屋顶，周围有墙，能防风避雨，御寒保温，供人们在其中工作、生活、学习、娱乐和储藏物资。房屋是人类建造物，具有自然功能和社会功能。自然功能指对人类个体或群体生命、身体提供保护和停留的功能，社会功能指房屋是人类个体或群体的权利标志和所受管辖的归属标志。
            </p>
          </div>
          <div class="al">
            <a
              class="btn-default round btn-icon"
              href="#Home"
              style="font-size: 15px"
              >了解详情
            </a>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple" style="margin-top: 3%">
          <img
            src="https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B%20%2815%29.jpg"
            alt
            style="height: 350px; margin-left: 10%; width: 600px"
          />
        </div>
      </el-col>
    </el-row> -->
  </Layout>
</template>

<script>
import toast from "@/mixin/toast";
import Layout from "../components/common/Layout.vue";
import Icon from "../components/icon/Icon.vue";
import VueSlickCarousel from "vue-slick-carousel";
// import animateMix from "@/mixin/animateMix.js";
export default {
  mixins: [toast],
  components: {
    Layout,
    Icon,
    VueSlickCarousel,
  },
  data() {
    return {
      wpli: 0,
      imglist: [
        "https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E6%95%B0%E5%AD%97%E5%AD%AA%E7%94%9F/%E6%95%B0%E5%AD%97%E5%AD%AA%E7%94%9F%20%284%29.jpg",
        "https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E6%95%B0%E5%AD%97%E5%AD%AA%E7%94%9F/%E6%95%B0%E5%AD%97%E5%AD%AA%E7%94%9F%20%285%29.jpg",
        "https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E6%95%B0%E5%AD%97%E5%AD%AA%E7%94%9F/%E6%95%B0%E5%AD%97%E5%AD%AA%E7%94%9F%20%286%29.jpg",
        // require("@/assets/images/main/zhny.jpg"),
      ],
      sliderSetting: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      sliderData: [
        {
          image: "banner-2",
          title: `数字孪生`,
          //   description: `使用工作台高效处理模型，尽情展现.`,
        },
        {
          image: "banner-3",
          title: `急速变化 <br/> 高品质效果.`,
          description: `模型处理？特效差？编辑器高效解决.`,
        },
      ],
      titlelist: ["智慧工厂", "数字园区", "智慧平台"],
      swiperOption: {
        autoplay: true,
        effect: "fade",
        pagination: {
          el: ".swiper-pagination.swiper-pagination-wrapper",
          clickable: true,
          bulletClass: "my-bullet",
          bulletActiveClass: "my-bullet-active",
        },
      },
      swiperOption2: {
        direction: "vertical",
        mousewheel: true,
      },
      controlledSwiper: null,
    };
  },
  methods: {
    getCss(el, css) {
      return el && window.getComputedStyle(el)[css];
    },
    onSwiperSlideChangeTransitionStart() {
      this.wpli = this.$refs.swiper1.$swiper.realIndex;
      this.$refs.swiper2.$swiper.slideTo(
        this.$refs.swiper1.$swiper.activeIndex
      );
    },
    onSwiperSlideChangeTransitionStart2() {
      this.wpli = this.$refs.swiper2.$swiper.realIndex;
      this.$refs.swiper1.$swiper.slideTo(
        this.$refs.swiper2.$swiper.activeIndex
      );
    },
  },
};
</script>

<style lang="less" scoped>
// .swiper {
//   width: 100%;
//   height: 37.75rem;
//   background-color: tomato;
//   border-radius: 1.25rem;
//   display: none;
// }
.main {
  // margin-top: 6.75rem;
  flex: 1;
}

.el-radio {
  color: rgb(101, 101, 104);
}

.height-950 {
  height: 650px;
  display: flex;
  align-items: center;
}

.mian {
  margin-top: 3.625rem;
}

.wrapper {
  position: relative;
  width: 900px;
  height: 500px;
  border-radius: 1.25rem;
  padding-bottom: 3rem;
  margin-left: 50%;
}

.slider {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #4d4d4d;
  transition: all 1s ease;
  border-radius: 1.25rem;
  line-height: 39.75rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.swiper-pagination {
  height: 30px;
}

/deep/ .my-bullet {
  width: 13px;
  height: 13px;
  background: #000000;
  box-sizing: border-box;
  border-radius: 50%;
  display: inline-block;
  margin: 20px 10px 0px;
}

/deep/ .my-bullet-active {
  background-color: #ffffff;
  border: 0.125rem solid #000000;
}

.swiper-title {
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #808080;
  padding-bottom: 0.625rem;
  // text-align: center;
  // float: left;

  transition: all 0.01s ease-in;
  // border-bottom: .125rem solid #808080;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 1.125rem;
  opacity: 0.75;
  line-height: 18.75rem;
  margin: 0;
}

// .el-carousel__item:nth-child(2n) {
//   background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n + 1) {
//   background-color: #d3dce6;
// }
// .flex {
// align-items: flex-start;
// }

.swiper-box {
  // background: #4d4d4d;
  // border-radius: 1.25rem;
  // overflow: auto;
}

.swiper-main {
  width: 35.875rem;
  height: 39.75rem;
  // background-color: brown;
  transition: all 0.25s ease-in;
}

.main-data {
  width: 100%;
  height: 100%;
  text-align: left;
  transition: all 0.25s ease-in;

  .data-title {
    margin-top: -50%;
    margin-left: 90%;
    font-size: 1.875rem;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #000000;

    span {
      color: #000000;
      font-size: 1.125rem;
    }
  }

  .data-text {
    width: 35.875rem;
    font-size: 0.875rem;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #000000;
    margin-top: 3.625rem;
  }

  .fc {
    width: 19rem;
    height: 6.75rem;
    margin-top: 9.375rem;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    // margin-left: 16.625rem;;

    .div1,
    .div2 {
      // width: 9.375rem;
      height: 2.75rem;
      background: #4d4d4d;
      border-radius: 1.375rem;
      text-align: center;
      line-height: 2.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      padding: 0 0.9375rem;
      font-size: 0.875rem;
      cursor: pointer;

      img {
        width: 1.1875rem;
        height: 1.4375rem;
        margin-right: 0.9375rem;
      }
    }

    .div3 {
      // width: 19.25rem;
      height: 2.75rem;
      background: #4d4d4d;
      border-radius: 1.375rem;
      font-size: 0.875rem;
      font-family: Source Han Sans SC;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.9375rem;

      img {
        width: 1.1875rem;
        height: 1.4375rem;
        margin-right: 0.9375rem;
      }
    }
  }

  .button {
    width: 11.875rem;
    height: 3.125rem;
    background: #1a1a1a;
    color: #fff;
    text-align: center;
    line-height: 3.125rem;
    box-shadow: 0rem 0rem 1.1875rem 0.0625rem rgba(255, 255, 255, 0.38);
    border-radius: 0.625rem;
    font-size: 0.875rem;
    font-family: Source Han Sans SC;
    font-weight: bold;
    transition: all 0.36s;
    margin-top: 5.5rem;
    margin-left: 1.125rem;
  }

  .button:hover {
    background-color: #fff;
    color: #1a1a1a;
    /* box-shadow:none; */
    transform: translateY(-0.3125rem);
  }
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
}
</style>