<template>
    <!-- //business-consulting 额外拓展 -->
    <Layout>
        <div class="rn-blog-details-area">
            <div class="post-page-banner rn-section-gapTop">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="content text-center">
                                <div class="page-title">
                                    <h1 class="theme-gradient" v-html="post.title" />
                                </div>
                                <ul class="rn-meta-list">
                                    <li>
                                        <Icon name="user" class="size-20" />
                                        <router-link to="#">BIMWORK</router-link>
                                    </li>
                                    <li>
                                        <Icon name="calendar" class="size-20" /> {{ post.publishedAt }}
                                    </li>
                                </ul>
                                <div class="thumbnail alignwide mt--60">
                                    <img class="w-100 radius" :src="post.img" :alt="post.title">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog-details-content pt--60 rn-section-gapBottom">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="content">
                                <p v-html="post.body" />

                                <div class="category-meta">
                                    <span class="text">标签:</span>
                                    <div class="tagcloud">
                                        <a href="#">高效</a>
                                        <a href="#">快捷</a>
                                        <a href="#">创作型</a>
                                        <a href="#">设计</a>
                                        <a href="#">简单</a>
                                        <a href="#">公司</a>
                                        <a href="#">发展</a>
                                        <a href="#">不错</a>
                                        <a href="#">在线</a>
                                        <a href="#">WEB</a>
                                        <a href="#">效率工具</a>
                                        <a href="#">分享工具</a>
                                        <a href="#">好用的</a>
                                        <a href="#">艺术</a>
                                    </div>
                                </div>

                                <!-- Start Contact Form Area  -->
                                <div class="rn-comment-form pt--60">
                                    <div class="inner">
                                        <div class="section-title">
                                            <span class="subtitle">更多意见？</span>
                                            <h2 class="title">写个回复</h2>
                                        </div>
                                        <form class="mt--40" action="#">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12 col-12">
                                                    <div class="rnform-group">
                                                        <input type="text" placeholder="名字">
                                                    </div>
                                                    <div class="rnform-group">
                                                        <input type="email" placeholder="邮箱">
                                                    </div>
                                                    <div class="rnform-group">
                                                        <input type="text" placeholder="微信或电话">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12 col-12">
                                                    <div class="rnform-group">
                                                        <textarea placeholder="说明"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="blog-btn">
                                                        <a class="btn-default" href="#">
                                                            <span>发送 信息</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <!-- End Contact Form Area  -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import Icon from '../components/icon/Icon'
import BlogPostMixin from '../mixins/BlogPostMixin'

export default {
    name: 'BlogDetailsPage',
    components: { Icon, Layout, Breadcrumb },
    mixins: [BlogPostMixin],
    data() {
        return {
            id: this.$route.params.id,
            post: {}
        }
    },
    methods: {
        getPost(postId) {
            this.post = this.posts.find(item => item.id == postId);
        }
    },
    mounted() {
        this.getPost(this.id);
    }
}
</script>