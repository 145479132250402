<template>
  <!-- 多元案例 -->
  <Layout :footer-style="3" :show-top-header="false">
    <!-- 1- 海报页 -->
    <div
      class="
        slider-area slider-style-4
        variation-2
        slider-dot
        rn-slick-dot rn-slick-arrow
      "
    >
      <VueSlickCarousel v-bind="sliderSetting">
        <div
          v-for="(slider, index) in sliderData"
          :key="index"
          class="single-slide"
        >
          <div
            class="height-950 bg-overlay bg_image"
            :style="{
              'background-image': `url(${require(`@/assets/images/bg/${slider.image}.jpg`)})`,
            }"
          >
            <div class="container">
              <div class="row row--30 align-items-center">
                <div class="col-lg-12">
                  <div class="inner text-center">
                    <h1 class="title" v-html="slider.title" />
                    <p class="description" v-html="slider.description" />
                    <div class="button-group mt--30">
                      <a
                        class="btn-default btn-large round"
                        href="http://www.bimhuicloud.com"
                      >
                        开始 创作！
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <div>
      <el-tabs
        v-model="activeName"
        :stretch="true"
        @tab-click="handleClick"
        style="margin-top: 5%"
      >
        <el-tab-pane
          label="厂区数字孪生案例"
          name="first"
          style="text-align: left"
        >
          <div class="xiezuo">
            <el-row :gutter="20" style="margin-top: 5%">
              <el-col :span="12">
                <div class="grid-content bg-purple" style="margin-top: 6%">
                  <div>
                    <span
                      class="theme-gradient"
                      style="
                        font-size: 34px;
                        font-weight: 700;
                        margin-left: 20%;
                      "
                      >数字园区</span
                    >
                  </div>
                  <div class="content">
                    <p>
                      数字园区是运用数字化技术实现智能化管理、分析、决策的数字化园区平台，那么我们应该如何建设数字化智慧园区呢？数字化智慧园区建设分为四个方面：一、园区平台设计理念。二、园区智能化建设实施。三、园区智能化运维。四、园区项目价值。
                      　
                    </p>
                  </div>
                  <div class="al">
                    <a
                      class="btn-default round btn-icon"
                      href="#Home"
                      style="font-size: 14px"
                      >了解详情
                    </a>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <img
                    src="https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B%20%2811%29.jpg"
                    alt
                    style="height: 450px; margin-left: 15%; width: 700px"
                  />
                </div>
              </el-col>
            </el-row>
          </div>
          <el-row :gutter="20" style="margin-top: 5%">
            <el-col :span="12">
              <div class="grid-content bg-purple" style="margin-top: 6%">
                <span
                  class="theme-gradient"
                  style="font-size: 34px; font-weight: 700; margin-left: 20%"
                  >智慧平台</span
                >
                <div class="content">
                  <p>
                    建立统一的应急管理与日常管理、对内与对外服务的管理体系，满足“安全、高效、绿色”的现代产业园区建设规划要求
                    安全监管统一规范，生产数据体系化分析，实现环保平台、智慧化工综合管理平台等数据的接入，实现园区产业
                    数据互联管理数字化
                  </p>
                </div>
                <div class="al">
                  <a
                    class="btn-default round btn-icon"
                    href="#Home"
                    style="font-size: 14px"
                    >了解详情
                  </a>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <img
                  src="https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B%20%2810%29.jpg"
                  alt
                  style="height: 450px; margin-left: 15%; width: 700px"
                />
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="个性化案例" name="second">
          <div class="xiezuo">
            <el-row :gutter="20" style="margin-top: 5%">
              <el-col :span="12">
                <div class="grid-content bg-purple" style="margin-top: 6%">
                  <div>
                    <span
                      class="theme-gradient"
                      style="
                        font-size: 34px;
                        font-weight: 700;
                        margin-left: 20%;
                      "
                      >模型-立交</span
                    >
                  </div>
                  <div class="content">
                    <p>
                      交通立交桥模型，全称"立体交叉桥"，是在城市重要交通交汇点建立的上下分层、多方向行驶、互不相扰的现代化陆地桥。主要是设置在城内交通要道上。主要形式有十字型立体交叉、Y型立体交叉和T型立体交叉。其通行方法为：干线上的主交通流走上跨道或下穿道，左右转弯的车辆仍在平面交叉改变运动方向。
                    </p>
                  </div>
                  <div class="al">
                    <a
                      class="btn-default round btn-icon"
                      href="#Home"
                      style="font-size: 14px"
                      >了解详情
                    </a>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <img
                    src="https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B%20%283%29.jpg"
                    alt
                    style="height: 450px; margin-left: 15%; width: 700px"
                  />
                </div>
              </el-col>
            </el-row>
          </div>
          <el-row :gutter="20" style="margin-top: 5%">
            <el-col :span="12">
              <div class="grid-content bg-purple" style="margin-top: 6%">
                <span
                  class="theme-gradient"
                  style="font-size: 34px; font-weight: 700; margin-left: 20%"
                  >模型-房屋</span
                >
                <div class="content">
                  <p>
                    房屋建筑模型，上有屋顶，周围有墙，能防风避雨，御寒保温，供人们在其中工作、生活、学习、娱乐和储藏物资。房屋是人类建造物，具有自然功能和社会功能。自然功能指对人类个体或群体生命、身体提供保护和停留的功能，社会功能指房屋是人类个体或群体的权利标志和所受管辖的归属标志。
                  </p>
                </div>
                <div class="al">
                  <a
                    class="btn-default round btn-icon"
                    href="#Home"
                    style="font-size: 14px"
                    >了解详情
                  </a>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content bg-purple">
                <img
                  src="https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B/%E5%A4%9A%E5%85%83%E6%A1%88%E4%BE%8B%20%2815%29.jpg"
                  alt
                  style="height: 450px; margin-left: 15%; width: 700px"
                />
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout.vue";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  components: {
    Layout,
    VueSlickCarousel,
  },
  data() {
    return {
      activeName: "first",
      sliderSetting: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      sliderData: [
        {
          image: "案例-1",
          title: `模型积分兑换 <br/> 高效便捷.`,
          description: `使用工作台高效处理模型，尽情展现.`,
        },
        {
          image: "案例-2",
          title: `急速变化 <br/> 高品质效果.`,
          description: `模型处理？特效差？编辑器高效解决.`,
        },
      ],
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="scss" scoped>
.height-950 {
  height: 650px;
  display: flex;
  align-items: center;
}

::v-deep .el-tabs__item {
  font-size: 21px;
  margin-bottom: 10px;
  font-weight: 600;
}

.title {
  font-size: 29px;
  margin-left: 20%;
  // position: relative;
}

.titleanimation :hover {
  transition: all 2s;
  transform: translate(0, 15px);
}

.content {
  width: 70%;
  margin-left: 20%;
  margin-top: 2%;
}

.al {
  width: 19rem;
  height: 6.75rem;
  margin-top: 5%;
  margin-left: 20%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;

  // margin-left: 16.625rem;;
  .div1 {
    // width: 9.375rem;
    height: 2.75rem;
    background: #4d4d4d;
    border-radius: 1.375rem;
    text-align: center;
    line-height: 2.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    padding: 0 0.9375rem;
    font-size: 0.875rem;
    cursor: pointer;

    img {
      width: 1.1875rem;
      height: 1.4375rem;
      margin-right: 0.9375rem;
    }
  }
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background-color: transparent;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;

  img {
    height: 400px;
  }
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>