<template>
  <!-- Start Copy Right Area  -->
  <div class="copyright-area copyright-style-one" style="padding: 0">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-8 col-sm-12 col-12">
          <div class="copyright-left">
            <ul class="ft-menu link-hover">
              <li>
                <!-- <router-link to="#">首页</router-link> -->
                <img src="../../../assets/images/brand/brand-1.png" style="width: 50%; margin-left: 60%" />
              </li>
              <li>
                <span style="font-size: 12px; margin: 0">本网站由阿里云提供云计算及安全服务</span>
                <!-- <router-link to="/business-consulting">产品</router-link> -->
              </li>
              <!-- <li>
                <router-link to="/web-agency">解决方案</router-link>
              </li> -->
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-4 col-sm-12 col-12">
          <ul class="ft-menu link-hover">
            <li>
              <span style="font-size: 12px">本网站支持
                <span style="
                        background-color: transparent;
                        border-color: DarkGray;
                        border-radius: 10px;
                        border-style: solid;
                        border-width: 1px;
                        padding: 5px;
                        font-size: 12px;
                      ">
                  IPV6
                </span></span>
              <!-- <router-link to="/business-consulting">产品</router-link> -->
            </li>
            <li>
              <span style="font-size: 12px"> © 图模坊 2022
                <a style="text-decoration: none" href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2022042492号-4</a></span>
              <!-- <router-link to="/business-consulting">产品</router-link> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- End Copy Right Area  -->
</template>

<script>
export default {
  name: "Copyright",
};
</script>