<template>
  <ul
    :class="[
      `brand-list`,
      { 'brand-style-1': brandStyle === 1 },
      { 'brand-style-2': brandStyle === 2 },
      { 'brand-style-2': brandStyle === 3 },
      { 'brand-style-2 variation-2': brandStyle === 4 },
    ]"
  >
    <li v-for="(brand, index) in brandList" :key="index">
      <a href="#"><img :src="brand.image" alt="Brand Image" /></a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Brand",
  props: {
    brandList: {},
    brandStyle: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style scoped>
.brand-style-2 li a img {
  filter: grayscale(100%);
  transition: 0.3s;
}
.brand-style-2 li a img:hover {
  filter: grayscale(0%);
  z-index: 1;
}
</style>