<template>
    <Layout :header-transparency="true" :footer-style="3" buy-button-class="btn-default btn-small">
        <!-- Start Slider area  -->
        <div class="slider-area slider-style-2 height-950 bg_image" data-black-overlay="2"
            :style="{ 'background-image': `url(${require(`@/assets/images/bg/banner-1.jpg`)})` }">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-left">
                            <h4 class="subtitle" data-aos="fade-up" data-aos-delay="100">
                                <span class="theme-gradient">SASS 高效的 解决行业痛点的</span>
                            </h4>
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="130">BIMWORK 项目协同平台</h1>
                            <ul class="list-icon" data-aos="fade-up" data-aos-delay="160">
                                <li>
                                    <Icon name="check" size="20" icon-class="icon" />
                                    全项目生命周期管理.支撑项目全生命周期管理和完整的业务流程。
                                </li>
                                <li>
                                    <Icon name="check" size="20" icon-class="icon" />
                                    项目工程协作管理,融合BIM可视化协同
                                </li>
                                <li>
                                    <Icon name="check" size="20" icon-class="icon" />
                                    统一式文档管理，极大的拓展了项目协作的便利性。
                                </li>
                            </ul>
                            <div class="button-group mt--40 mt_sm--20" data-aos="fade-up" data-aos-delay="190">
                                <a class="btn-default btn-icon" href="http://139.224.14.100:9595/">
                                    立刻 体验！
                                    <Icon name="arrow-right" size="20" icon-class="icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider area  -->

        <!-- Start Service Area  -->
        <div class="rn-service-area rn-section-gap" id="miao6">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="协同平台 能为你做什么？" title="协同平台 功能介绍"
                            description="BimWork项目协同平台——项目全生命周期管理，助力工程数字化转型" data-aos="fade-up" />
                    </div>
                </div>
                <ServiceTwo card-style="card-style-1" text-align="left" />
            </div>
        </div>
        <!-- End Service Area  -->

        <Separator />

        <!-- Start About Area  -->
        <about-two />
        <!-- End About Area  -->

        <Separator />

        <!-- Start Elements Area  -->
        <div class="rwt-counterup-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="平台数据说明" title="二三维协同平台、助力企业创新升级。"
                            data-aos="fade-up" />
                    </div>
                </div>
                <Counter :counter-style="2" text-align="center" :counter-data="counterData"
                    column="col-lg-4 col-md-6 col-sm-6 col-12" />
            </div>
        </div>
        <!-- End Elements Area  -->

        <Separator />

        <!-- Start Elements Area  -->
        <div class="rwt-team-area rn-section-gap">
            <div class="wrapper plr--65 plr_md--15 plr_sm--15">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="面向 人群" title="我们的客户" data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" v-for="(teamMember, index) in teamData"
                        :key="index">
                        <Team :team-member="teamMember" :team-style="4" />
                    </div>
                </div>
            </div>
        </div>
        <!-- End Elements Area  -->

        <Separator />

        <!-- Start Elements Area  -->
        <div class="rwt-pricing-area rn-section-gap" id="miao7">
            <div class="container">
                <div class="row mb--35">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="免费/专业升级" title="我们的 价格计划" data-aos="fade-up" />
                    </div>
                </div>
                <Pricing :pricing-data="pricingData" />
            </div>
        </div>
        <!-- End Elements Area  -->

        <!-- Start Call To Action Area  -->
        <div class="rwt-callto-action-area rn-section-gapBottom">
            <div class="wrapper">
                <CallToAction :style-type="5" title="准备好开始免费体验了吗?" subtitle="只需手机号，就能免费创建企业版SASS协同平台应用"
                    btn-text="立刻 体验" />
            </div>
        </div>
        <!-- End Call To Action Area  -->
    </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Icon from '../../components/icon/Icon'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import ServiceThree from '../../components/elements/service/ServiceThree'
import Separator from '../../components/elements/separator/Separator'
import Counter from '../../components/elements/counterUp/Counter'
import Team from '../../components/elements/team/Team'
import Pricing from '../../components/elements/pricing/Pricing'
import CallToAction from '../../components/elements/callToAction/CallToAction'
import AboutTwo from "../../components/elements/about/AboutTwo";
import ServiceTwo from "../../components/elements/service/ServiceTwo";

export default {
    name: 'Corporate',
    components: {
        ServiceTwo,
        AboutTwo, CallToAction, Pricing, Team, Counter, Separator, ServiceThree, SectionTitle, Icon, Layout
    },
    data() {
        return {
            counterData: [
                {
                    number: 121,
                    title: '定制模板',
                    description: '项目表单模板。。。'
                },
                {
                    number: 3,
                    title: '版本',
                    description: '免费版/专业版/BIM版'
                },
                {
                    number: 12,
                    title: '经典功能',
                    description: '功能增加。。。'
                }
            ],
            teamData: [
                {
                    image: '配图-13',
                    name: '王先生',
                    designation: '设计师',
                    location: 'CO Miego, AD, USA',
                    description: 'Yes, I am a product designer. I have a passion for product design.'
                },
                {
                    image: '配图-14',
                    name: '米先生',
                    designation: '设计师',
                    location: 'Bangladesh',
                    description: 'Yes, I am a product designer. I have a passion for product design.'
                },
                {
                    image: '配图-15',
                    name: '胡先生',
                    designation: '管理者',
                    location: 'Poland',
                    description: 'Yes, I am a product designer. I have a passion for product design.'
                },
                {
                    image: '配图-16',
                    name: '林先生',
                    designation: '企业人事',
                    location: 'Poland',
                    description: 'Yes, I am a product designer. I have a passion for product design.'
                },
            ],
            pricingData: [
                {
                    title: '免费版',
                    currency: '¥',
                    price: '0',
                    subtitle: '终身免费',
                    features: [
                        { title: '10G空间' },
                        { title: '数据总量30万条' },
                        { title: '二维项目数100张' },
                        { title: '三维项目数1个' },
                        { title: '其他额外功能免费' },
                    ],
                    btn: {
                        text: '获取详细清单',
                        link: 'http://account.bimhui.com:8181/#/About'
                    }
                },
                {
                    title: 'Business',
                    currency: '¥',
                    price: '399',
                    subtitle: '1人/1年',
                    features: [
                        { title: '500G空间' },
                        { title: '数据总量50万条' },
                        { title: '二维项目数不限' },
                        { title: '三维项目数20个' },
                        { title: '其他功能免费' },
                    ],
                    btn: {
                        text: '获取详细清单',
                        link: 'http://account.bimhui.com:8181/#/About'
                    },
                    isActive: true
                },
                {
                    title: 'Advanced',
                    currency: '¥',
                    price: '699',
                    subtitle: '1人/1年',
                    features: [
                        { title: '1T空间' },
                        { title: '数据总量100万条' },
                        { title: '二维项目数不限' },
                        { title: '三维项目数不限' },
                        { title: '其他功能免费' },
                    ],
                    btn: {
                        text: '获取详细清单',
                        link: 'http://account.bimhui.com:8181/#/About'
                    }
                }
            ],
        }
    }
}
</script>