<template>
  <div class="row row--15 service-wrapper">
    <div
      class="col-lg-3 col-md-6 col-sm-6 col-12"
      v-for="(service, index) in serviceList"
      :key="index"
    >
      <div
        :class="`service ${serviceStyle} ${checkTextAlign}`"
        data-aos="fade-up"
        data-aos-delay="70"
      >
        <div class="icon">
          <div class="line" />
          {{ service.icon }}
        </div>
        <div class="content">
          <h4 class="title">
            <router-link to="/service-details" v-html="service.title" />
          </h4>
          <p
            class="description b1 color-gray mb--0"
            v-html="service.description"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceFour",
  props: {
    serviceStyle: {
      type: String,
    },
    textAlign: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      serviceList: [
        {
          icon: "1",
          title: "需求咨询",
          description: "填写专业定制需求问卷精准匹配设计服务内容",
        },
        {
          icon: "2",
          title: "设计投入",
          description: "专属设计师定制设计方案专业把控设计实施进展",
        },
        {
          icon: "3",
          title: "还原搭建",
          description: "进行设计还原搭建实施完成应用场景交互配置",
        },
        {
          icon: "4",
          title: "交付确认",
          description: "确认应用搭建实际效果输出工程文件及设计文件",
        },
      ],
    };
  },
  computed: {
    checkTextAlign() {
      if (this.textAlign === "left") return "text-start";
      else if (this.textAlign === "center") return "text-center";
      else if (this.textAlign === "end") return "text-end";
      else return "";
    },
  },
};
</script>