<template>
  <div :class="`rn-accordion-style ${accordionStyleClass} accordion`">
    <div class="accordion" :id="id">
      <div
        class="accordion-item card"
        v-for="(content, index) in accordionContent"
      >
        <h2 class="accordion-header card-header" :id="`heading${content.id}`">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#collapse${content.id}`"
            :aria-expanded="index === activeCollapse ? 'true' : 'false'"
            :aria-controls="`collapse${content.id}`"
            @click="activeCollapse = index"
          >
            {{ content.title }}
            <i class="collapse-icon" />
          </button>
        </h2>
        <div
          :id="`collapse${content.id}`"
          class="accordion-collapse collapse"
          :class="{ show: index === 0 }"
          :aria-labelledby="`heading${content.id}`"
          :data-bs-parent="`#${id}`"
        >
          <div class="accordion-body card-body">
            {{ content.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: {
    id: {
      type: String,
      default: "accordionExample",
    },
    accordionStyleClass: {
      type: String,
    },
    accordionContent: {
      type: Array,
      default: function () {
        return [
          {
            id: "One",
            title: "支持全版本协同预览",
            description:
              "全版本CAD无忧上传，后台自动转化完成后即可对图纸进行查看、分享、尺寸测量等。可自动生成嵌入式代码或者二维码，随时随地看图",
          },
          {
            id: "Two",
            title: "CAD快捷审批汇报",
            description:
              "Web端/客户端/手机端都能实时查看图纸，同一图纸保存多个历史版本，方便多方案比选。BIMWORK为您提供丰富的汇报模板",
          },
          {
            id: "Three",
            title: "CAD嵌入式应用",
            description:
              "您可将CAD图纸嵌入到自己的应用，或者书籍、明信片等，只需一行代码",
          },
          {
            id: "Four",
            title: "CAD共享交互应用",
            description:
              "人在旅途，着急看图！图纸在公司电脑怎么办？同事一键分享，一键修改，历史版本看图，对图，聊图……Bimwork，应有尽有",
          },
        ];
      },
    },
  },
  data() {
    return {
      activeCollapse: 0,
    };
  },
};
</script>