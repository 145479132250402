<template>
    <div class="row row--15 service-wrapper">
        <div class="col-lg-4 col-md-6 col-sm-6 col-12" v-for="(service, index) in serviceList" :key=index>
            <div :class="`service ${serviceStyle} ${checkTextAlign}`" data-aos="fade-up" data-aos-delay="70">
                <div class="icon">
                    <Icon :name="service.icon" :class="`size-${iconSize}`" />
                </div>
                <div class="content">
                    <h4 class="title w-600">
                        <router-link to="/service-details" v-html="service.title" />
                    </h4>
                    <p class="description b1 color-gray mb--0" v-html="service.description" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '../../icon/Icon'

export default {
    name: 'ServiceOne',
    components: { Icon },
    props: {
        serviceStyle: {
            type: String
        },
        iconSize: {
            type: String | Number,
            default: 62
        },
        textAlign: {
            type: String,
            default: 'left'
        }
    },
    data() {
        return {
            serviceList: [
                {
                    icon: 'activity',
                    title: '全版本CAD转化Web',
                    description: '在线、便捷的使用方式，改变传统CAD只能在电脑端打开的方式'
                },
                {
                    icon: 'cast',
                    title: 'CAD 快捷汇报宝',
                    description: '传统PPT汇报信息分散，汇报宝集成大屏看板,全套图纸，一目了然'
                },
                {
                    icon: 'map',
                    title: '嵌入式 + 共享应用',
                    description: '嵌入式体验，二维码/条形码/PPT链接/海报 融合 CAD图纸评论，大神评图，专家改图'
                },

            ]
        }
    },
    computed: {
        checkTextAlign() {
            if (this.textAlign === 'left') return 'text-start';
            else if (this.textAlign === 'center') return 'text-center';
            else if (this.textAlign === 'end') return 'text-end';
            else return '';
        }
    }
}
</script>