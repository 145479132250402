<template>
  <div v-if="splitStyle === 1" class="rn-splite-style">
    <div class="split-wrapper">
      <div class="row no-gutters radius-10 align-items-center">
        <div class="col-lg-12 col-xl-6 col-12">
          <div class="thumbnail image-left-content">
            <img
              src="https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87/%E9%A6%96%E9%A1%B5%20%285%29.jpg"
              alt="Split Image"
              style="margin-left: 12%; width: 80%; height: 430px"
            />
          </div>
        </div>
        <div class="col-lg-12 col-xl-6 col-12">
          <div class="split-inner">
            <h4 class="title">{{ splitData.title }}</h4>
            <p class="description">{{ splitData.description }}</p>
            <ul class="split-list">
              <li v-for="listItem in splitData.list">{{ listItem }}</li>
            </ul>
            <div class="view-more-button mt--35">
              <Button :title="splitData.btn.text" :url="splitData.btn.link" />
              <Button
                :title="splitData.btn2.text"
                :url="splitData.btn2.link"
                style="margin-left: 5%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-else-if="splitStyle === 2 || splitStyle === 3 || splitStyle === 4"
    :class="[
      `rn-splite-style`,
      { 'bg-color-blackest': splitStyle === 3 || splitStyle === 4 },
    ]"
  >
    <div class="split-wrapper">
      <div class="row no-gutters radius-10 align-items-center">
        <div
          :class="[
            `col-lg-12 col-xl-6 col-12`,
            { 'order-2': splitStyle === 4 },
          ]"
        >
          <div class="thumbnail">
            <img
              src="https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87/%E9%A6%96%E9%A1%B5%20%286%29.jpg"
              alt="Split Image"
              style="
                margin-left: 12%;
                margin-right: 20%;
                width: 80%;
                height: 470px;
              "
            />
          </div>
        </div>
        <div
          :class="[
            `col-lg-12 col-xl-6 col-12`,
            { 'order-1': splitStyle === 4 },
          ]"
        >
          <div class="split-inner">
            <h4 class="title">{{ splitData.title }}</h4>
            <p class="description">{{ splitData.description }}</p>
            <div class="view-more-button mt--35">
              <Button :title="splitData.btn.text" :url="splitData.btn.link" />
            </div>
            <Counter
              :counter-style="5"
              text-align="left"
              :counter-data="splitData.counterData"
              column="col-lg-6 col-md-6 col-sm-6 col-12"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../button/Button";
import Counter from "../counterUp/Counter";

export default {
  name: "Split",
  components: { Counter, Button },
  props: {
    splitStyle: {
      type: Number,
      default: 1,
    },
    splitData: {},
  },
};
</script>