<template>
  <div v-if="splitStyle === 1" class="rn-splite-style">
    <div class="split-wrapper">
      <div class="row no-gutters radius-10 align-items-center">
        <div class="col-lg-12 col-xl-6 col-12">
          <div class="thumbnail image-left-content">
            <!-- iPhone组件写法 -->
            <Iphone />
            <!-- iPhone内嵌式写法 -->
            <!-- <div class="hero__model">
              <iframe
                id="landing-model"
                class="hero__iframe"
                width="500px"
                height="600px"
                src="http://120.79.35.135:8001/#/Iphone"
                allowfullscreen
                mozallowfullscreen="true"
                frameborder="0"
                style="text-align: left"
              ></iframe>
            </div> -->
          </div>
        </div>
        <div class="col-lg-12 col-xl-6 col-12">
          <div class="split-inner">
            <h4 class="title">{{ splitData.title }}</h4>
            <p class="description">{{ splitData.description }}</p>
            <ul class="split-list">
              <li v-for="listItem in splitData.list">{{ listItem }}</li>
            </ul>
            <div class="view-more-button mt--35">
              <Button :title="splitData.btn.text" :url="splitData.btn.link" />
              <!-- <div @click="test()"> -->
              <a
                class="btn-default"
                style="margin-left: 5%"
                href="https://www.tomore.fun/"
                >进入工作台 →
              </a>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../button/Button";
import Counter from "../counterUp/Counter";
import Iphone from "./Iphone.vue";
export default {
  name: "Split",
  components: { Counter, Button, Iphone },
  props: {
    splitStyle: {
      type: Number,
      default: 1,
    },
    splitData: {},
  },
  methods: {
    test() {
      window.open("http://www.bim.work/", "_blank");
    },
  },
};
</script>
