var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.splitStyle === 1)?_c('div',{staticClass:"rn-splite-style"},[_c('div',{staticClass:"split-wrapper"},[_c('div',{staticClass:"row no-gutters radius-10 align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-lg-12 col-xl-6 col-12"},[_c('div',{staticClass:"split-inner"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.splitData.title))]),_c('p',{staticClass:"description"},[_vm._v(_vm._s(_vm.splitData.description))]),_c('ul',{staticClass:"split-list"},_vm._l((_vm.splitData.list),function(listItem){return _c('li',[_vm._v(_vm._s(listItem))])}),0),_c('div',{staticClass:"view-more-button mt--35"},[_c('Button',{attrs:{"title":_vm.splitData.btn.text,"url":_vm.splitData.btn.link}}),_c('Button',{staticStyle:{"margin-left":"5%"},attrs:{"title":_vm.splitData.btn2.text,"url":_vm.splitData.btn2.link}})],1)])])])])]):(_vm.splitStyle === 2 || _vm.splitStyle === 3 || _vm.splitStyle === 4)?_c('div',{class:[
    `rn-splite-style`,
    { 'bg-color-blackest': _vm.splitStyle === 3 || _vm.splitStyle === 4 },
  ]},[_c('div',{staticClass:"split-wrapper"},[_c('div',{staticClass:"row no-gutters radius-10 align-items-center"},[_c('div',{class:[
          `col-lg-12 col-xl-6 col-12`,
          { 'order-2': _vm.splitStyle === 4 },
        ]},[_vm._m(1)]),_c('div',{class:[
          `col-lg-12 col-xl-6 col-12`,
          { 'order-1': _vm.splitStyle === 4 },
        ]},[_c('div',{staticClass:"split-inner"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.splitData.title))]),_c('p',{staticClass:"description"},[_vm._v(_vm._s(_vm.splitData.description))]),_c('div',{staticClass:"view-more-button mt--35"},[_c('Button',{attrs:{"title":_vm.splitData.btn.text,"url":_vm.splitData.btn.link}})],1),_c('Counter',{attrs:{"counter-style":5,"text-align":"left","counter-data":_vm.splitData.counterData,"column":"col-lg-6 col-md-6 col-sm-6 col-12"}})],1)])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12 col-xl-6 col-12"},[_c('div',{staticClass:"thumbnail image-left-content"},[_c('img',{staticStyle:{"margin-left":"12%","width":"80%","height":"430px"},attrs:{"src":"https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87/%E9%A6%96%E9%A1%B5%20%285%29.jpg","alt":"Split Image"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thumbnail"},[_c('img',{staticStyle:{"margin-left":"12%","margin-right":"20%","width":"80%","height":"470px"},attrs:{"src":"https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E9%A6%96%E9%A1%B5%E5%9B%BE%E7%89%87/%E9%A6%96%E9%A1%B5%20%286%29.jpg","alt":"Split Image"}})])
}]

export { render, staticRenderFns }