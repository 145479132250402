<template>
  <div class="row row--15 service-wrapper">
    <div
      class="col-lg-4 col-md-6 col-sm-12 col-12"
      v-for="(service, index) in serviceList"
      :key="index"
    >
      <div
        :class="`service ${serviceStyle} ${checkTextAlign}`"
        data-aos="fade-up"
        data-aos-delay="70"
      >
        <div class="inner">
          <div class="content">
            <h4 class="title">
              <router-link to="/service-details" v-html="service.title" />
            </h4>
            <p class="description" v-html="service.description" />
          </div>
          <div class="image">
            <img :src="service.image" alt="Service Images" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceFive",
  props: {
    serviceStyle: {
      type: String,
    },
    textAlign: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      serviceList: [
        {
          image: require("@/assets/images/service/配图-28.jpg"),
          title: "二维码分享",
          description: "在线生成二维码，随时随地分享模型作品，还可即时在线评论",
        },
        {
          image: require("@/assets/images/service/配图-29.jpg"),
          title: "3D特效展示",
          description:
            "利用模型编辑器预设的各类3D效果模板，轻松获取想要的模型效果，一键分享，炫酷展示",
        },
        {
          image: require("@/assets/images/service/配图-30.jpg"),
          title: "嵌入式应用",
          description:
            "可将三维作品嵌入网站、海报、刊物等各类应用场景，无限拓展应用边界",
        },
      ],
    };
  },
  computed: {
    checkTextAlign() {
      if (this.textAlign === "left") return "text-start";
      else if (this.textAlign === "center") return "text-center";
      else if (this.textAlign === "end") return "text-end";
      else return "";
    },
  },
};
</script>