<template>
  <div>
    <footer
      v-if="data.showFooter"
      class="rn-footer footer-style-default footer-style-1"
    >
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="rn-footer-widget">
                <Logo />
                <h3 class="text-big">领先的二三维可视化应用服务平台</h3>
              </div>
              <div class="friendlink">
                <p style="margin-top: 50%">
                  <a href="http://www.bimhui.com" target="_blank"
                    >友情链接：缤汇云</a
                  >
                </p>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-12">
              <div class="rn-footer-widget">
                <div class="widget-menu-top">
                  <h4 class="title">自主图模服务</h4>
                  <div class="inner">
                    <ul class="footer-link link-hover">
                      <li>
                        <router-link to="/ShowCAD">二维应用ShowCad</router-link>
                      </li>
                      <li>
                        <router-link to="/ShowModel"
                          >三维应用ShowModel</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/error?id=99"
                          >模型效果编辑器</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/error?id=98"
                          >无代码大屏编辑器</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <span class="widget-menu-bottom">
                  <router-link to="/Mall" class="title">图模市场</router-link>
                  <router-link
                    to="/Socio"
                    class="title"
                    style="float: right; margin-right: 25%"
                    >社区</router-link
                  >
                </span>
                <!-- <div class="widget-menu-bottom">
                  <h4 class="title">增值服务</h4>
                  <div class="inner">
                    <ul class="footer-link link-hover">
                      <li>
                        <router-link to="/error?id=97"
                          >3D建模/模型处理</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/error?id=96"
                          >3D网页/小程序开发</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/error?id=95"
                          >可视化设计服务
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-12">
              <div class="rn-footer-widget">
                <div class="widget-menu-bottom">
                  <h4 class="title">增值服务</h4>
                  <div class="inner">
                    <ul class="footer-link link-hover">
                      <li>
                        <router-link to="/error?id=97"
                          >3D建模/模型处理</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/error?id=96"
                          >3D网页/小程序开发</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/error?id=95"
                          >可视化设计服务
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- <div class="widget-menu-top">
                  <h4 class="title">数字孪生</h4>
                  <div class="inner">
                    <ul class="footer-link link-hover">
                      <li>
                        <router-link to="/Socio">智慧运维</router-link>
                      </li>
                      <li>
                        <router-link to="/Socio">智慧城市</router-link>
                      </li>
                      <li>
                        <router-link to="/Socio">智慧工地 </router-link>
                      </li>
                    </ul>
                  </div>
                </div> -->
                <!-- <h4 class="title">解决方案</h4> -->
                <!-- <div class="inner">
                    <ul class="footer-link link-hover">
                      <li>
                        <router-link to="/Mall">图模坊</router-link>
                      </li>
                      <li>
                        <router-link to="/Socio">社区 </router-link>
                      </li>
                      <li>
                        <router-link to="/contact">关于我们 </router-link>
                      </li>
                      <li>
                        <router-link to="/error?id=13"
                          >交通仿真模拟平台</router-link
                        >
                      </li>
                    </ul>
                  </div> -->
                <div class="widget-menu-bottom" style="margin-top: 63px">
                  <router-link to="/contact" class="title"
                    >关于我们</router-link
                  >
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 col-12">
              <div class="rn-footer-widget">
                <!-- <h4 class="title">服务用户次数</h4> -->
                <a href="https://www.tomore.fun/" target="_blank">
                  <button class="cssbuttons-io-button">
                    我的工作台
                    <div class="icon">
                      <svg height="24" width="24" viewBox="0 0 24 24">
                        <!-- <path d="M0 0h24v24H0z" fill="none"></path> -->
                        <path
                          d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </a>
                <div class="inner">
                  <!-- <h6 class="subtitle">2000+ 全世界使用人数</h6> -->
                  <h6 class="subtitle"></h6>
                  <form class="newsletter-form" action="#">
                    <div class="form-group">
                      <input type="email" placeholder="输入您的手机或者邮件" />
                    </div>
                    <div class="form-group">
                      <button class="btn-default" type="submit">
                        立刻体验
                      </button>
                    </div>
                    <p style="margin-top: 10%; margin-bottom: 2%">
                      联系电话号码
                    </p>
                    <span style="margin-top: -10%"
                      ><a href="tel:+444 555 666 777">+400 021 5123</a
                      ><span style="margin-left: 1%">转3</span></span
                    >
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <Copyright v-if="data.showCopyright" />
    <ScrollTop />
  </div>
</template>

<script>
import Copyright from "./Copyright";
import ScrollTop from "./ScrollTop";
import Logo from "../../elements/logo/Logo";

export default {
  name: "FooterThree",
  components: { Logo, ScrollTop, Copyright },
  props: {
    data: {
      default: null,
    },
  },
};
</script>
<style lang="scss" scoped>
.cssbuttons-io-button {
  background: #a370f0;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #714da6;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
}

.cssbuttons-io-button .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em #7b52b9;
  right: 0.3em;
  transition: all 0.3s;
}

.cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
}

.cssbuttons-io-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #7b52b9;
}

.cssbuttons-io-button:hover .icon svg {
  transform: translateX(0.1em);
}

.cssbuttons-io-button:active .icon {
  transform: scale(0.95);
}
</style>