<template>
    <div class="rwt-about-area rn-section-gap">
        <div class="container">
            <div class="row row--30 align-items-center">
                <div class="col-lg-5">
                    <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                        <img class="w-100" src="../../../assets/images/about/配图-10.jpg" alt="About Images">
                    </div>
                </div>
                <div class="col-lg-7 mt_md--40 mt_sm--40">
                    <div class="content">
                        <div class="section-title">
                            <h4 class="subtitle" data-aos="fade-up" data-aos-delay="280">
                                <span class="theme-gradient">协同平台 解决了哪些问题？</span>
                            </h4>
                            <h2 class="title mt--10" data-aos="fade-up" data-aos-delay="310">平台解决痛点.</h2>
                            <p data-aos="fade-up" data-aos-delay="310">
                                近年来，随着城市规模扩大，工程设计行业所包含的内容也越来越多样化。在不断发展的形势下，工程设计行业中传统的工作方式和技术手段越来越难以满足业务发展的需求。在项目的具体实施过程中，制定标准化的流程，有计划的控制项目进度非常重要。除此以外，协同设计是当下设计行业技术更新的一个重要方向，也是设计技术发展的必然趋势。基于标准化的工作流程，高质量落实协同设计，可以切实提高整体工作效率和整体设计质量。协同设计的发展和应用，可有效减少设计人员的重复工作量，实现效率最大化。
                            </p>
                            <ul class="list-icon" data-aos="fade-up" data-aos-delay="340">
                                <li>
                                    <Icon name="check" size="20"
                                        icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                    （1）工程设计行业产品形式个性化，缺乏标准化的全周期管理体系
                                </li>
                                <li>
                                    <Icon name="check" size="20"
                                        icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                    （2）工程设计阶段间断不连续，缺乏有效的质量把握和进度控制体系.
                                </li>
                                <li>
                                    <Icon name="check" size="20"
                                        icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                    （3）不同业务单元之间数据割裂，缺乏资源共享与跨部门协同协作.
                                </li>
                                <li>
                                    <Icon name="check" size="20"
                                        icon-class="icon d-inline-flex align-items-center justify-content-center" />
                                    （4）技术供应商产品不健全，缺乏综合性人才实施整合与成熟的BIM技术.
                                </li>
                            </ul>

                            <div class="read-more-btn mt--40" data-aos="fade-up" data-aos-delay="370">
                                <router-link class="btn-default btn-icon" to="/corporate">
                                    体验使用
                                    <Icon name="arrow-right" size="20" icon-class="icon" />
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '../../icon/Icon'

export default {
    name: 'AboutTwo',
    components: { Icon }
}
</script>