<template>
  <!-- 关于我们 -->
  <Layout :footer-style="3">
    <div
      class="
        slider-area slider-style-4
        variation-2
        slider-dot
        rn-slick-dot rn-slick-arrow
      "
    >
      <VueSlickCarousel v-bind="sliderSetting">
        <div
          v-for="(slider, index) in sliderData"
          :key="index"
          class="single-slide"
        >
          <div
            class="height-950 bg-overlay bg_image"
            :style="{
              'background-image': 'url(' + slider.image + ')',
            }"
          >
            <div class="container">
              <div class="row row--30 align-items-center">
                <div class="col-lg-12">
                  <div class="inner text-center">
                    <h1 class="title" v-html="slider.title" />
                    <p class="description2" v-html="slider.description" />
                    <div class="button-group mt--30">
                      <a
                        class="btn-default btn-large round"
                        href="https://www.tomore.fun/"
                      >
                        开始 创作！
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <!-- Start Service Area -->
    <div>
      <!-- <p>
        BIMWORK成立于2021年，是缤汇云®旗下二三维可视化与数字孪生应用服务平台。立足于前沿的互联网云原生和三维显示交互技术，BIMWORK自主研发了三维图形渲染引擎和轻量化系列工具，使模型资源可以自动输出至Web端、PC端和移动端，实现了多设备、全平台展示和交互的3D通用技术，为图纸和模型的可视化提供了丰富的展现形式和视觉效果，致力于为客户提供有价值的二三维模型和数字孪生技术服务。
        BIMWORK搭建了一个供用户上传、展示、分享的服务平台，用户可以自由上传自己的三维模型和图像作品，支持包括Revit、Solidworks、Rhino、3Dmax、NX、Inventor在内的多种3D文件格式。与一般的模型平台所不同的是，BIMWORK提供了所有模型的720度在线预览界面，所见即所得，并可让用户通过功能强大的个人后台进行管理。BIMWORK可让你的模型嵌入任何网页，可以一键转发分享，同时支持在产品网站、微信、H5、APP、出版物等方面的二维码应用，也支持为用户的业务场景深度定制开发。
        BIMWORK在3D模型展示与应用的基础上，不断深耕细分行业，建设数字增强世界。结合WebGL、3D游戏引擎、GIS、BIM、CIM等技术，为客户构建“数字孪生
        (Digital
        Twin)”。基于工程和运维一体化数据，围绕数字孪生技术、数字驾驶舱和行业应用，为工程建设和工业制造的生产管控、智慧城市的监控运维等应用领域，提供全要素智慧场景的可视化解决方案。
      </p> -->
    </div>
    <div class="service-area rn-section-gapTop">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="content" data-aos="fade-up">
              <h3 class="title">二三维可视化服务应用服务平台</h3>
            </div>
          </div>
          <div class="col-lg-6">
            <p class="mb--10" data-aos="fade-up">
              图模坊为图纸和模型提供了丰富的展现形式和视觉效果，致力于为客户提供有价值的可视化技术服务。针对设计师，我们提供二三维可视化编辑器；针对程序开发人员，我们提供3D模型定制服务。我们不仅可以节省工作制作周期，而且还支持跨平台一键分享，通过BIMWORK图模坊上传你的3D作品，可以使用模型编辑器进行材质、颜色、背景、标签、动画等一系列操作，然后直接将一键分享给你的同事和客户。此外，还可以发布在图模坊作品区进行售卖，成交即可获得佣金分成，所有模型在购买后还能获取嵌入代码，方便二次开发。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Service Area -->

    <!-- Start Brand Area -->
    <div class="rwt-brand-area pb--60 pt--30">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mt--10">
            <Brand :brand-list="brandList" :brand-style="3" />
          </div>
        </div>
      </div>
    </div>
    <!-- End Brand Area -->

    <!-- <Separator /> -->

    <!-- Start Service Area -->
    <!-- <div class="rn-service-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="我们可以提供什么服务"
              title="我们可以做什么."
              description="个性化定制开发，打通线上各个平台, <br /> 产品3d展示营销更真实的3d交互式场景体验."
              data-aos="fade-up"
            />
          </div>
        </div>
        <ServiceSix
          service-style="service__style--1 bg-color-blackest radius mt--25"
          icon-size="62"
          text-align="center"
        />
      </div>
    </div> -->

    <Separator />
    <!-- End Service Area -->
    <!-- Start Elements Area -->
    <!-- <div class="rwt-advance-tab-area rn-section-gap">
      <div class="container">
        <div class="row mb--40">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="拓展应用"
              title="我们可以提供的拓展性服务"
              description=""
              data-aos="fade-up"
            />
          </div>
        </div>
        <AdvancedTab :tab-data="tabData" />
      </div>
    </div>

    <Separator /> -->

    <!-- <AboutFour :image="require(`@/assets/images/about/contact-image.jpg`)"/> -->
    <!-- Start Video Area -->
    <!-- <div class="rwt-video-area rn-section-gapBottom">
      <div class="container">
        <Video
          :video-data="videoData"
          :video-style="2"
          column="col-lg-12"
          data-aos="fade-up"
        />
      </div>
    </div> -->
    <!-- End Video Area -->
    <!-- Start Elements Area -->

    <!-- End Elements Area -->

    <!-- <Separator /> -->

    <!-- Start Elements Area -->
    <!-- <div class="rwt-team-area rn-section-gap">
      <div class="container">
        <div class="row mb--20">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="我们还能做什么."
              title="敬请期待."
              data-aos="fade-up"
            />
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
            v-for="(teamMember, index) in teamData"
            :key="index"
          >
            <Team :team-member="teamMember" :team-style="4" />
          </div>
        </div>
      </div>
    </div> -->
    <!-- End Elements Area -->
    <!-- Start Contact Area  -->
    <div class="rwt-contact-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb--40">
            <SectionTitle
              text-align="center"
              subtitle="WELCOME"
              title="欢迎与我们联系"
            />
          </div>
        </div>

        <div class="row mt--40 row--15">
          <div class="col-lg-7">
            <ContactForm />
          </div>
          <div class="col-lg-5 col-md-6 col-12" style="margin-top: -5%">
            <div class="rn-address">
              <div class="icon" style="width: 40%">
                <Icon name="headphones" :size="40" />
              </div>
              <div class="inner" style="width: 40%">
                <h4 class="title">联系电话号码</h4>
                <p><a href="tel:+444 555 666 777">+400 021 5123</a></p>
                <!-- <p><a href="tel:+222 222 222 333">+222 222 222 333</a></p> -->
              </div>
              <div
                class="icon"
                style="float: right; margin-right: 34%; margin-top: -28%"
              >
                <Icon name="mail" :size="40" />
              </div>
              <div
                class="inner"
                style="float: right; margin-right: 10%; margin-top: -17%"
              >
                <h4 class="title">邮件地址</h4>
                <p>
                  <a href="mailto:admin@gmail.com">bimhui@yeah.net</a>
                </p>
                <!-- <p><a href="mailto:example@gmail.com">example@gmail.com</a></p> -->
              </div>
            </div>
            <div class="rn-address">
              <div class="icon">
                <Icon name="message-circle" :size="40" />
              </div>
              <div class="inner">
                <h4 class="title">微信联系二维码</h4>
                <div>
                  <img src="../assets/images/about/微信二维码.jpg" />
                </div>
                <!-- <p><a href="mailto:example@gmail.com">example@gmail.com</a></p> -->
              </div>
            </div>
            <!-- <div class="rn-address">
              <div class="icon">
                <Icon name="mail" :size="40" />
              </div>
              <div class="inner">
                <h4 class="title">邮件地址</h4>
                <p>
                  <a href="mailto:admin@gmail.com">bimhui@yeah.net</a>
                </p> -->
            <!-- <p><a href="mailto:example@gmail.com">example@gmail.com</a></p> -->
            <!-- </div>
            </div> -->
          </div>
          <!-- <div class="col-lg-5 mt_md--30 mt_sm--30">
            <GoogleMap />
          </div> -->
        </div>
      </div>
    </div>
    <!-- End Contact Area  -->
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Breadcrumb from "../components/elements/breadcrumb/Breadcrumb";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm";
import AdvancedTab from "../components/elements/tab/AdvancedTab";
import Separator from "../components/elements/separator/Separator";
import AboutFour from "../components/elements/about/AboutFour";
import ServiceSix from "../components/elements/service/ServiceSix";
import Timeline from "../components/elements/timeline/Timeline";
import Counter from "../components/elements/counterUp/Counter";
import Brand from "../components/elements/brand/Brand";
import Team from "../components/elements/team/Team";
import Video from "../components/elements/video/Video";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: "ContactPage",
  components: {
    VueSlickCarousel,
    Video,
    AdvancedTab,
    Team,
    Brand,
    Counter,
    Timeline,
    ServiceSix,
    AboutFour,
    SectionTitle,
    Separator,
    Layout,
    ContactForm,
    GoogleMap,
    Icon,
    SectionTitle,
    Breadcrumb,
    Layout,
  },
  data() {
    return {
      tabData: {
        tabContent: [
          {
            id: 1,
            menu: "market-research",
            title: "新型教材出版物方案.",
            description: "扫码轻松享受互动阅读的乐趣.",
            image: require(`@/assets/images/tab/配图-21.jpg`),
          },
          {
            id: 2,
            menu: "corporate-report",
            title: "企业及电商产品展示方案.",
            description: "在线体验全方位的产品3D展示.",
            image: require(`@/assets/images/tab/配图-22.jpg`),
          },
          {
            id: 3,
            menu: "虚拟展厅建设方案",
            title: "虚拟展厅建设方案.",
            description: "打造产品的立体宣传手册.",
            image: require(`@/assets/images/tab/配图-23.jpg`),
          },
        ],
      },
      videoData: [
        {
          thumb: require(`@/assets/images/bg/视频封面1290x620.jpg`),
          src: "https://bimhui2-0.oss-cn-shanghai.aliyuncs.com/BimWork/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC-%E8%A7%86%E9%A2%91.mp4",
          title: `BIMWOROK可以做什么，我们提供什么服务`,
        },
      ],
      brandList: [
        {
          image: require(`@/assets/images/brand/brand-01.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-02.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-03.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-04.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-05.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-06.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-07.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-08.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-09.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-10.png`),
        },
        {
          image: require(`@/assets/images/brand/brand-11.png`),
        },
      ],
      timelineData: [
        {
          id: "1",
          title: "Knowledge",
          description: "Present all available features in Essentials.",
        },
        {
          id: "2",
          title: "Working",
          description: "All Feature available features in Essentials.",
        },
        {
          id: "3",
          title: "Solution",
          description: "Popular Feature available features in Essentials.",
        },
        {
          id: "4",
          title: "Process",
          description: "Latest Feature available features in Essentials.",
        },
      ],
      counterData: [
        {
          number: 199,
          title: "Happy Clients",
        },
        {
          number: 575,
          title: "Employees",
        },
        {
          number: 69,
          title: "Useful Programs",
        },
        {
          number: 500,
          title: "Useful Programs",
        },
      ],
      teamData: [
        {
          image: "配图-13",
          name: "电子贺卡",
          designation: "",
          location: "",
          description: "",
        },
        {
          image: "配图-14",
          name: "新品H5方案",
          designation: "",
          location: "",
          description: "",
        },
        {
          image: "配图-15",
          name: "Web展览",
          designation: "",
          location: "",
          description: "",
        },
        {
          image: "配图-16",
          name: "嵌入式展示",
          designation: "",
          location: "",
          description: "",
        },
      ],
      sliderSetting: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      sliderData: [
        {
          image:
            "https://bimhuicorebasic.oss-cn-shanghai.aliyuncs.com/banner%E5%AD%98%E5%82%A8/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC-1.jpg",
          title: `关于图模坊`,
          description: `图模坊成立于2021年，是缤汇云®旗下二三维可视化应用服务平台。立足于前沿的互联网云原生和三维显示交互技术，图模坊自主研发了三维图形渲染引擎和轻量化系列工具，使模型资源可以自动输出至Web端、PC端和移动端，实现了多设备、全平台展示和交互的3D通用技术，为图纸和模型的可视化提供了丰富的展现形式和视觉效果，致力于为客户提供有价值的二三维模型。
图模坊搭建了一个供用户上传、展示、分享的服务平台，用户可以自由上传自己的三维模型和图像作品，支持包括Revit、Solidworks、Rhino、3Dmax、NX、Inventor在内的多种3D文件格式。`,
        },
        {
          image:
            "https://bimhuicorebasic.oss-cn-shanghai.aliyuncs.com/banner%E5%AD%98%E5%82%A8/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC-2.jpg",
          title: `关于图模坊`,
          description: `与一般的模型平台所不同的是，图模坊提供了所有模型的720度在线预览界面，所见即所得，并可让用户通过功能强大的个人后台进行管理。图模坊可让你的模型嵌入任何网页，可以一键转发分享，同时支持在产品网站、微信、H5、APP、出版物等方面的二维码应用，也支持为用户的业务场景深度定制开发。
图模坊在3D模型展示与应用的基础上，不断深耕细分行业，建设数字增强世界。`,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.el-radio {
  color: rgb(101, 101, 104);
}

.description2 {
  font-size: 20px;
  font-family: var(--font-primary);
  line-height: 1.7;
  margin-bottom: 40px;
  color: var(--color-body);
}

.class {
  margin-top: -10%;
}

.height-950 {
  height: 650px;
  display: flex;
  align-items: center;
}
</style>
