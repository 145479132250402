<template>
  <Layout :footer-style="3" :show-top-header="false">
    <div class="container">
      <el-row type="flex" class="model" style="margin-bottom: 10px">
        <el-switch v-if="isswitch" v-model="isCollapses" @change="change()" active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
        <!-- 模型及详情 -->
        <el-col :span="16">
          <div class="canvas">
            <show3d ref="show3dRef" :data="tdata"></show3d>
            <div :class="[
              'model-left-menu',
              { 'model-left-menu-show': isshowmenu },
            ]">
              <el-form>
                <el-form-item label="背景" style="margin-top: 25px">
                  <el-radio-group v-model="modelForm.modelBakColor" style="margin-bottom: 20px" class="menu-btns"
                    size="small" @change="selectstyle(modelForm.modelBakColor)">
                    <el-radio-button label="1">渐变</el-radio-button>
                    <el-radio-button label="2">蓝天</el-radio-button>
                    <el-radio-button label="3">星空</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item style="margin: 0" label="自动旋转">
                  <el-switch v-model="modelForm.modelMove" @change="AutoRotate()">
                  </el-switch>
                </el-form-item>
                <el-form-item style="margin: 0" label="开启阴影">
                  <el-switch v-model="modelForm.modelTable" @change="OpenShadow()">
                  </el-switch>
                </el-form-item>
                <el-form-item style="margin: 0" label="显示线框">
                  <el-switch v-model="modelForm.modelShowSize" @change="ShowWireframe()">
                  </el-switch>
                </el-form-item>
                <el-form-item label="天气效果">
                  <el-switch v-model="showWireSwitch" @change="ShowWireSwitch()"></el-switch>
                  <el-radio-group v-model="modelForm.Dispose" class="menu-btns-2" size="small"
                    @change="selectweather(modelForm.Dispose)" v-if="disposeshow">
                    <el-radio-button label="1">雨天</el-radio-button>
                    <el-radio-button label="2">雪天</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="效果配置">
                  <el-radio-group v-model="modelForm.effect" class="menu-btns-2" size="small"
                    @change="effectSwitch(modelForm.effect)">
                    <el-radio-button label="1">自动效果</el-radio-button>
                    <el-radio-button label="2">环境光</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="模型拆分" class="menu-slider" style="margin: 0">
                  <el-slider v-model="modelForm.Resolve" @input="ModelSplit(modelForm.Resolve)"></el-slider>
                </el-form-item>
                <el-form-item style="margin: 0" label="模型操作" class="menu-slider menu-btns-action">
                  <span class="menu-icon-span" @click="history()">
                    <i class="menu-icon-back4">
                      <svg class="menu-icon" aria-hidden="true" style="font-size: 13px; color: red">
                        <use xlink:href="#icon-zhongzhi"></use>
                      </svg>
                    </i>
                    <b style="margin-left: 3px">重置</b>
                  </span>
                  <span class="menu-icon-span" @click="Nofuntion5()">
                    <i class="menu-icon-back5">
                      <svg class="menu-icon" aria-hidden="true" style="font-size: 13px; color: red">
                        <use xlink:href="#icon-xianxingshouzhangtubiao"></use>
                      </svg>
                    </i>
                    <b style="margin-left: 3px">触影</b>
                  </span>
                  <span class="menu-icon-span" @click="Nofuntion6()">
                    <i class="menu-icon-back6">
                      <svg class="menu-icon" aria-hidden="true" style="font-size: 13px; color: red">
                        <use xlink:href="#icon-yincang"></use>
                      </svg>
                    </i>
                    <b style="margin-left: 3px">透明</b>
                  </span>

                  <span class="menu-icon-span" @click="Nofuntion7()">
                    <i class="menu-icon-back7">
                      <svg class="menu-icon" aria-hidden="true" style="font-size: 13px; color: red">
                        <use xlink:href="#icon--fanganguanli"></use>
                      </svg>
                    </i>
                    <b style="margin-left: 3px">透视</b>
                  </span>
                </el-form-item>
                <el-form-item style="margin: 0" label="" class="menu-slider menu-btns-action">
                  <span class="menu-icon-span" @click="Nofuntion1()">
                    <i class="menu-icon-back1">
                      <svg class="menu-icon" aria-hidden="true" style="font-size: 13px; color: red">
                        <use xlink:href="#icon-yidong"></use>
                      </svg>
                    </i>
                    <b style="margin-left: 3px">禁平</b>
                  </span>
                  <span class="menu-icon-span" @click="Nofuntion2()">
                    <i class="menu-icon-back2">
                      <svg class="menu-icon" aria-hidden="true" style="font-size: 13px; color: red">
                        <use xlink:href="#icon-suofang"></use>
                      </svg>
                    </i>
                    <b style="margin-left: 3px">禁缩</b>
                  </span>
                  <span class="menu-icon-span" @click="Nofuntion3()">
                    <i class="menu-icon-back3">
                      <svg class="menu-icon" aria-hidden="true" style="font-size: 13px; color: red">
                        <use xlink:href="#icon-xuanzhuan1"></use>
                      </svg>
                    </i>
                    <b style="margin-left: 3px">禁转</b>
                  </span>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="right-message">
            <el-row type="flex" class="price-sm" style="height: 8%; align-items: center" justify="space-between">
              <span class="sm-span">购买积分</span>
              <el-tooltip content="本3D源文件设计著作权归原作者所有，下载源文件仅供学习参考。" placement="bottom">
                <i class="el-icon-warning-outline sm-i" style="font-size: 13px; color: #b4bac4">下载说明</i>
              </el-tooltip>
            </el-row>
            <el-row type="flex" class="show-price" style="height: 10%; line-height: 90px" justify="center">
              <span class="price-money">{{ modelInfo.modelPrice }}</span><span style="line-height: 110px">积分</span>
            </el-row>
            <el-row type="flex" class="price-desc" style="
                height: 16px;
                align-items: center;
                margin: 12px 0;
                color: #b4bac4;
              " justify="center">
              (1元=1积分)
            </el-row>
            <el-row type="flex" class="tc-desc" style="height: 5%; align-items: center" justify="center">
              <span style="font-size: 13px; color: red">充值套餐享更多优惠</span>
              <svg class="tc-desc-icon" aria-hidden="true" style="font-size: 13px; color: red">
                <use xlink:href="#icon-arrow-right-circle"></use>
              </svg>
            </el-row>
            <el-row type="flex" class="cz-botn" style="height: 11%; padding-bottom: 20px; margin-top: 20px"
              justify="space-between">
              <el-button type="warning" style="width: 184px; height: 50px" @click="BuyNow()">立即购买</el-button>
              <el-button type="warning" icon="el-icon-shopping-cart-2" plain
                style="width: 48px; height: 48px; font-size: 19px" class="cz-botn-right"></el-button>
            </el-row>
            <el-row type="flex" class="show-details" style="height: 46%">
              <ul>
                <li>
                  <span class="details-left">ID</span>
                  <span class="details-right">{{ modelInfo.fileId }}</span>
                </li>
                <li>
                  <span class="details-left">来源</span>
                  <span class="details-right">{{ modelInfo.sources }}</span>
                </li>
                <li>
                  <span class="details-left">格式</span>
                  <span class="details-right">{{ modelInfo.modelType }}</span>
                </li>
                <li>
                  <span class="details-left">大小</span>
                  <span class="details-right">{{
                    formatter(modelInfo.modelSize)
                  }}</span>
                </li>
                <li>
                  <span class="details-left">贴图</span>
                  <span class="details-right">{{
                    modelInfo.modelChartlet
                  }}</span>
                </li>
                <li>
                  <span class="details-left">动画</span>
                  <span class="details-right">{{
                    modelInfo.modelCartoon
                  }}</span>
                </li>
              </ul>
            </el-row>
            <!-- //右下方点赞区域 -->
            <el-row type="flex" class="right-icons" style="
                border-top: 1px solid rgb(229, 233, 242);
                height: 10%;
                padding: 20px 0 10px 0;
              " justify="space-between">
              <p class="light-icons">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-good"></use>
                </svg>
                <span class="icon-title">{{ modelInfo.likeNumber }}</span>
              </p>
              <p class="light-icons">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-fabulous"></use>
                </svg>
                <span class="icon-title">收藏</span>
              </p>
              <p class="light-icons">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-share"></use>
                </svg>
                <span class="icon-title">分享</span>
              </p>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <!-- 作者+作品展示 -->
      <el-row type="flex" class="btm" style="margin-bottom: 10px" justify-content="space-between">
        <el-col :span="18" class="btm-left">
          <el-row>
            <div class="btm-title">{{ modelInfo.btmTitle }}</div>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="2">
              <el-avatar :src="modelInfo.btmPhoto" style="width: 45px; height: 45px"></el-avatar>
            </el-col>
            <el-col :span="4">
              <el-row style="height: 19px">
                <span style="padding-left: 16px">{{ modelInfo.btmName }}</span>
              </el-row>
              <el-row style="height: 26px; line-height: 30px">
                <el-button type="primary" plain style="
                    height: 22px;
                    line-height: 22px;
                    padding: 0 16px;
                    margin-top: 10px;
                    margin-left: 10px;
                  ">关注
                </el-button>
              </el-row>
            </el-col>
          </el-row>
          <el-row>
            <el-row style="margin-top: 10px">
              <span style="margin-right: 20px">上传时间</span>
              <span>{{ dateFormat(modelInfo.uploadTime) }}</span>
            </el-row>
            <el-row justify="center" style="margin-top: 10px">
              <span style="margin-right: 20px">模型说明</span>
              <span>{{ modelInfo.modelDesc }}</span>
            </el-row>
          </el-row>
        </el-col>
        <el-col :span="6" style="" class="btm-right">
          <el-row>
            <el-col>
              <div class="show" style="text-align: center; margin-bottom: 10px">
                个人作品展示
              </div>
            </el-col>
          </el-row>
          <el-row>
            <!-- //前端截取 -->
            <el-col :span="5" v-for="(item, index) in worksList.slice(0, 3)" :key="index" :offset="index > 0 ? 1 : 0"
              class="img-box">
              <el-image :src="item" style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  margin-left: 15px;
                " />
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <el-row type="flex" class="recom" justify="end" style="margin-bottom: 20px">
        <!-- 推荐模型 -->
        <el-col :span="24">
          <el-row style="margin-bottom: 10px">
            <div class="recom-title">
              <span style="float: left">推荐模型</span>
              <i class="el-icon-refresh" style="float: right; font-size: 13px">换一批</i>
            </div>
          </el-row>
          <div class="map-card-group">
            <!-- //以下为推荐图片地址 -->
            <el-row>
              <el-col class="card-col" :xs="12" :sm="9" :md="6" :lg="5" :xl="5" v-for="item in curPageMapCards"
                :key="item.key">
                <el-card class="card-item" :id="item.name" shadow="hover"
                  :body-style="{ padding: '2px', color: 'aquamarine' }">
                  <div @click="routerdetail(item)">
                    <el-image :key="item.key" :src="item.urlImage" lazy class="card-image">
                    </el-image>
                  </div>
                  <!-- 1-2 文件名 -->
                  <div class="text-ellipsis">
                    <div class="textel">
                      <div class="name" style="
                          color: black;
                          float: left;
                          font-size: 15px;
                          margin-left: 5px;
                        ">
                        {{ item.name }}
                      </div>
                      <div class="icons">
                        <svg class="icon" aria-hidden="true" style="
                            width: 25px;
                            height: 22px;
                            margin-left: 10px;
                            color: black;
                            cursor: pointer;
                          ">
                          <use xlink:href="#icon-Buy"></use>
                        </svg>
                        <el-tooltip content="下载" placement="bottom">
                          <svg class="icon" aria-hidden="true" style="
                              width: 25px;
                              height: 22px;
                              margin-right: 2px;
                              color: black;
                              cursor: pointer;
                            ">
                            <use xlink:href="#icon-download1"></use>
                          </svg>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <el-divider></el-divider>
                  <!-- 1- 图纸信息板块 -->
                  <div class="card-bottom">
                    <!-- 1-3 按钮模块 -->
                    <!-- //头像 -->
                    <div class="avatar">
                      <el-avatar :size="30" icon="el-icon-user-solid" style="margin-right: 6px"></el-avatar>
                      <div class="title">{{ item.account }}</div>
                    </div>
                    <div class="icon">
                      <el-tooltip content="浏览次数" placement="bottom">
                        <i class="el-icon-view" style="margin-right: 10px">{{
                          item.showint
                        }}</i>
                      </el-tooltip>
                      <el-tooltip content="下载次数" placement="bottom">
                        <i class="el-icon-download">{{ item.dowanloadint }}</i>
                      </el-tooltip>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" class="talk">
        <!-- 评论 -->
        <el-col :span="24" style="text-align: left; margin-top: 10px">
          <p class="talk-title">评论({{ commentNumber }})</p>
          <div class="talk-form">
            <el-form :inline="true">
              <el-form-item>
                <el-col :span="24">
                  <el-input placeholder="请输入评论" :span="8" maxlength="200" show-word-limit style="width: 1000px"
                    v-model="commentForm.commentInput">
                  </el-input>
                </el-col>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" style="margin-left: 20px">发表评论</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
  </Layout>
</template>

<script>
import show3d from "./MallService3dshow.vue";
import Layout from "../components/common/Layout";
import axios from "axios";
import { getToken } from "@/utils/token-util";
export default {
  name: "xiangqing",
  components: {
    Layout,
    show3d,
  },
  props: {},
  data() {
    return {
      //天气效果开关
      showWireSwitch: false,
      disposeshow: false,
      modelstyle: 1,
      isswitch: true,
      isshowmenu: false,
      tdata: {
        fileId: this.$route.params.fileId,
        type: this.$route.params.type,
        split: null,
      },
      modelForm: {
        modelBakColor: 1,
        modelMove: false,
        modelTable: false,
        modelShowSize: false,
        Dispose: null,
        Resolve: null,
        effect: this.geteffect(),
      },
      show: false,
      isCollapses: true,
      modelInfo: {
        //价格
        modelPrice: 1499,
        //格式
        modelType: null,
        //大小
        modelSize: null,
        //FILEid
        fileId: null,
        sources: null,
        //贴图
        modelChartlet: "有",
        //动画
        modelCartoon: "无",
        //点赞
        likeNumber: 1,
        //名字
        btmTitle: "环保展厅",
        //个人名字
        btmName: "香蕉",
        //上传时间
        uploadTime: "2022-10-12 09:13:44",
        //上传说明
        modelDesc: "暂无模型说明",
        //用户头像
        btmPhoto:
          "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
      },
      //个人作品
      worksList: [
        "https://laozi-model.obs.cn-east-2.myhuaweicloud.com/mapping/A51272A31ECC406BA73784D831EF3269.png?x-image-process=image/resize,m_lfit,h_256,w_256",
        "https://laozi-model.obs.cn-east-2.myhuaweicloud.com/mapping/A10A359A7F774BABAEFFE0C4084DA199.png?x-image-process=image/resize,m_lfit,h_256,w_256",
        "https://laozi-model.obs.cn-east-2.myhuaweicloud.com/mapping/E0442470EC0847DE90FD7F1B027652B7.png?x-image-process=image/resize,m_lfit,h_256,w_256",
      ],
      // 数据
      curPageMapCards: [],
      //评论数量
      commentNumber: null,
      //评论
      commentForm: {
        commentInput: "",
      },
      nofuntion1: false,
      nofuntion2: false,
      nofuntion3: false,
      nofuntion5: false,
      nofuntion6: false,
      nofuntion7: false,
    };
  },
  mounted() {
    this.initData();
    this.getData();
  },
  methods: {
    //重置
    history() {
      document.querySelector(".menu-icon-back4").style.backgroundColor =
        "#1458ea";
      history.go(0);
    },
    //禁止平移
    Nofuntion1() {
      this.nofuntion1 = !this.nofuntion1;
      if (this.nofuntion1) {
        //禁止平移
        document.querySelector(".menu-icon-back1").style.backgroundColor =
          "#1458ea";
        this.$refs.show3dRef.Nofuntion1("1");
      } else {
        document.querySelector(".menu-icon-back1").style.backgroundColor =
          "rgba(63, 63, 65, 0.2)";
        this.$refs.show3dRef.Nofuntion1("2");
      }
    },
    //禁止缩放
    Nofuntion2() {
      this.nofuntion2 = !this.nofuntion2;
      if (this.nofuntion2) {
        //禁止平移
        document.querySelector(".menu-icon-back2").style.backgroundColor =
          "#1458ea";
        this.$refs.show3dRef.Nofuntion2("1");
      } else {
        document.querySelector(".menu-icon-back2").style.backgroundColor =
          "rgba(63, 63, 65, 0.2)";
        this.$refs.show3dRef.Nofuntion2("2");
      }
    },
    //禁止旋转
    Nofuntion3() {
      this.nofuntion3 = !this.nofuntion3;
      if (this.nofuntion3) {
        //禁止平移
        document.querySelector(".menu-icon-back3").style.backgroundColor =
          "#1458ea";
        this.$refs.show3dRef.Nofuntion3("1");
      } else {
        document.querySelector(".menu-icon-back3").style.backgroundColor =
          "rgba(63, 63, 65, 0.2)";
        this.$refs.show3dRef.Nofuntion3("2");
      }
    },
    //触影
    Nofuntion5() {
      this.nofuntion5 = !this.nofuntion5;
      if (this.nofuntion5) {
        document.querySelector(".menu-icon-back5").style.backgroundColor =
          "#1458ea";
        this.$refs.show3dRef.Shadowtrue();
      } else {
        document.querySelector(".menu-icon-back5").style.backgroundColor =
          "rgba(63, 63, 65, 0.2)";
        this.$refs.show3dRef.Shadowfalse();
      }
    },
    //触影
    Nofuntion6() {
      this.nofuntion6 = !this.nofuntion6;
      if (this.nofuntion6) {
        document.querySelector(".menu-icon-back6").style.backgroundColor =
          "#1458ea";
        this.$refs.show3dRef.overrideAllComponentsColor("1");
      } else {
        document.querySelector(".menu-icon-back6").style.backgroundColor =
          "rgba(63, 63, 65, 0.2)";
        this.$refs.show3dRef.overrideAllComponentsColor("2");
      }
    },
    //透视
    Nofuntion7() {
      this.nofuntion7 = !this.nofuntion7;
      if (this.nofuntion7) {
        document.querySelector(".menu-icon-back7").style.backgroundColor =
          "#1458ea";
        this.$refs.show3dRef.Nofuntion7("1");
      } else {
        document.querySelector(".menu-icon-back7").style.backgroundColor =
          "rgba(63, 63, 65, 0.2)";
        this.$refs.show3dRef.Nofuntion7("2");
      }
    },
    //出现按钮
    change() {
      if (this.isCollapses) {
        this.isshowmenu = false;
      } else {
        this.isshowmenu = true;
      }
    },
    //天空盒颜色
    selectstyle(value) {
      switch (value) {
        case "1":
          //1渐变背景色
          this.$refs.show3dRef.gradientBackgroundColor();
          break;
        case "3":
          this.$refs.show3dRef.setGalaxySky();
          break;
        default:
          //蓝天
          this.$refs.show3dRef.setBlueSky();
      }
    },
    //1 自动旋转
    AutoRotate() {
      if (this.modelForm.modelMove) {
        this.$refs.show3dRef.centerRotate();
      } else {
        this.$refs.show3dRef.stopcenterRotate();
      }
    },
    //2 模型阴影
    OpenShadow() {
      this.$refs.show3dRef.openShadowtrue();
    },
    //3 显示线框
    ShowWireframe() {
      this.$refs.show3dRef.showWireframetrue();
    },
    //4-1 天气效果开关
    ShowWireSwitch() {
      if (this.showWireSwitch) {
        this.disposeshow = true;
        this.modelForm.Dispose = null;
      } else {
        this.disposeshow = false;
        this.$refs.show3dRef.selectweather("3");
      }
    },
    //4 天气效果
    selectweather(value) {
      this.$refs.show3dRef.selectweather(value);
    },
    //高亮构件
    Shadow() {
      if (this.modelForm.modelTable) {
        this.$refs.show3dRef.Shadowtrue();
      } else {
        this.$refs.show3dRef.Shadowfalse();
      }
    },
    //效果配置
    effectSwitch(value) {
      //环境光效果
      if (value == "2") {
        localStorage.setItem("effect", value);
      } else {
        localStorage.removeItem("effect", value);
      }
      history.go(0);
    },
    //根据缓存判断自动花式还是后处理效果
    geteffect() {
      var data =
        localStorage.getItem("effect") == null
          ? "1"
          : localStorage.getItem("effect");
      return data;
    },
    //模型拆分
    ModelSplit(value) {
      if (value != "0") {
        this.tdata.split = value / 34;
        this.$refs.show3dRef.modelSplit(this.tdata.split);
      }
    },
    //简单测量
    SimpleMeasurement() {
      if (this.modelForm.modelTable) {
        this.$refs.show3dRef.SimpleMeasure();
      } else {
        this.$refs.show3dRef.SimpleMeasure();
      }
    },

    dateFormat(date) {
      return this.jnpf.dateFormat(date);
    },
    formatter(size) {
      return this.jnpf.formatter(size);
    },
    //获取推荐模型
    initData() {
      //先判断图纸
      if (this.$route.params.type == 2) {
        this.isswitch = false;
        this.isshowmenu = true;
      }
      //默认情况下是个人查询
      this.loading = true;
      let query = {
        sort: "推荐",
        industry: "室外场景",
        type: "模型",
        free: false,
        currentPage: 1,
        pageSize: 4,
      };
      axios
        .post(
          "https://api.tomore.fun/api/BimWorkWebService/GetPlant",
          query
        )
        .then((res) => {
          this.curPageMapCards = res.data.data.list;
        })
        .catch((e) => {
          this.$message.error("获取失败，请稍后再试");
        });
    },
    //获取数据
    getData() {
      var Id = this.$route.params.id;
      var parentId = this.$route.params.parentId;
      //默认情况下是个人查询
      let query = {
        Id: Id,
        parentId: parentId,
      };
      axios
        .post(
          "https://api.tomore.fun/api/BimWorkWebService/GetData",
          query
        )
        .then((res) => {
          this.modelInfo = res.data.data;
        })
        .catch((e) => {
          this.$message.error("获取失败，请稍后再试");
        });
    },
    BuyNow() {
      if (getToken()) {
        this.$message.success("显示订单支付页面");
      } else {
        this.Login();
      }
    },
    //登录
    Login() {
      this.$router.push("/Login");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  margin-top: 40px;
  width: 1180px;
  // margin: auto;
  padding: 0;
}

.right-message {
  padding: 0 20px;
  height: 100%;

  .sm-span {
    float: left;
  }

  .sm-i {
    float: right;
  }

  .tc-desc-icon {
    fill: red;
  }

  .tc-desc:hover {
    cursor: pointer;
  }

  .price-money {
    font-family: "PingFang SC";
    font-size: 39px;
    font-weight: 700;
    color: #1458ea;
  }

  ul {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    font-size: 13px;
    list-style: none;
    background-color: rgba(25, 3, 169, 0.04);
    // background-color: rgba(20, 88, 234, 0.04);
    border-radius: 10px;

    li {
      height: 16.7%;
      font-size: 13px;
      margin: 0;

      .details-left {
        float: left;
        color: #8192a8;
      }

      .details-right {
        float: right;
      }
    }
  }

  .light-icons {
    .icon-title {
      font-size: 13px;
    }

    p {
      line-height: 100px;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }

  .light-icons:hover {
    cursor: pointer;
    color: #1458ea;
    fill: #1458ea;
  }

  .sm-i:hover {
    cursor: pointer;
  }
}

.el-icon-refresh:hover {
  cursor: pointer;
  color: #1458ea;
  fill: #1458ea;
}

.img-box {
  width: 65px;
  height: 65px;

  img {
    width: 65px;
    height: 65px;
  }
}

.icon-title {
  font-size: 13px;
}

.model {
  height: 680px;

  .canvas {
    width: 780px;
    height: 100%;

    .menu-switch {
      position: absolute;
      right: 300px;
    }

    .model-left-menu {
      left: 40px;
      width: 200px;
      height: 100%;
      padding: 0 20px;
      margin: 0;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      position: absolute;
      overflow: hidden;
      top: 0;
      transition: 0.5s;

      ::v-deep .el-form-item__label {
        color: #fff;
      }

      ::v-deep .menu-btns .el-radio-button__inner {
        // margin: 0;
        // background-color: #fff;
        // color: white;
        font-size: 11px;
        width: 50px;
        // height: 2.4rem;
      }

      ::v-deep .menu-btns-2 .el-radio-button__inner {
        font-size: 11px;
        width: 78px;
      }

      ::v-deep .menu-slider .el-form-item__label {
        float: none; // 取消label左浮动
        word-break: break-word; // 支持单词截断换行
      }

      ::v-deep .menu-btns-action .el-form-item__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .el-form-item {
        margin-bottom: 0;
      }

      .menu-icon-span {
        // margin-left: 4px;
        width: 36px;
        height: 80px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        padding: 10px 0;

        .menu-icon-back4 {
          line-height: 36px;
          text-align: center;
          border-radius: 18px;
          background-color: rgba(63, 63, 65, 0.2);
        }

        .menu-icon-back5 {
          line-height: 36px;
          text-align: center;
          border-radius: 18px;
          background-color: rgba(63, 63, 65, 0.2);
        }

        .menu-icon-back6 {
          line-height: 36px;
          text-align: center;
          border-radius: 18px;
          background-color: rgba(63, 63, 65, 0.2);
        }

        .menu-icon-back7 {
          line-height: 36px;
          text-align: center;
          border-radius: 18px;
          background-color: rgba(63, 63, 65, 0.2);
        }

        .menu-icon-back1 {
          line-height: 36px;
          text-align: center;
          border-radius: 18px;
          background-color: rgba(63, 63, 65, 0.2);
        }

        .menu-icon-back2 {
          line-height: 36px;
          text-align: center;
          border-radius: 18px;
          background-color: rgba(63, 63, 65, 0.2);
        }

        .menu-icon-back3 {
          line-height: 36px;
          text-align: center;
          border-radius: 18px;
          background-color: rgba(63, 63, 65, 0.2);
        }

        .menu-icon {
          width: 18px;
          height: 18px;
          fill: #ffffff;
        }
      }
    }

    .model-left-menu-show {
      left: -500px;
    }
  }
}

.cz-botn-right {
  display: flex;
  justify-content: center;
  /*主轴上居中*/
  align-items: center;
  /*侧轴上居中*/
}

.btm {
  height: 205px;
  text-align: left;

  .btm-right {
    margin-left: 2px;
    // display: flex;
    justify-content: center;
    /*主轴上居中*/
    align-items: center;
    /*侧轴上居中*/
  }

  .btm-title {
    font-size: 17px;
    font-weight: 700;
    font-family: "PingFang SC";
  }
}

.recom {
  height: 310px;

  .recom-title {
    font-size: 17px;
    font-weight: 700;
    font-family: "PingFang SC";
  }
}

.talk {
  height: 188px;

  .talk-title {
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 700;
    font-family: "PingFang SC";
  }
}

.btm-left,
.btm-right {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
}

.talk-form {
  background-color: #ffffff;
  height: 144px;
  padding: 20px;
  border-radius: 10px;
}

.map-card-group {
  width: 100%;

  .el-divider {
    margin: 2px 0;
  }

  .card-image {
    height: 200px;
    width: 100%;
    text-align: center;
  }

  .card-col {
    padding: 5px;
  }

  .card-imge {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--el-text-color-secondary);
    font-size: 15px;
    // color: blue;
  }

  .demo-qrcode-image-item {
    width: 200px;
  }

  .bg-error {
    background: #ecfaf1;
  }

  .card-bottom {
    padding: 2px;
    font-size: 13px;

    .operation {
      display: flex;
      height: 40px;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
  }

  .text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    // width: 260px;
    // margin-left:39px;
  }

  .width150 {
    width: 200px !important;
  }

  .co-map-tag {
    position: absolute;
    right: 3px;
    top: 3px;
  }

  .textel {
    margin-right: 2px;
    font-size: 13px;
  }

  .card-item {
    position: relative;
  }

  .icon {
    // font-size: 12px;
    margin-top: 5px;
    float: right;
    color: rgb(67, 66, 66);
    margin-right: 2px;
  }

  .icons {
    margin-bottom: 2px;
  }
}

.title {
  color: black;
  float: right;
  font-size: 13px;
  margin-top: 5px;
}

.avatar {
  float: left;
}
</style>


