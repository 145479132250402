<template>
  <Layout
    header-class="header-transparent"
    :show-newsletter="false"
    :show-cta="false"
    :footer-style="3"
  >
    <div class="slider-area slider-style-1 bg-transparent height-850">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center">
              <span class="subtitle" data-aos="fade-up" data-aos-delay="100"
                >SHOW Model 三维数字服务</span
              >
              <h1
                class="maintitle theme-gradient display-two"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                三维模型应用服务 <br />
                <vue-typed-js
                  class="justify-content-center"
                  :strings="['', '3D效果', '可视化大屏', '无代码编辑']"
                  :loop="true"
                  :type-speed="80"
                  :start-delay="0"
                  :back-speed="50"
                >
                  <b class="is-visible theme-gradient typing"></b>
                </vue-typed-js>
              </h1>
              <p class="description" data-aos="fade-up" data-aos-delay="200">
                我们帮助用户成功应用3D数据，3D编辑、3D展示、3D功能汇报，嵌入式应用，全场景应用
              </p>
              <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                <a
                  class="btn-default btn-medium round btn-icon"
                  href="https://www.tomore.fun/"
                  target="_blank"
                >
                  开始 创作！
                  <Icon name="arrow-right" size="20" icon-class="icon" />
                </a>
                <router-link
                  class="btn-default btn-medium btn-border round btn-icon"
                  to="/contact"
                >
                  联系我们！
                  <Icon name="arrow-right" size="20" icon-class="icon" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between main">
      <el-row>
        <el-col :span="12"
          ><div class="grid-content bg-purple-dark">
            <div
              class="title"
              style="float: left; background-color: transparent"
            >
              <span
                class="theme-gradient"
                style="font-size: 34px; font-weight: 700"
                >模型效果编辑器</span
              ><span style="margin-left: 5px">(方便，快捷，高效)</span>
              <ul
                style="
                  list-style-type: none;
                  text-align: left;
                  margin-left: -3%;
                  margin-top: 10%;
                "
              >
                <li style="font-size: 13px; background-color: transparent">
                  支持三维场景中修改设备点位、属性数据等信息。
                </li>
                <li style="font-size: 13px">
                  支持数据看板层面，根据数据类型选择各形态的图表展示。
                </li>
                <li style="font-size: 13px">支持模型、看板数据业务绑定。</li>
              </ul>
              <!-- <div class="fc">
              <a class="btn-default round btn-icon" href="#Home" style="font-size:15px">多格式导入 </a>
              <a class="btn-default round btn-icon" href="#Home" style="margin-left:20px;font-size:15px">多图表选择 </a>
              <a class="btn-default round btn-icon" href="#Home" style="margin-top:20px;font-size:15px">多样式修改 </a>
            </div> -->
              <a
                class="btn-default round btn-icon"
                href="https://www.tomore.fun/"
                style="margin-top: 20%; font-size: 14px"
                >获取体验</a
              >
            </div>
          </div></el-col
        >
        <el-col :span="12"
          ><div class="grid-content bg-purple">
            <div class="mian-right fadeInUp" id="miao99">
              <el-carousel indicator-position="outside" height="500px">
                <el-carousel-item>
                  <img
                    src="../../assets/images/main/模型效果编辑器-1.png"
                    alt=""
                    class="bjq"
                  />
                </el-carousel-item>
                <el-carousel-item>
                  <img
                    src="../../assets/images/main/模型效果编辑器-2.png"
                    alt=""
                    class="bjq"
                  />
                </el-carousel-item>
                <el-carousel-item>
                  <img
                    src="../../assets/images/main/模型效果编辑器-3.png"
                    alt=""
                    class="bjq"
                  />
                </el-carousel-item>
              </el-carousel>
            </div></div
        ></el-col>
      </el-row>
    </div>
    <div class="flex justify-between main" id="miao98">
      <el-row>
        <el-col :span="12"
          ><div class="grid-content bg-purple-dark">
            <div
              class="title"
              style="float: left; background-color: transparent"
            >
              <span
                class="theme-gradient"
                style="font-size: 34px; font-weight: 700"
                >无代码大屏编辑器</span
              ><span style="margin-left: 5px">(方便，快捷，高效)</span>
              <ul
                style="
                  list-style-type: none;
                  text-align: left;
                  margin-left: -3%;
                  margin-top: 10%;
                "
              >
                <li style="font-size: 13px; background-color: transparent">
                  支持三维场景的设备属性数据修改。
                </li>
                <li style="font-size: 13px">
                  支持根据数据类型选择各形态的图表展示。
                </li>
                <li style="font-size: 13px">支持模型、看板数据业务绑定。</li>
              </ul>
              <!-- <div class="fc">
              <a class="btn-default round btn-icon" href="#Home" style="font-size:15px">多格式导入 </a>
              <a class="btn-default round btn-icon" href="#Home" style="margin-left:20px;font-size:15px">多图表选择 </a>
              <a class="btn-default round btn-icon" href="#Home" style="margin-top:20px;font-size:15px">多样式修改 </a>
            </div> -->
              <a
                class="btn-default round btn-icon"
                href="https://www.tomore.fun/"
                style="margin-top: 20%; font-size: 14px"
                >获取体验</a
              >
            </div>
          </div>
        </el-col>
        <el-col :span="12"
          ><div class="grid-content bg-purple-dark">
            <div class="mian-right fadeInUp">
              <el-carousel indicator-position="outside" height="500px">
                <el-carousel-item>
                  <img
                    src="../../assets/images/main/无代码大屏编辑器-1.png"
                    alt=""
                    class="bjq"
                  />
                </el-carousel-item>
                <el-carousel-item>
                  <img
                    src="../../assets/images/main/无代码大屏编辑器-2.png"
                    alt=""
                    class="bjq"
                  />
                </el-carousel-item>
                <el-carousel-item>
                  <img
                    src="../../assets/images/main/无代码大屏编辑器-3.png"
                    alt=""
                    class="bjq"
                  />
                </el-carousel-item>
              </el-carousel>
            </div></div
        ></el-col>
      </el-row>
    </div>
  </Layout>
</template>

<script>
import Card from "../../components/base/Card.vue";
import Layout from "../../components/common/Layout.vue";
import Icon from "../../components/icon/Icon";
export default {
  components: {
    Card,
    Layout,
    Icon,
  },
  data() {
    return {};
  },
  methods: {
    handlemore() {
      document.documentElement.scrollTop = document.body.clientHeight;
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  align-items: center;
}

.title {
  font-size: 29px;
  font-weight: 500;
  color: #000000;
  margin-left: 15%;
  margin-top: 5%;
  span {
    font-size: 19px;
    color: #b3b3b3;
  }
}

ul {
  font-weight: 400;
  color: #b3b3b3;
}

.fc {
  width: 320px;
  margin-top: 5rem;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;

  div {
    width: 140px;
    height: 44px;
    background: #706868;
    border-radius: 21.88px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 19px;
    font-size: 0.9rem;

    img {
      width: 19px;
      height: 23px;
      margin-right: 16px;
      margin-left: 15px;
    }
  }
}

.mian-right {
  width: 900px;
  height: 500px;
  border-radius: 1.25rem;
  float: right;
  margin-bottom: 10%;
  margin-right: 10%;
}

.bjq {
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 1.125rem;
  opacity: 0.75;
  line-height: 18.75rem;
  margin: 0;
}
</style>