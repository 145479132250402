<template>
  <Layout>
    <!-- Start Error-Area Area  -->
    <div class="error-area ptb--200 ptb_sm--60 ptb_md--80">
      <div class="container">
        <div class="row align-item-center">
          <div class="col-lg-12">
            <div class="error-inner">
              <!-- <form action="#" class="blog-search">
                <input type="text" placeholder="Search Here..." />
                <button class="search-button">
                  <Icon name="search" size="20" />
                </button>
              </form> -->
              <div class="view-more-button">
                <a class="btn-default" href="/">回到主页</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Error-Area Area  -->
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Icon from "../components/icon/Icon";

export default {
  components: { Icon, Layout },
  mounted() {
    this.test();
  },
  methods: {
    test() {
      var a = this.$route.query.id;
      if (a == "12345") {
        window.location.href = "/business-consulting/#business1";
      }
      if (a == "123456") {
        window.location.href = "/business-consulting/#business2";
      }
      if (a == "1234567") {
        window.location.href = "/business-consulting/#business3";
      }
      if (a == "12345678") {
        window.location.href = "/business-consulting/#business4";
      }
      if (a == "1") {
        window.location.href = "/business-consulting-2/#miao1";
      }
      if (a == "2") {
        window.location.href = "/business-consulting-2/#miao2";
      }
      if (a == "3") {
        window.location.href = "/business-consulting-2/#miao3";
      }
      if (a == "4") {
        window.location.href = "/business-consulting-2/#miao4";
      }
      if (a == "6") {
        window.location.href = "/corporate/#miao6";
      }
      if (a == "7") {
        window.location.href = "/corporate/#miao7";
      }
      if (a == "001") {
        window.location.href = "/startup/#miao1";
      }
      if (a == "11") {
        window.location.href = "/web-agency/#miao1";
      }
      if (a == "12") {
        window.location.href = "/web-agency/#miao2";
      }
      if (a == "13") {
        window.location.href = "/web-agency/#miao3";
      }
      // if (a == "99") {
      //   window.location.href = "/Editor/#miao99";
      // }
      // if (a == "98") {
      //   window.location.href = "/Editor/#miao98";
      // }
      // if (a == "97") {
      //   window.location.href = "/Service/#miao97";
      // }
      // if (a == "96") {
      //   window.location.href = "/Service/#miao96";
      // }
      // if (a == "95") {
      //   window.location.href = "/Service/#miao95";
      // }
      // if (a == "94") {
      //   window.location.href = "/Price/#miao94";
      // }
      // if (a == "93") {
      //   window.location.href = "/Price/#miao93";
      // }
    },
  },
};
</script>