<template>
  <Layout :footer-style="3" header-class="header-transparent">
    <div
      class="slider-area slider-style-1 variation-default height-850 bg_image"
      data-black-overlay="7"
      :style="{
        'background-image': `url(https://bimhuicorebasic.oss-cn-shanghai.aliyuncs.com/banner%E5%AD%98%E5%82%A8/3Dbanner.jpg)`,
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center">
              <span class="subtitle" data-aos="fade-up" data-aos-delay="100">
                快捷、高效
              </span>
              <h1
                class="title display-one"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                ShowModel服务
              </h1>
              <p class="description" data-aos="fade-up" data-aos-delay="200">
                模型在线预览
                <!-- <br/>CAD汇报宝（多模板） -->
                <br />嵌入式体验<br />丰富的场景应用
              </p>
              <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                <a class="btn-default btn-medium round btn-icon">
                  立刻登录 体验！
                  <Icon name="arrow-right" size="20" icon-class="icon" />
                </a>
                <router-link
                  class="btn-default btn-medium btn-border round btn-icon"
                  to="/contact"
                >
                  联系客服，指导体验！
                  <Icon name="arrow-right" size="20" icon-class="icon" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rwt-portfolio-area rn-section-gapTop" id="Home">
      <div class="container-fluid plr--60 plr_md--15 plr_sm--15">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="在线实时展示"
              title="上传模型、一键预览、开始创造！"
              data-aos="fade-up"
            />
          </div>
        </div>
        <Portfolio
          :portfolio-data="portfolioData"
          column="col-lg-4 col-md-6 mt--30 box-grid-layout no-overlay"
        />
        <!-- <Portfolio2
          :portfolio-data="portfolioData2"
          column="col-lg-4 col-md-6 mt--30 box-grid-layout no-overlay"
        /> -->
      </div>
    </div>
    <div class="rn-service-area rn-section-gap" id="miao2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="多元场景应用"
              title="我拥有模型可以做什么？"
              description="我们提供了功能强大的模型编辑器，进入我的工作台，高效开展模型应用"
              data-aos="fade-up"
            />
          </div>
        </div>
        <ServiceFive service-style="gallery-style" text-align="left" />
      </div>
    </div>
    <div class="quxiantime">
      <div
        class="timetitle"
        style="
          text-align: center;
          font-size: 40px;
          margin: 2% 0%;
          font-weight: 800;
        "
      >
        Web3D内容创作与展示流程
      </div>
      <div class="bolangxian">
        <div class="timelinecontent">
          <el-row>
            <el-col :span="2"
              ><div
                class="grid-content bg-purple"
                style="
                  text-align: center;
                  margin-top: 70px;
                  margin-left: -30px;
                  font-size: 22px;
                  color: black;
                  font-weight: 600;
                "
              >
                模型服务
              </div></el-col
            >
            <el-col :span="3"
              ><div class="grid-content bg-purple-light"></div
            ></el-col>
            <el-col :span="2"
              ><div
                class="grid-content bg-purple"
                style="
                  text-align: center;
                  margin-top: 200px;
                  font-size: 22px;
                  color: black;
                  font-weight: 600;
                "
              >
                模型编辑
              </div></el-col
            >
            <el-col :span="4"
              ><div class="grid-content bg-purple-light"></div
            ></el-col>
            <el-col :span="2"
              ><div
                class="grid-content bg-purple"
                style="
                  text-align: center;
                  margin-top: 100px;
                  margin-left: -15px;
                  font-size: 22px;
                  color: black;
                  font-weight: 600;
                "
              >
                场景搭建
              </div></el-col
            >
            <el-col :span="3"
              ><div class="grid-content bg-purple-light"></div
            ></el-col>
            <el-col :span="2"
              ><div
                class="grid-content bg-purple"
                style="
                  text-align: center;
                  margin-top: 200px;
                  margin-left: -40px;
                  font-size: 22px;
                  color: black;
                  font-weight: 600;
                "
              >
                开发应用
              </div></el-col
            >
            <el-col :span="3"
              ><div class="grid-content bg-purple-light"></div
            ></el-col>
            <el-col :span="3"
              ><div
                class="grid-content bg-purple"
                style="
                  text-align: center;
                  margin-top: 100px;
                  margin-left: -45px;
                  font-size: 22px;
                  color: black;
                  font-weight: 600;
                "
              >
                全网多端显示
              </div></el-col
            >
          </el-row>
          <el-row>
            <el-col :span="2"
              ><div
                class="grid-content bg-purple"
                style="margin-top: -120px; margin-left: -10px"
              >
                为您提供海量低面数模型资源、模型全自动轻量化处理(单模型轻量化和
                倾斜摄影轻量化) 70+种模型格式转换一站式模型服务
              </div></el-col
            >
            <el-col :span="3"
              ><div class="grid-content bg-purple-light"></div
            ></el-col>
            <el-col :span="2"
              ><div class="grid-content bg-purple">
                单模型效果编辑器,让模型展示效果和方式更加丰富
              </div></el-col
            >
            <el-col :span="4"
              ><div class="grid-content bg-purple-light"></div
            ></el-col>
            <el-col :span="2"
              ><div class="grid-content bg-purple" style="margin-top: -90px">
                通过全场景编辑器，零基础、低成本的快速搭建城市 级3D可视化场景
              </div></el-col
            >
            <el-col :span="3"
              ><div class="grid-content bg-purple-light"></div
            ></el-col>
            <el-col :span="2"
              ><div class="grid-content bg-purple" style="margin-left: -15px">
                通过使用开发文档下载SDK和API接口，轻松开发可视化项目应用
              </div></el-col
            >
            <el-col :span="3"
              ><div class="grid-content bg-purple-light"></div
            ></el-col>
            <el-col :span="3"
              ><div
                class="grid-content bg-purple"
                style="
                  text-align: center;
                  margin-top: -90px;
                  margin-left: -20px;
                "
              >
                可在多端展示应用，或离线部署到本地
              </div></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
    <div class="rwt-callto-action-area" style="margin-top: 20%">
      <div class="wrapper">
        <CallToAction
          :style-type="5"
          title="准备好开始使用了吗?"
          subtitle="或许可以听听明星使用者的意见。"
          btn-text="立刻登录 "
        />
      </div>
    </div>
    <div class="rwt-team-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="明星使用者"
              title="好用的 ShowModel"
              description="丰富的三维应用，多姿多彩！ <br /> 高逼真的效果，焕然一新！"
              data-aos="fade-up"
            />
          </div>
        </div>
        <div class="row row--15">
          <div
            class="col-lg-4 col-md-6 col-12"
            v-for="(teamMember, index) in teamData"
            :key="index"
          >
            <Team :team-member="teamMember" :team-style="2" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="rwt-split-area" id="miao3">
      <div class="wrapper">
        <Split :split-data="splitTwoData" :split-style="4" />
      </div>
    </div> -->
    <!-- <div class="rwt-timeline-area rn-section-gap" id="miao">
      <div class="container">
        <div class="row mt_dec--20">
          <div class="col-lg-12">
            <Timeline :timeline-data="timelineData" />
          </div>
        </div>
      </div>
    </div> -->
  </Layout>
</template>
<style scope>
.bolangxian {
  width: 80%;
  height: 200px;
  border-color: transparent;
  border-style: solid;
  background-image: url("../../assets/images/main/波浪线.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: auto;
}
.timelinecontent {
  width: 100%;
  height: 200px;
  border-color: transparent;
  border-style: solid;
  margin: auto;
}
.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: transparent;
}
.bg-purple {
  background: transparent;
}
.bg-purple-light {
  background: transparent;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
<script>
import Layout from "../../components/common/Layout";
import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
import Portfolio from "../../components/elements/portfolio/Portfolio.vue";
// import Portfolio2 from "../../components/elements/portfolio/Portfolio2";
import Split from "../../components/elements/split/Split";
import Timeline from "../../components/elements/timeline/Timeline";
// import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import ServiceFive from "../../components/elements/service/ServiceFive";
import CallToAction from "../../components/elements/callToAction/CallToAction";
import Team from "../../components/elements/team/Team";
import ServiceFour from "../../components/elements/service/ServiceFour";
import Icon from "../../components/icon/Icon";
export default {
  components: {
    SectionTitle,
    Portfolio,
    // Portfolio2,
    Layout,
    Split,
    Timeline,
    ServiceFive,
    CallToAction,
    Team,
    ServiceFour,
    Icon,
  },
  data() {
    return {
      teamData: [
        {
          image: "配图-17",
          name: "朱 先生",
          designation: "3D 设计师",
          location: "中国*江苏省*苏州市",
          description:
            "3D编辑器非常好用，以前我的作品都在个人电脑里，现在可以分享全世界。",
          socialNetwork: [
            {
              icon: "home",
              url: "#",
            },
            {
              icon: "inbox",
              url: "#",
            },
            {
              icon: "italic",
              url: "#",
            },
          ],
        },
        {
          image: "配图-18",
          name: "梁 先生",
          designation: "BIM设计师",
          location: "中国*上海市",
          description:
            "我特别喜欢工作台中的 模型轻量化，在线展示，汇报，领导特别喜欢看我的作品.",
          socialNetwork: [
            {
              icon: "home",
              url: "#",
            },
            {
              icon: "inbox",
              url: "#",
            },
            {
              icon: "italic",
              url: "#",
            },
          ],
        },
        {
          image: "配图-19",
          name: "徐 女士",
          designation: "设计院工程师",
          location: "中国*浙江省*杭州市",
          description: "方便快捷、高效。",
          description2: "轻松应对甲方的各种要求。",
          socialNetwork: [
            {
              icon: "home",
              url: "#",
            },
            {
              icon: "inbox",
              url: "#",
            },
            {
              icon: "italic",
              url: "#",
            },
          ],
        },
      ],
      portfolioData: [
        {
          //序号
          id: 1,
          //白色主题图片
          portfolioImage: require(`@/assets/NewImages/Picture10000746356818.jpg`),
          //黑色主题图片
          image: require(`@/assets/NewImages/Picture10000746356818.jpg`),
          //名称
          title: "城市 互通立交桥 BIM三维模型",
          //子页-描述
          subtitle: "模型描述",
          //子页-日期
          date: "2021年5月",
          client: "立交模型",
          category: "交通模型",
          awards: ["2020 Design beautiful apps Finale Awards"],
          excerpt: "Design beautiful apps.",
          body: `<p> 该立交为城市复杂互通立交, 模型匝道1:1还原，具有真实的坐标及构件信息。模型建成后，用于视觉呈现，PPT汇报，效果图等，应用广泛。</p> <br /> <p>该模型通过 轻量化引擎，实现了在线预览等功能，所有信息包含在模型中，具有广泛的价值和意义。</p>`,
          btn: {
            buttonText: "返回首页",
            link: "/",
          },
          FileId: "10000767031455",
          subContent: {
            title: "Digital Marketing.",
            subtext:
              "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent2: {
            title: "App Development.",
            subtext:
              "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent3: {
            title: "Solution.",
            subtext:
              "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          //子页-图片
          gallery: {
            // imageOne: require(`@/assets/NewImages/Picture10000746356818.jpg`),
            // imageTwo: require(`@/assets/NewImages/Picture10000746356818.jpg`),
            // imageThree: require(`@/assets/NewImages/Picture10000746356818.jpg`)
          },
        },
        {
          id: 2,
          portfolioImage: require(`@/assets/NewImages/2.jpg`),
          image: require(`@/assets/NewImages/2.jpg`),
          title: "特大桥 立交模型",
          subtitle: "模型描述",
          date: "2021年4月",
          client: "桥梁模型",
          category: "交通模型",
          awards: ["2020 Design beautiful apps Finale Awards"],
          excerpt: "Design beautiful apps.",
          body: `<p> 该立交为公路特大桥, 模型匝道1:1还原，具有真实的坐标及构件信息。模型建成后，用于视觉呈现，PPT汇报，效果图等，应用广泛。</p> <br /> <p>该模型通过 轻量化引擎，实现了在线预览等功能，所有信息包含在模型中，具有广泛的价值和意义。</p>`,
          btn: {
            buttonText: "返回首页",
            link: "/",
          },
          FileId: "10000767029252",
          subContent: {
            title: "Digital Marketing.",
            subtext:
              "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent2: {
            title: "App Development.",
            subtext:
              "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent3: {
            title: "Solution.",
            subtext:
              "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          gallery: {
            //    imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
            //    imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
            //    imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
          },
        },
        {
          id: 3,
          portfolioImage: require(`@/assets/NewImages/3.jpg`),
          image: require(`@/assets/NewImages/3.jpg`),
          title: "系杆拱 立交模型",
          subtitle: "模型描述",
          date: "2021年7月",
          client: "桥梁模型",
          category: "交通模型",
          awards: ["2020 Design beautiful apps Finale Awards"],
          excerpt: "Design beautiful apps.",
          body: `<p> 该桥梁为某系杆拱桥梁, 模型1:1还原，具有真实的坐标及构件信息。模型建成后，用于视觉呈现，PPT汇报，效果图等，应用广泛。</p> <br /> <p>该模型通过 轻量化引擎，实现了在线预览等功能，所有信息包含在模型中，具有广泛的价值和意义。</p>`,
          btn: {
            buttonText: "View Projects",
            link: "/",
          },
          FileId: "10000767028132",
          subContent: {
            title: "Digital Marketing.",
            subtext:
              "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent2: {
            title: "App Development.",
            subtext:
              "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent3: {
            title: "Solution.",
            subtext:
              "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          gallery: {
            //    imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
            //    imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
            //    imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
          },
        },
        // {
        //   id: 4,
        //   portfolioImage: require(`@/assets/NewImages/4.jpg`),
        //   image: require(`@/assets/NewImages/4.jpg`),
        //   title: "小洋房 建筑",
        //   subtitle: "模型描述",
        //   date: "2021年7月",
        //   client: "建筑模型",
        //   category: "建筑模型",
        //   awards: ["2020 Design beautiful apps Finale Awards"],
        //   excerpt: "Design beautiful apps.",
        //   body: `<p> 该建筑为小洋房建筑, 模型1:1还原，具有真实的坐标及构件信息。模型建成后，用于视觉呈现，PPT汇报，效果图等，应用广泛。</p> <br /> <p>该模型通过 轻量化引擎，实现了在线预览等功能，所有信息包含在模型中，具有广泛的价值和意义。</p>`,
        //   btn: {
        //     buttonText: "View Projects",
        //     link: "/",
        //   },
        //   FileId: "10000767073049",
        //   subContent: {
        //     title: "Digital Marketing.",
        //     subtext:
        //       "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   subContent2: {
        //     title: "App Development.",
        //     subtext:
        //       "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   subContent3: {
        //     title: "Solution.",
        //     subtext:
        //       "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   gallery: {
        //     //    imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
        //     //    imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
        //     //    imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
        //   },
        // },
        // {
        //   id: 5,
        //   portfolioImage: require(`@/assets/NewImages/5.jpg`),
        //   image: require(`@/assets/NewImages/5.jpg`),
        //   title: "独栋 别墅",
        //   subtitle: "模型描述",
        //   date: "2021年2月",
        //   client: "建筑模型",
        //   category: "建筑模型",
        //   awards: ["2020 Design beautiful apps Finale Awards"],
        //   excerpt: "Design beautiful apps.",
        //   body: `<p> 该建筑为独栋 别墅, 模型1:1还原，具有真实的坐标及构件信息。模型建成后，用于视觉呈现，PPT汇报，效果图等，应用广泛。</p> <br /> <p>该模型通过 轻量化引擎，实现了在线预览等功能，所有信息包含在模型中，具有广泛的价值和意义。</p>`,
        //   btn: {
        //     buttonText: "View Projects",
        //     link: "/",
        //   },
        //   FileId: "10000767070338",
        //   subContent: {
        //     title: "Digital Marketing.",
        //     subtext:
        //       "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   subContent2: {
        //     title: "App Development.",
        //     subtext:
        //       "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   subContent3: {
        //     title: "Solution.",
        //     subtext:
        //       "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   gallery: {
        //     //    imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
        //     //    imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
        //     //    imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
        //   },
        // },
        // {
        //   id: 6,
        //   portfolioImage: require(`@/assets/NewImages/6.jpg`),
        //   image: require(`@/assets/NewImages/6.jpg`),
        //   title: "商业 建筑",
        //   subtitle: "模型描述",
        //   date: "2021年2月",
        //   client: "建筑模型",
        //   category: "建筑模型",
        //   awards: ["2020 Design beautiful apps Finale Awards"],
        //   excerpt: "Design beautiful apps.",
        //   body: `<p> 该建筑为商业 建筑, 模型1:1还原，具有真实的坐标及构件信息。模型建成后，用于视觉呈现，PPT汇报，效果图等，应用广泛。</p> <br /> <p>该模型通过 轻量化引擎，实现了在线预览等功能，所有信息包含在模型中，具有广泛的价值和意义。</p>`,
        //   btn: {
        //     buttonText: "View Projects",
        //     link: "/",
        //   },
        //   FileId: "10000767070338",
        //   subContent: {
        //     title: "Digital Marketing.",
        //     subtext:
        //       "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   subContent2: {
        //     title: "App Development.",
        //     subtext:
        //       "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   subContent3: {
        //     title: "Solution.",
        //     subtext:
        //       "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   gallery: {
        //     //    imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
        //     //    imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
        //     //    imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
        //   },
        // },
        // {
        //   id: 7,
        //   portfolioImage: require(`@/assets/NewImages/7.jpg`),
        //   image: require(`@/assets/NewImages/7.jpg`),
        //   title: "跑步机",
        //   subtitle: "模型描述",
        //   date: "2021年1月",
        //   client: "其他模型",
        //   category: "其他模型",
        //   awards: ["2020 Design beautiful apps Finale Awards"],
        //   excerpt: "Design beautiful apps.",
        //   body: `<p> 该建筑为跑步机, 模型1:1还原，具有真实的坐标及构件信息。模型建成后，用于视觉呈现，PPT汇报，效果图等，应用广泛。</p> <br /> <p>该模型通过 轻量化引擎，实现了在线预览等功能，所有信息包含在模型中，具有广泛的价值和意义。</p>`,
        //   btn: {
        //     buttonText: "View Projects",
        //     link: "/",
        //   },
        //   FileId: "10000767070338",
        //   subContent: {
        //     title: "Digital Marketing.",
        //     subtext:
        //       "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   subContent2: {
        //     title: "App Development.",
        //     subtext:
        //       "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   subContent3: {
        //     title: "Solution.",
        //     subtext:
        //       "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   gallery: {
        //     //    imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
        //     //    imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
        //     //    imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
        //   },
        // },
        // {
        //   id: 8,
        //   portfolioImage: require(`@/assets/NewImages/8.jpg`),
        //   image: require(`@/assets/NewImages/8.jpg`),
        //   title: "花箱",
        //   subtitle: "模型描述",
        //   date: "2021年1月",
        //   client: "其他模型",
        //   category: "其他模型",
        //   awards: ["2020 Design beautiful apps Finale Awards"],
        //   excerpt: "Design beautiful apps.",
        //   body: `<p> 花箱, 模型1:1还原，具有真实的坐标及构件信息。模型建成后，用于视觉呈现，PPT汇报，效果图等，应用广泛。</p> <br /> <p>该模型通过 轻量化引擎，实现了在线预览等功能，所有信息包含在模型中，具有广泛的价值和意义。</p>`,
        //   btn: {
        //     buttonText: "View Projects",
        //     link: "/",
        //   },
        //   FileId: "10000767075217",
        //   subContent: {
        //     title: "Digital Marketing.",
        //     subtext:
        //       "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   subContent2: {
        //     title: "App Development.",
        //     subtext:
        //       "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   subContent3: {
        //     title: "Solution.",
        //     subtext:
        //       "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   gallery: {
        //     //    imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
        //     //    imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
        //     //    imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
        //   },
        // },
        // {
        //   id: 9,
        //   portfolioImage: require(`@/assets/NewImages/9.jpg`),
        //   image: require(`@/assets/NewImages/9.jpg`),
        //   title: "护栏",
        //   subtitle: "模型描述",
        //   date: "2022年1月",
        //   client: "其他模型",
        //   category: "其他模型",
        //   awards: ["2020 Design beautiful apps Finale Awards"],
        //   excerpt: "Design beautiful apps.",
        //   body: `<p> 护栏, 模型1:1还原，具有真实的坐标及构件信息。模型建成后，用于视觉呈现，PPT汇报，效果图等，应用广泛。</p> <br /> <p>该模型通过 轻量化引擎，实现了在线预览等功能，所有信息包含在模型中，具有广泛的价值和意义。</p>`,
        //   btn: {
        //     buttonText: "View Projects",
        //     link: "/",
        //   },
        //   FileId: "10000767065825",
        //   subContent: {
        //     title: "Digital Marketing.",
        //     subtext:
        //       "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   subContent2: {
        //     title: "App Development.",
        //     subtext:
        //       "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   subContent3: {
        //     title: "Solution.",
        //     subtext:
        //       "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
        //   },
        //   gallery: {
        //     //    imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
        //     //    imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
        //     //    imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
        //   },
        // },
      ],
      portfolioData2: [
        {
          //序号
          id: 11,
          //白色主题图片
          portfolioImage: require(`@/assets/NewImages/11.jpg`),
          //黑色主题图片
          image: require(`@/assets/NewImages/11.jpg`),
          //名称
          title: "CAD图纸钢筋",
          //子页-描述
          subtitle: "CAD图纸",
          //子页-日期
          date: "2021年5月",
          client: "CAD图纸",
          category: "图纸",
          awards: ["2020 Design beautiful apps Finale Awards"],
          excerpt: "Design beautiful apps.",
          body: `<p>CAD图纸在线预览，广泛应用于设计协作，设计协同行业，极大的提高了效率。</p>`,
          btn: {
            buttonText: "返回首页",
            link: "/",
          },
          FileId: "10000764274142",
          subContent: {
            title: "Digital Marketing.",
            subtext:
              "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent2: {
            title: "App Development.",
            subtext:
              "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent3: {
            title: "Solution.",
            subtext:
              "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          //子页-图片
          gallery: {
            // imageOne: require(`@/assets/NewImages/Picture10000746356818.jpg`),
            // imageTwo: require(`@/assets/NewImages/Picture10000746356818.jpg`),
            // imageThree: require(`@/assets/NewImages/Picture10000746356818.jpg`)
          },
        },
        {
          //序号
          id: 12,
          //白色主题图片
          portfolioImage: require(`@/assets/NewImages/12.jpg`),
          //黑色主题图片
          image: require(`@/assets/NewImages/12.jpg`),
          //名称
          title: "CAD图纸",
          //子页-描述
          subtitle: "CAD图纸",
          //子页-日期
          date: "2021年5月",
          client: "CAD图纸",
          category: "图纸",
          awards: ["2020 Design beautiful apps Finale Awards"],
          excerpt: "Design beautiful apps.",
          body: `<p>CAD图纸在线预览，广泛应用于设计协作，设计协同行业，极大的提高了效率。</p>`,
          btn: {
            buttonText: "返回首页",
            link: "/",
          },
          FileId: "10000764600719",
          subContent: {
            title: "Digital Marketing.",
            subtext:
              "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent2: {
            title: "App Development.",
            subtext:
              "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent3: {
            title: "Solution.",
            subtext:
              "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          //子页-图片
          gallery: {
            // imageOne: require(`@/assets/NewImages/Picture10000746356818.jpg`),
            // imageTwo: require(`@/assets/NewImages/Picture10000746356818.jpg`),
            // imageThree: require(`@/assets/NewImages/Picture10000746356818.jpg`)
          },
        },
        {
          //序号
          id: 13,
          //白色主题图片
          portfolioImage: require(`@/assets/NewImages/13.jpg`),
          //黑色主题图片
          image: require(`@/assets/NewImages/13.jpg`),
          //名称
          title: "CAD图纸",
          //子页-描述
          subtitle: "CAD图纸",
          //子页-日期
          date: "2021年5月",
          client: "CAD图纸",
          category: "图纸",
          awards: ["2020 Design beautiful apps Finale Awards"],
          excerpt: "Design beautiful apps.",
          body: `<p>CAD图纸在线预览，广泛应用于设计协作，设计协同行业，极大的提高了效率。</p>`,
          btn: {
            buttonText: "返回首页",
            link: "/",
          },
          FileId: "10000764275046",
          subContent: {
            title: "Digital Marketing.",
            subtext:
              "We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent2: {
            title: "App Development.",
            subtext:
              "We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          subContent3: {
            title: "Solution.",
            subtext:
              "We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.",
          },
          //子页-图片
          gallery: {
            // imageOne: require(`@/assets/NewImages/Picture10000746356818.jpg`),
            // imageTwo: require(`@/assets/NewImages/Picture10000746356818.jpg`),
            // imageThree: require(`@/assets/NewImages/Picture10000746356818.jpg`)
          },
        },
      ],
      splitTwoData: {
        image: "2.jpg",
        title: "Show展位模型",
        description: `图模坊模型展示，通过轻量化、无插件的方式来加载高质量的3D模型。实现了模型的720度全方位展示，且兼具互动特性，支持产品模型的爆炸、拆分、旋转、 缩放、移动等在线操作体验。还可以通过微信、手机一键分享至任何网页或平台。`,
        counterData: [
          {
            number: 199,
            title: "稳定运行时间",
          },
          {
            number: 575,
            title: "项目模型数量",
          },
          {
            number: 69,
            title: "制作周期",
          },
          {
            number: 500,
            title: "已观看人数",
          },
        ],
        btn: {
          text: "联系我们",
          link: "/contact",
        },
      },
      timelineData: [
        // {
        //   id: "1",
        //   // date: "Step-1",
        //   title: "BIMWORK可视化编辑器",
        //   description:
        //     "以成熟的3D编辑模式和便捷的业务交互设计，搭建全要素、多精度的孪生数据底座，高效实现可视化场景，赋能用户三维呈现与交互的无限创造力。",
        //   image: "配图-1",
        //   workingStep: [
        //     {
        //       stepTitle: "模型效果编辑器",
        //       stepDescription: "基于WEBGL的3D图形引擎",
        //       stepDescription2: "采用云计算技术可视化在线编辑",
        //       stepDescription3: "可保存编辑效果一键发送",
        //     },
        //     {
        //       stepTitle: "无代码大屏编辑器",
        //       stepDescription: "支持三维场景中修改设备点位、属性数据等信息。",
        //       stepDescription2:
        //         "支持数据看板层面，根据数据类型选择各形态的图表展示。",
        //       stepDescription3: "支持模型、看板数据业务绑定。",
        //     },
        //   ],
        //   buttontext: "了解更多",
        // },
        // {
        //   id: "2",
        //   // date: "Step-2",
        //   title: "构建数字孪生世界",
        //   description:
        //     "融合多源异构数据，全面覆盖地理空间数据处理需求，搭建基于BIM模型的三维可视化、智能化、集成化的数字孪生平台",
        //   image: "配图-2",
        //   workingStep: [
        //     {
        //       stepTitle: "数据可视化",
        //       stepDescription: "专业建模可视化团队",
        //       stepDescription2: "丰富的可视化大屏模板",
        //       stepDescription3: "深度业务融合",
        //       stepDescription4: "数据决策和协同联动",
        //     },
        //     {
        //       stepTitle: "智慧城市",
        //       stepDescription: "城市底座生成",
        //       stepDescription2: "BIM模型融合",
        //       stepDescription3: "引擎脚本开发",
        //       stepDescription4: "UI前后端开发",
        //     },
        //   ],
        //   buttontext: "了解更多",
        // },
        {
          id: "3",
          // date: "Step-3",
          title: "丰富的模型增值服务",
          description:
            "BIM轻量化技术大大拓展了BIM的应用范围，让BIM模型可以在web端进行展示、操作、处理，打造原始3D模型到3D应用的转换器及核心支撑。",
          image: "配图-3",
          workingStep: [
            {
              stepTitle: "BIM可视化组件",
              stepDescription:
                "通过BIM可视化组件系统，对模型进行轻量化预览、审阅、批注和成果确认。",
            },
            {
              stepTitle: "轻量化引擎技术",
              stepDescription:
                "轻量化的BIM渲染引擎，可以让查看BIM模型及应用BIM模型更加方便快捷。",
            },
          ],
          buttontext: "了解更多",
        },
        // {
        //   id: "4",
        //   // date: "Step-4",
        //   title: "图模坊-让你的作品更具价值",
        //   description:
        //     "自主上传，支持各类通用格式，模型自动转换为独有的轻量化文件，用户可以在线查看，也可以一键分享给同事和朋友。",
        //   image: "配图-4",
        //   workingStep: [
        //     {
        //       stepTitle: "数字化技术",
        //       stepDescription:
        //         "利用数字化技术，为建筑全生命周期提供完整的建筑工程数据库。",
        //     },
        //     {
        //       stepTitle: "集成化信息",
        //       stepDescription:
        //         "数据库中包含几何信息和非几何信息，可提高信息的集成化程度。",
        //     },
        //   ],
        //   buttontext: "进入图模坊",
        // },
      ],
    };
  },
};
</script>