<template>
  <div>
    <div class="phone">
      <div id="container">
        <el-progress
          v-if="notHasLoadAll"
          :text-inside="true"
          :stroke-width="16"
          :percentage="percentage"
          :format="format"
          style="
            width: 300px;
            margin-left: 20%;
            margin-top: 40%;
            position: relative;
          "
        ></el-progress>
      </div>
      <!-- //最外层item -->
      <div class="items">
        <div class="items-inner">
          <div
            class="item-grab"
            v-for="couesel in couesels"
            :key="couesel.id"
            @click="EventPhone(couesel.id)"
          >
            <span>
              <div class="item-box">
                <img class="item-img" :src="couesel.img" alt="" />
              </div>
            </span>
            <div class="item-name">{{ couesel.name }}</div>
          </div>
        </div>
      </div>
      <div class="context">
        <b>示例：手机宣传案例（三维展示,产品介绍）</b><br />
        <b id="page2Txt" v-show="false"
          >搭载6.1英寸OLED屏幕材质，配有蓝色，紫色，午夜色，星光色，红色五款颜色。</b
        >
        <div class="el-page2 li-none">
          <b class="page2-txt" id="page2TxtShow"></b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
let scene, camera, renderer, controls;
var smart_cheshen;
export default {
  data() {
    return {
      notHasLoadAll: true,
      percentage: 0,
      content: "",
      controls: "",
      couesels: [
        {
          id: 1,
          img: " https://neal.fun/design-the-next-iphone/thumbnails/digital-crown.png",
          name: "自动旋转",
        },
        {
          id: 2,
          img: " https://neal.fun/design-the-next-iphone/thumbnails/apple-logo.png",
          name: "自动换色",
        },
        {
          id: 3,
          img: " https://neal.fun/design-the-next-iphone/thumbnails/home-button.png",
          name: "自动背景",
        },
        {
          id: 4,
          img: "https://neal.fun/design-the-next-iphone/thumbnails/single-camera.png",
          name: "自动介绍",
        },
        {
          id: 5,
          img: " https://neal.fun/design-the-next-iphone/thumbnails/dpad.png",
          name: "购物链接",
        },
      ],
      skyshow: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    format(percentage) {
      return `图模坊加载中` + "    " + `${percentage}%`;
    },
    init() {
      this.loadscene();
      //加载天空盒
      this.loadAllTexture();
      //加载模型
      setTimeout(() => {
        this.loadCarModel();
      }, 50);
      //加载灯光
      this.addLight();
      //渲染
      this.loop();
    },
    //1-- 构建场景
    loadscene() {
      scene = new THREE.Scene();
      //相机
      camera = new THREE.PerspectiveCamera(90, 1, 0.1, 100);
      camera.position.set(2, 0, 1);
      //场景
      renderer = new THREE.WebGLRenderer({
        antialias: true,
      });
      // renderer.setSize(document.body.clientWidth, document.body.clientHeight);
      renderer.setSize(700, 700);
      document.getElementById("container").appendChild(renderer.domElement);
      this.controls = new OrbitControls(camera, renderer.domElement);
      // this.controls.enablePan = true // 是否开启右键拖拽
      // this.controls.maxPolarAngle = 1.5 // 上下翻转的最大角度
      // this.controls.minPolarAngle = 0.0 // 上下翻转的最小角度
      this.controls.rotateSpeed = 0.2;
      this.controls.enableDamping = true;
      this.controls.dampingFactor = 0.05; //动态阻尼系数 就是鼠标拖拽旋转灵敏度，阻尼越小越灵敏
      this.controls.autoRotate = false; // 是否自动旋转 必须与 渲染中this.control.uodate()相结合
    },
    //2--加载模型
    loadCarModel() {
      let sceneReady = false;
      const loadingManager = new THREE.LoadingManager(
        //loaded 载入完成
        () => {
          console.log("载入完成");
          sceneReady = true;
          this.notHasLoadAll = false;
        },
        //Progress 载入过程
        (itemUrl, itemsLoaded, itemsTotal) => {
          // this.loading = true
          var a = (itemsLoaded / 5) * 100;
          this.percentage = 99.99;
          console.log("载入中...", itemsLoaded);
        }
      );
      //导入模型
      const dracoLoader = new DRACOLoader();
      //解压缩工具
      ///draco/
      dracoLoader.setDecoderPath("./draco/");
      dracoLoader.setDecoderConfig({ type: "js" }); //使用兼容性强的draco_decoder.js解码器
      const loader = new GLTFLoader(loadingManager);
      //设置压缩工具
      loader.setDRACOLoader(dracoLoader);
      loader.load(
        "/Iphone13model/13sceneDraco.gltf",
        (gltf) => {
          scene.add(gltf.scene);
          var child =
            gltf.scene.children[0].children[0].children[0].children[1]
              .children[0];
          smart_cheshen = child;
        },
        function (xhr) {
          var a = (xhr.loaded / xhr.total) * 100;
          this.percentage = a - 1;
          console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        },
        function (error) {
          console.log("An error happened");
        }
      );
    },
    //3 --添加灯光
    addLight() {
      var ambientLight = new THREE.AmbientLight(0xd5d5d5);
      ambientLight.intensity = 1.2;
      scene.add(ambientLight);
      var bottomRightDirLight = new THREE.DirectionalLight();
      bottomRightDirLight.position.x = 5;
      bottomRightDirLight.position.y = 3;
      bottomRightDirLight.position.z = -5;
      bottomRightDirLight.intensity = 0.8;
      // var helper=new THREE.DirectionalLightHelper(bottomRightDirLight,1);
      // scene.add( helper );
      scene.add(bottomRightDirLight);

      var frontDirLight = new THREE.DirectionalLight(0xffffff);

      frontDirLight.position.x = -5;
      frontDirLight.position.y = 3;
      frontDirLight.position.z = 5;
      frontDirLight.intensity = 0.8;
      scene.add(frontDirLight);
    },
    //渲染
    loop() {
      requestAnimationFrame(this.loop);
      this.controls.update();
      renderer.render(scene, camera);
    },
    //下方事件条
    EventPhone(value) {
      switch (value) {
        case 1:
          this.autoRotate();
          break;
        case 2:
          this.changecolor();
          break;
        case 3:
          this.loadsky();
          break;
        case 4:
          this.show();
          break;
        case 5:
          this.linkshop();
          break;
        default:
      }
    },
    //背景换色
    loadsky() {
      this.skyshow = !this.skyshow;
      this.loadAllTexture();
    },
    //1-加载天空盒
    loadAllTexture() {
      if (this.skyshow) {
        let urls = [
          "/SkyBox/starrysky/px.jpg",
          "/SkyBox/starrysky/nx.jpg",
          "/SkyBox/starrysky/py.jpg",
          "/SkyBox/starrysky/ny.jpg",
          "/SkyBox/starrysky/pz.jpg",
          "/SkyBox/starrysky/nz.jpg",
        ];
        let skyboxCubemap = new THREE.CubeTextureLoader().load(urls);
        scene.background = skyboxCubemap;
      } else {
        //0xffffff 白色
        scene.background = new THREE.Color(0xffffff);
      }
    },
    //手机换色
    changecolor() {
      var cIndex = Math.floor(Math.random() * 4 + 1);
      var colors = [0xffff00, 0xff0000, 0xffffff, 0x00e600, 0x4db8ff];
      smart_cheshen.material.color = new THREE.Color(colors[cIndex]);
    },
    //自动旋转
    autoRotate() {
      this.controls.autoRotate = !this.controls.autoRotate;
    },
    //购物链接
    linkshop() {
      window.open("https://www.jd.com/", "_blank");
    },
    //自动介绍
    show() {
      var page2Txt = document.getElementById("page2Txt"),
        page2TxtShow = document.getElementById("page2TxtShow"),
        i = 0,
        timer = setInterval(function () {
          page2TxtShow.innerHTML = page2Txt.innerHTML.substring(0, i);
          i++;
          if (page2TxtShow.innerHTML == page2Txt.innerHTML) {
            clearInterval(timer);
          }
        }, 60);
    },
  },
};
</script>

<style lang="scss" scoped>
.phone {
  height: 700px;
  position: relative;
}

.gridcol {
  width: 110px;
  transition: 0.5s;
}

.context {
  width: 700px;
  position: absolute;
  bottom: 15%;
  text-align: center;
  z-index: 3;

  b {
    line-height: 40px;
  }
}

.items {
  position: absolute;
  bottom: 5px;
  margin-bottom: 1%;
  left: 5%;
  z-index: 3;
  font-family: HelveticaNeue;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .items-inner {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    // max-width: 500px;

    .item-grab {
      min-width: 110px;
      transition: opacity 0.15s;
      cursor: pointer;
    }

    .item-grab :hover {
      min-width: 70px;
      transition: opacity 0.15s;
      cursor: pointer;
    }

    .item-box {
      width: 65px;
      height: 65px;
      background: #fff;
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-left: auto;
      margin-right: auto;
    }

    .item-name {
      margin-top: 7px;
      font-size: 14px;
      text-align: center;
      line-height: 1.3em;
      font-weight: 500;
    }

    .item-img {
      width: 100%;
      border-radius: 15px;
    }
  }
}

.yellow {
  background: yellow !important;
}

.red {
  background: red !important;
}

.green {
  background: #00e600 !important;
}

.blue {
  background: #4db8ff !important;
}
</style>
