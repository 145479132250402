<template>
  <div class="card">
    <slot />
  </div>
</template>
<style scoped>
.card {
  width: 1500px;
  height: 700px;
  margin: 0 auto;
  box-sizing: border-box;
  border-style: none;
  background-color:transparent;
}
</style>
<script>
export default {
  name: "Card",
};
</script>
