<template>
  <Layout
    header-class="header-transparent"
    :show-newsletter="false"
    :show-cta="false"
    :footer-style="3"
  >
    <div class="rwt-pricing-area rn-section-gap" id="94">
      <div class="wrapper plr--100 plr_md--15 plr_sm--15">
        <div class="row mb--40 mb_sm--0">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="我们提供什么服务？"
              title="自主服务价格"
              data-aos="fade-up"
              data-aos-delay="60"
            />
          </div>
        </div>
        <Pricing :pricing-data="pricingFourData" :pricing-style="4" />
      </div>
    </div>
    <div class="rwt-pricing-area rn-section-gap" id="93">
      <div class="wrapper plr--100 plr_md--15 plr_sm--15">
        <div class="row mb--40 mb_sm--0">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              title="增值服务价格"
              data-aos="fade-up"
              data-aos-delay="60"
            />
          </div>
        </div>
        <Pricing :pricing-data="pricingFourData2" :pricing-style="4" />
      </div>
    </div>
    <div class="rwt-callto-action-area rn-section-gapBottom">
      <div class="wrapper">
        <CallToAction
          :style-type="5"
          title="准备开始 利用工具 高效创作了吗 ?"
          subtitle="点击 注册==即可开始创作"
          btn-text="立刻 登录"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../components/common/Layout.vue";
import Pricing from "../../components/elements/pricing/Pricing";
import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
import CallToAction from "../../components/elements/callToAction/CallToAction";
export default {
  components: {
    Layout,
    Pricing,
    SectionTitle,
    CallToAction,
  },
  data() {
    return {
      pricingFourData: [
        {
          title: "基础功能",
          currency: "¥",
          price: "1",
          subtitle: "",
          features: [
            { title: "每月赠送50次免费转化次数" },
            { title: "在线预览、生成二维码、图纸共享" },
            { title: "多版本切换使用" },
            { title: "分享加成机制" },
            { title: "2D汇报/大屏基础模板" },
          ],
          btn: {
            text: "立刻体验",
            link: "/",
          },
        },
        {
          title: "高级功能",
          currency: "¥",
          price: "50",
          subtitle: "一个月",
          features: [
            { title: "每月赠送50次免费转化次数" },
            { title: "包含基础版所有功能" },
            { title: "无限制分享" },
            { title: "1次 大神聊图机会" },
            { title: "高级版汇报/展示 模板" },
          ],
          btn: {
            text: "立刻体验",
            link: "/",
          },
          isActive: true,
        },
        {
          title: "个性化应用",
          currency: "¥",
          price: "300",
          subtitle: "次",
          features: [
            { title: "1.个性应用（长图展示）" },
            { title: "2.嵌入式网页" },
            { title: "3.刊物/海报 应用" },
            { title: "4.个性化 展示模板" },
            { title: "5.私有部署" },
          ],
          btn: {
            text: "立刻体验",
            link: "/",
          },
        },
      ],
      pricingFourData2: [
        {
          title: "3d建模及模型处理",
          currency: "¥",
          price: "600",
          subtitle: "一个",
          features: [
            { title: "1.根据客户提供的产品照片3d建模（客户无3d模型）" },
            { title: "2.优化客户提供的3d模型（客户提供3d模型）" },
            { title: "3.根据建模复杂程度而定，详细报价与客服联系" },
          ],
          btn: {
            text: "立刻体验",
            link: "/",
          },
        },
        {
          title: "3d网页开发",
          currency: "¥",
          price: "300",
          subtitle: "一个",
          features: [
            { title: "1.根据客户需求定制网页页面" },
            { title: "2.3d网页嵌入到现有网页" },
            { title: "3.根据您的需求功能而定，详细报价与客服联系" },
          ],
          btn: {
            text: "立刻体验",
            link: "/",
          },
          isActive: true,
        },
        {
          title: "3d小程序定制",
          currency: "¥",
          price: "3000",
          subtitle: "次",
          features: [
            { title: "1.客户将小程序制作需求告知我们，我们提供开发定制服务" },
            { title: "2.根据业务要求设计小程序的外观及功能" },
            { title: "3.测试，上线，并小程序源码交付" },
          ],
          btn: {
            text: "立刻体验",
            link: "/",
          },
        },
        {
          title: "3d互动可视化平台",
          currency: "¥",
          price: "3000",
          subtitle: "次",
          features: [
            {
              title:
                "1.根据我公司已经制作好的模型，制作符合各个电商平台标准的模型文件",
            },
            { title: "2.客户可在电商平台自定义交互" },
            { title: "3.根据您的需求功能而定，详细报价与客服联系" },
          ],
          btn: {
            text: "立刻体验",
            link: "/",
          },
        },
      ],
    };
  },
};
</script>
