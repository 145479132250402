<template>
  <!-- //[direction] 绑定样式 -->
  <div class="login-wrapper">
    <!-- //修改 :model="loginForm"  -->
    <el-form ref="form" size="large" :model="loginForm" :rules="rules" class="login-form ele-bg-white"
      @keyup.enter.native="submit">
      <h4>图模坊3D-三维互动专家</h4>
      <el-tabs v-model="activeName" :stretch="true">
        <el-tab-pane label="二维码登录" name="first"><img
            src="https://bimhuicorebasic.oss-cn-shanghai.aliyuncs.com/%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpg"
            alt style="width:200px;margin:50px;" /> </el-tab-pane>
        <el-tab-pane label="账号登录" name="second">
          <el-form-item prop="account" style="margin-top:20px;">
            <el-input clearable v-model="loginForm.account" prefix-icon="el-icon-user" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input show-password v-model="loginForm.password" prefix-icon="el-icon-lock" />
          </el-form-item>
          <div class="el-form-item">
            <el-button size="large" type="primary" class="login-btn" :loading="loading" @click="submit">登录</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <div class="login-copyright">
      All Rights Reserved. 苏ICP备2022042492号-1
      新一代3D互动展示平台！
    </div>
    <!-- 三个登录选项 -->
    <div class="hidden-xs-only" style="position: absolute; right: 30px; bottom: 20px; z-index: 9">
      <el-radio-group v-model="direction" size="mini">
        <el-radio-button label="2">居左</el-radio-button>
        <el-radio-button label="0">居中</el-radio-button>
        <el-radio-button label="1">居右</el-radio-button>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { setToken } from '@/utils/token-util';
import { isvalidPhone } from '@/utils/formrules';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',
  data() {
    //自定义验证
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入用户名'))
      } else if (!isvalidPhone(value)) { //判断用户输入的值是否符合规则
        callback(new Error('请输入正确的用户名（11位手机号)'))
      } else {
        callback()
      }
    }
    return {
      activeName: 'first',
      // 登录框方向, 0居中, 1居右, 2居左
      direction: 0,
      // 加载状态
      loading: false,
      // 表单数据
      loginForm: {
        account: "",
        password: ""
      },
      //正则验证
      rules: {
        account: [
          {
            required: true,
            trigger: 'blur',
            essage: '请输入登录账号',
            validator: validPhone
          }
        ],
        password: [
          {
            required: true,
            message: '请输入登录密码',
            trigger: 'blur',
          }
        ],
      }
    };
  },
  created() {
    //如果存在token，跳转到首页
    // if (getToken()) {
    //   this.goHome();
    // }
  },
  methods: {
    /* 提交 */
    submit() {
      this.$refs.form.validate((valid) => {
        //如果没通过，返回
        if (!valid) {
          return false;
        }
        this.loading = true;
        let data = this.loginForm
        axios.post(
          "https://api.tomore.fun/api/OAuth/Login",
          data
        ).then((res) => {
          this.loading = false;
          if (res.data.code === 200) {
            //登录成功
            setToken(res.data.data.token);
            this.$message.success("登录成功返回购买页面");
            //返回上一页
            history.go(-1)
          }
        })
          .catch((e) => {
            this.$message.error("获取失败，请稍后再试");
          });
      });
    },
    // /* 跳转到首页 */
    // goHome() {
    //   this.$router.push(this.$route?.query?.from ?? '/').catch(() => { });
    // },

  }
};
</script>

<style scoped lang="scss">
/* 背景 */
.login-wrapper {
  padding: 50px 20px;
  position: relative;
  box-sizing: border-box;
  background-image: url('https://bimhuicorebasic.oss-cn-shanghai.aliyuncs.com/%E8%83%8C%E6%99%AF%E5%9B%BE.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.login-wrapper:before {
  content: '';
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* 卡片 */
.login-form {
  margin: 0 auto;
  width: 360px;
  max-width: 100%;
  padding: 25px 30px;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 4px;
  z-index: 2;
}

.login-form-right .login-form {
  margin: 0 15% 0 auto;
}

.login-form-left .login-form {
  margin: 0 auto 0 15%;
}

.login-form h4 {
  text-align: center;
  margin: 0 0 25px 0;
}

.login-form>.el-form-item {
  margin-bottom: 25px;
}

/* 验证码 */
.login-input-group {
  display: flex;
  align-items: center;
}


.login-captcha {
  height: 38px;
  width: 102px;
  margin-left: 10px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  text-align: center;
  cursor: pointer;
}

.login-captcha:hover {
  opacity: 0.75;
}

.login-btn {
  display: block;
  width: 100%;
}

/* 第三方登录图标 */
.login-oauth-icon {
  color: #fff;
  padding: 5px;
  margin: 0 10px;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}

/* 底部版权 */
.login-copyright {
  color: #eee;
  padding-top: 20px;
  text-align: center;
  position: relative;
  z-index: 1;
}
</style>
