<template>
  <div class="about-area about-style-4 rn-section-gap">
    <div class="container">
      <div class="row row--40 align-items-center">
        <div class="col-lg-6">
          <Video :video-data="videoData" column="col-12" />
        </div>
        <div class="col-lg-6 mt_md--40 mt_sm--40">
          <div class="content">
            <div class="inner">
              <h3 class="title">
                二维应用ShowCAD <br />
                核心功能 <strong>介绍</strong>
              </h3>
              <ul class="feature-list">
                <li>
                  <Icon name="check" size="20" icon-class="icon" />
                  <div class="title-wrapper">
                    <h4 class="title">Web多端展示</h4>
                    <p class="text">
                      开放式图纸应用，用户一键上传，即可分享给好友、生成互联网ID
                      <br />支持全版本CAD图纸在线预览，无需载入字体，打开速度快
                    </p>
                  </div>
                </li>
                <li>
                  <Icon name="check" size="20" icon-class="icon" />
                  <div class="title-wrapper">
                    <h4 class="title">CAD多场景应用</h4>
                    <p class="text">
                      支持二维码/条形码嵌入网页/PPT/海报等媒介，全场景使用<br />
                      团队共享、审批，图纸汇报展示，论坛聊图
                    </p>
                  </div>
                </li>
              </ul>
              <div class="about-btn mt--30">
                <router-link class="btn-default" to="/about"
                  >点击 开始使用</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../../icon/Icon";
import Video from "../video/Video";

export default {
  name: "AboutFour",
  components: { Video, Icon },
  props: {
    image: {},
  },
  data() {
    return {
      videoData: [
        {
          thumb: this.image,
          src: "https://bimhuicorebasic.oss-cn-shanghai.aliyuncs.com/12s%E8%A7%86%E9%A2%91.mp4",
        },
      ],
    };
  },
};
</script>