<template>
  <Layout :header-transparency="true" :footer-style="3" :show-top-header="true">
    <!-- Start Slider Area -->
    <div
      class="
        slider-area slider-style-4
        variation-2
        slider-dot
        rn-slick-dot rn-slick-arrow
      "
    >
      <VueSlickCarousel v-bind="sliderSetting">
        <div
          v-for="(slider, index) in sliderData"
          :key="index"
          class="single-slide"
        >
          <div
            class="height-950 bg-overlay bg_image"
            :style="{
              'background-image': `url(${require(`@/assets/images/bg/${slider.image}.jpg`)})`,
            }"
          >
            <div class="container">
              <div class="row row--30 align-items-center">
                <div class="col-lg-12">
                  <div class="inner text-center">
                    <h1 class="title" v-html="slider.title" />
                    <p class="description" v-html="slider.description" />
                    <div class="button-group mt--30">
                      <a
                        class="btn-default btn-large round"
                        href="https://www.tomore.fun/"
                      >
                        开始 创作！
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <!-- End Slider Area -->

    <Separator />

    <div class="rwt-elements-area rn-section-gap">
      <div class="container">
        <div class="row mb--25">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="用户类型"
              title="适应 全行业  工程行业"
              data-aos="fade-up"
            />
          </div>
        </div>
        <div class="col-lg-10 offset-lg-1 mt--10">
          <Progressbar
            :progress-data="progressData"
            :progress-style="5"
            column="col-lg-3 col-md-6 col-sm-6 mt--30 col-12"
          />
        </div>
      </div>
    </div>

    <Separator />
    <!-- Start Timeline Area  -->
    <div class="rwt-timeline-area rn-section-gap" id="miao">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="Function Introduction"
              title="协作工具 功能介绍"
              description="我们提供高效快捷的自助服务！ <br /> 开始创作！"
            />
          </div>
        </div>
        <div class="row mt_dec--20">
          <div class="col-lg-12">
            <Timeline :timeline-data="timelineData" />
          </div>
        </div>
      </div>
    </div>
    <!-- End Timeline Area -->
    <!-- Start Elements Area -->
    <div class="rwt-advance-tab-area rn-section-gap" id="miao1">
      <div class="container">
        <div class="row mb--40">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="功能介绍"
              title="覆盖全行业，多个效率工具"
              data-aos="fade-up"
            />
          </div>
        </div>
        <AdvancedTab :tab-data="tabData" :tab-style="2" />
      </div>
    </div>

    <Separator />

    <Separator />

    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              radiusRounded=""
              subtitle="我们可以做什么？"
              title="以下 四大功能 核心介绍"
              data-aos="fade-up"
            />
          </div>
        </div>
        <ServiceFour
          service-style="service__style--1 icon-circle-style with-working-process"
          text-align="center"
        />
      </div>
    </div>
    <!-- End Service Area -->

    <Separator />

    <div class="blog-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="额外功能"
              title="拓展介绍"
              description=""
              data-aos="fade-up"
            />
          </div>
        </div>
        <div class="row row--15">
          <div
            class="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
            data-aos="slide-up"
            data-aos-duration="800"
            :data-aos-delay="100 + index"
            v-for="(blog, index) in posts"
            :key="index"
            v-if="index < 3"
          >
            <BlogPost :blog="blog" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import AdvancedTab from "../../components/elements/tab/AdvancedTab";
import Layout from "../../components/common/Layout";
import Separator from "../../components/elements/separator/Separator";
import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
import BlogPost from "../../components/blog/BlogPost";
import BlogPostMixin from "../../mixins/BlogPostMixin";
import VueSlickCarousel from "vue-slick-carousel";
import AboutFour from "../../components/elements/about/AboutFour";
import Portfolio from "../../components/elements/portfolio/Portfolio";
import Progressbar from "../../components/elements/progressbar/Progressbar";
import Timeline from "../../components/elements/timeline/Timeline";
import ServiceFour from "../../components/elements/service/ServiceFour";

export default {
  name: "Startup",
  components: {
    ServiceFour,
    Timeline,
    Progressbar,
    Portfolio,
    BlogPost,
    AboutFour,
    SectionTitle,
    Separator,
    Layout,
    VueSlickCarousel,
    AdvancedTab,
  },
  mixins: [BlogPostMixin],
  data() {
    return {
      timelineData: [
        {
          id: "1",
          date: "Step-1",
          title: "CAD 在线协同预览",
          description:
            "CAD文件一键上传，快速形成线上预览文件，无需依托软件安装下载，通过浏览器即可在线便捷查阅。",
          image: "配图-1",
          workingStep: [
            {
              stepTitle: "一键上传",
              stepDescription:
                "上传DWG文件至服务平台，等待约10-20S左右，即可在线预览图纸，一键分享，多端使用",
            },
            {
              stepTitle: "轻量化预览",
              stepDescription:
                "图纸轻量化，快速预览，利用内置工具条，即可开展在线CAD工作",
            },
          ],
        },
        {
          id: "2",
          date: "Step-2",
          title: "CAD在线审批审查",
          description:
            "图纸提交审批，审批人无需安装CAD软件，在线实时查看图纸，并可在CAD图上直接批注审查意见。",
          image: "配图-2",
          workingStep: [
            {
              stepTitle: "CAD自助审批",
              stepDescription:
                "利用BIMWORK工具条，自助对在线CAD文件进行审批、预览",
            },
            {
              stepTitle: "还原意见内容，可追溯",
              stepDescription: "可逐条还原各类人员的审批意见，实时记录，修改",
            },
          ],
        },
        {
          id: "3",
          date: "Step-3",
          title: "CAD汇报宝",
          description:
            "通过web端/客户端/手机端都能实时查看图纸，同一图纸可保存最近3个历史版本，方便多方案比选。",
          image: "配图-3",
          workingStep: [
            {
              stepTitle: "自助载入汇报图纸",
              stepDescription: "选择在线图纸，一键开启汇报模式",
            },
            {
              stepTitle: "多汇报模板",
              stepDescription:
                "可多模板选择，市政、房建、机电、其他。适合自己的汇报模板",
            },
          ],
        },
        {
          id: "4",
          date: "Step-4",
          title: "工程场景-SURM看板甘特图",
          description:
            "通过任务看板管理整个项目周期，实时跟进项目进展情况，尽可能缩短项目周期，高效提升团队执行力。",
          image: "配图-3",
          workingStep: [
            {
              stepTitle: "自助载入汇报图纸",
              stepDescription: "选择在线图纸，一键开启汇报模式",
            },
            {
              stepTitle: "多汇报模板",
              stepDescription:
                "可多模板选择，市政、房建、机电、其他。适合自己的汇报模板",
            },
          ],
        },
        {
          id: "5",
          date: "Step-5",
          title: "工程场景 图片处理",
          description:
            "在线图片编辑，图片裁剪、尺寸标注、文字批注，可同步保存图片编辑路径，方便后期图片查找修改。",
          image: "配图-5",
          workingStep: [
            {
              stepTitle: "图片编辑",
              stepDescription:
                "图片编辑功能可为图片增加阴影、文字图层等，增强图片信息",
            },
            {
              stepTitle: "拓展标签",
              stepDescription: "可自定义图片标签，同时记录保存，还原图层",
            },
          ],
        },
        {
          id: "6",
          date: "Step-6",
          title: "工程场景 文档管理",
          description:
            "兼容数十种常用文件格式，支持在线安全阅读、文档共享归纳等功能，实现成员之间独立的项目文档协同管理。",
          image: "配图-6",
          workingStep: [
            {
              stepTitle: "文档管理",
              stepDescription: "排版管理，可分享，安全无忧",
            },
            {
              stepTitle: "在线预览",
              stepDescription: "office在线预览，实时下载，存储",
            },
          ],
        },
      ],
      sliderSetting: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      sliderData: [
        {
          image: "1920副本",
          title: `CAD协作工作区 <br/> 高效便捷.`,
          description: `使用协作工具，在线CAD分享、预览、即可开启协作模式.`,
        },
        {
          image: "1920-2",
          title: `真图处理 <br/> 记录追溯.`,
          description: `外业图片处理？内页记录追溯、可还原、方便修改无忧.`,
        },
      ],
      progressData: [
        {
          id: 1,
          title: "设计工程师",
          percentage: 25,
          subtitle: "市政行业、建筑行业 设计工程人员",
          strokeColor: "#0f0f11",
          strokePath: "#059DFF",
        },
        {
          id: 2,
          title: "BIM建模",
          percentage: 30,
          subtitle: "全行业 建模需求人员",
          strokeColor: "#0f0f11",
          strokePath: "#059DFF",
        },
        {
          id: 3,
          title: "施工方",
          percentage: 25,
          subtitle: "高效处理工序、记录的工程人员",
          strokeColor: "#0f0f11",
          strokePath: "#059DFF",
        },
        {
          id: 4,
          title: "甲方",
          percentage: 10,
          subtitle: "数据汇总的甲方人员",
          strokeColor: "#0f0f11",
          strokePath: "#059DFF",
        },
      ],
      tabData: {
        tabContent: [
          {
            id: 1,
            menu: "market-research",
            title: "ShowWork",
            description:
              "二维工作区，主打图片标签处理、记录、SURM高效看板、文档管理.",
            image: require(`@/assets/images/tab/配图-32.jpg`),
          },
          {
            id: 2,
            menu: "corporate-report",
            title: "ShowCAD",
            description:
              "CAD专区、主打工程图纸审批、图纸分享、版本切换、图纸分享协作、2D汇报区",
            image: require(`@/assets/images/tab/配图-33.jpg`),
          },
          {
            id: 3,
            menu: "app-development",
            title: "ShowModel",
            description: "3D专区、三维模型审批、三维模型功能、3D汇报.",
            image: require(`@/assets/images/tab/配图-35.jpg`),
          },
        ],
      },
    };
  },
};
</script>